
































































































import Events from '../events';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import BigNumber from 'bignumber.js';
import {Accessors} from 'vue/types/options';
import Vue from 'vue';
import {fromWeiEther, toBN} from '../utils/common';
import i18n from '../i18n';
import {getConfigValue} from '@/contracts';
import config from '../../app-config.json';
import {SupportedProject} from './Treasury.vue';
import {addChainToRouter} from '@/utils/common';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3 from 'web3';

interface StoreMappedState {
  skillRewards: string;
  directStakeBonusPercent: number;
  payoutCurrencyId: string;
  currentNetworkId: number;
}

interface StoreMappedActions {
  setUpContracts(): Promise<void>;
  initialize(): Promise<void>;
  configureMetaMask(networkId: number): Promise<void>;
  fetchPartnerProjects(): Promise<void>;
}
interface Data {
  showGraphics: boolean;
  hideRewards: boolean;
  hideAdvanced: boolean;
  hideWalletWarning: boolean;
  showSkillInUsd: boolean;
  showCosmetics: boolean;
  fightMultiplier: number;
  currentChain: string;
  supportedChains: string[];
  walletConnectChain: string;
  connectingWalletConnect: boolean;
  enabledLanguages: string[];
}

interface StoreMappedGetters {
  rewardsClaimTaxAsFactorBN: BigNumber;
  maxRewardsClaimTaxAsFactorBN: BigNumber;
  getPartnerProjects: SupportedProject[];
  getWeb3: any;
}

interface RPCS {
  [key: number]: string;
}

enum ClaimStage {
  WaxBridge = 0,
  Stake = 1,
  Claim = 2,
}

export default Vue.extend({
  async created() {
    if(this.isWalletConnect) this.walletConnectChain = getConfigValue('currencyNetwork');
    this.showGraphics = localStorage.getItem('useGraphics') === 'true';
    this.hideRewards = localStorage.getItem('hideRewards') === 'true';
    this.hideAdvanced = localStorage.getItem('hideAdvanced') === 'true';
    this.hideWalletWarning = localStorage.getItem('hideWalletWarning') === 'true';
    this.showSkillInUsd = localStorage.getItem('showSkillInUsd') === 'true';
    if(!localStorage.getItem('showCosmetics')) {
      localStorage.setItem('showCosmetics', 'true');
    }
    this.showCosmetics = localStorage.getItem('showCosmetics') !== 'false';
    this.fightMultiplier = Number(localStorage.getItem('fightMultiplier'));
    this.currentChain = localStorage.getItem('currentChain') || 'BNB';

    this.walletConnectChain = this.currentChain;
    this.supportedChains = window.location.href.startsWith('https://test') ? config.testSupportedChains : config.supportedChains;

    await this.fetchPartnerProjects();
  },

  data() {
    return {
      showGraphics: false,
      hideRewards: false,
      hideAdvanced: false,
      hideWalletWarning: false,
      showSkillInUsd: false,
      showCosmetics: true,
      fightMultiplier: 1,
      currentChain: 'BNB',
      walletConnectChain: 'BNB',
      connectingWalletConnect: false,
      checked: false,
      ClaimStage,
      supportedChains: [],
      enabledLanguages: [
        'en',
        'es',
        'fr',
        'pl',
        'de',
        'id',
        'pt'
      ]
    } as Data;
  },

  computed: {
    ...mapState(['skillRewards', 'directStakeBonusPercent', 'payoutCurrencyId', 'currentNetworkId']) as Accessors<StoreMappedState>,
    ...mapGetters(['getWeb3', 'rewardsClaimTaxAsFactorBN', 'maxRewardsClaimTaxAsFactorBN', 'getPartnerProjects']) as Accessors<StoreMappedGetters>,

    formattedSkillReward(): string {
      const skillRewards = fromWeiEther(this.skillRewards);
      return `${toBN(skillRewards).toFixed(4)}`;
    },
    formattedTaxAmount(): string {
      const skillRewards = fromWeiEther((parseFloat(this.skillRewards)* parseFloat(String(this.rewardsClaimTaxAsFactorBN))).toString());
      return `${toBN(skillRewards).toFixed(4)}`;
    },
    formattedRewardsClaimTax(): string {
      const frac = this.skillRewards === '0' ? this.maxRewardsClaimTaxAsFactorBN : this.rewardsClaimTaxAsFactorBN;

      return `${frac.multipliedBy(100).decimalPlaces(0, BigNumber.ROUND_HALF_UP)}%`;
    },
    formattedBonusLost(): string {
      const skillLost = fromWeiEther((parseFloat(this.skillRewards)*this.directStakeBonusPercent/100).toString());
      return `${toBN(skillLost).toFixed(4)}`;
    },
    canClaimTokens(): boolean {
      if (toBN(this.skillRewards).lte(0)) {
        return false;
      }
      return true;
    },

    languages(): { [key: string]: string } {
      const rObj: { [key: string]: string } = {};
      for (const [key, value] of Object.entries(i18n.messages)) {
        if(value.name.toString === null || !this.enabledLanguages.includes(key)) continue;
        rObj[key] = value.name.toString();
      }
      return rObj;
    },
    isWalletConnect(): boolean {
      return localStorage.getItem('walletconnect') ? true : false;
    }
  },

  methods: {
    ...(mapActions(
      'treasury',
      [
        'fetchPartnerProjects',
      ]
    ) as StoreMappedActions),
    ...(mapActions([
      'setUpContracts',
      'initialize',
      'configureMetaMask']
    ) as StoreMappedActions),
    ...mapMutations([
      'setNetworkId',
      'updateCurrentChainSupportsPvP',
      'updateCurrentChainSupportsQuests',
      'updateCurrentChainSupportsDrawbridge',
      'setWeb3',
    ]),
    ...mapMutations('treasury', [
      'updatePayoutCurrencyId',
    ]),
    toggleRewards() {
      this.hideRewards = !this.hideRewards;
      if (this.hideRewards) localStorage.setItem('hideRewards', 'true');
      else localStorage.setItem('hideRewards', 'false');

      Events.$emit('setting:hideRewards', { value: this.hideRewards });
    },

    toggleAdvanced() {
      this.hideAdvanced = !this.hideAdvanced;
      if (this.hideAdvanced) localStorage.setItem('hideAdvanced', 'true');
      else localStorage.setItem('hideAdvanced', 'false');

      Events.$emit('setting:hideAdvanced', { value: this.hideAdvanced });
    },
    async claimSkill(stage: ClaimStage) {
      if (stage === ClaimStage.WaxBridge) {
        (this.$refs['need-gas-modal'] as any).show();
      }
      if (stage === ClaimStage.Stake) {
        (this.$refs['stake-suggestion-modal'] as any).show();
      }
      if (stage === ClaimStage.Claim) {
        (this.$refs['stake-suggestion-modal'] as any).hide();
        (this.$refs['claim-confirmation-modal'] as any).show();
      }
    },

    toggleHideWalletWarning() {
      this.hideWalletWarning = !this.hideWalletWarning;
      if (this.hideWalletWarning) localStorage.setItem('hideWalletWarning', 'true');
      else localStorage.setItem('hideWalletWarning', 'false');

      Events.$emit('setting:hideWalletWarning', { value: this.hideWalletWarning });
    },

    toggleShowSkillInUsd() {
      this.showSkillInUsd = !this.showSkillInUsd;
      if (this.showSkillInUsd) localStorage.setItem('showSkillInUsd', 'true');
      else localStorage.setItem('showSkillInUsd', 'false');

      Events.$emit('setting:showSkillInUsd', { value: this.showSkillInUsd });
    },

    toggleShowCosmetics() {
      this.showCosmetics = !this.showCosmetics;
      if (this.showCosmetics) localStorage.setItem('showCosmetics', 'true');
      else localStorage.setItem('showCosmetics', 'false');

      Events.$emit('setting:showCosmetics', { value: this.showCosmetics });
    },

    setFightMultiplier() {
      localStorage.setItem('fightMultiplier', this.fightMultiplier.toString());

      Events.$emit('setting:fightMultiplier', { value: this.fightMultiplier });
    },

    async setCurrentChain() {
      localStorage.setItem('currentChain', this.currentChain);
      this.updateCurrentChainSupportsPvP();
      this.updateCurrentChainSupportsQuests();
      this.updateCurrentChainSupportsDrawbridge();
      Events.$emit('setting:currentChain', { value: this.currentChain });
      addChainToRouter(this.currentChain);
      if(!this.connectingWalletConnect) await this.configureMetaMask(+getConfigValue('VUE_APP_NETWORK_ID'));
    },
    async connectWallet() {
      if(this.isWalletConnect) {
        localStorage.removeItem('walletconnect');
        window.location.reload();
      }
      else{
        this.connectingWalletConnect = true;
        this.currentChain = this.walletConnectChain;
        this.setCurrentChain();

        const rpcs = {} as RPCS;
        config.supportedChains.forEach((chain) => {
          const chainId = getConfigValue('VUE_APP_NETWORK_ID', chain);
          rpcs[chainId] = getConfigValue('rpcUrls',chain)[0];
        });

        //  Create WalletConnect Provider
        const provider = new WalletConnectProvider({
          rpc: rpcs,
          chainId: +getConfigValue('VUE_APP_NETWORK_ID'),
        });

        //  Enable session (triggers QR Code modal)
        await provider.enable();

        const w3 = new Web3(provider as any);
        this.setWeb3(w3);
        (this as any).$router.push({ path: '/', query: { chain: this.currentChain } });
        window.location.reload();
      }
    }
  },
  watch: {
    '$i18n.locale'(newVal, ) {
      localStorage.setItem('language', newVal);
    }
  }
});
