

























import Vue from 'vue';

import ViewLinks from './ViewLinks.vue';
import Options from './Options.vue';
import SkillBalanceDisplay from './smart/SkillBalanceDisplay.vue';

import Events from '../events';
import {mapGetters, mapMutations} from 'vuex';

export default Vue.extend({
  components: {
    ViewLinks,
    SkillBalanceDisplay,
    Options,
  },

  data() {
    return {
      canShowRewardsBar: true,
      currentRoute: ''
    };
  },

  computed: {
    ...mapGetters(['getIsCharacterViewExpanded', 'ownCharacters']),
  },

  methods: {
    ...mapMutations(['setIsCharacterViewExpanded']),
    checkStorage(): void {
      this.canShowRewardsBar = localStorage.getItem('hideRewards') === 'false';
    },
    toggleCharacterView(): void {
      this.setIsCharacterViewExpanded(!this.getIsCharacterViewExpanded);
      localStorage.setItem(
        'isCharacterViewExpanded',
        this.getIsCharacterViewExpanded ? 'true' : 'false'
      );
    },
  },

  mounted() {
    this.checkStorage();
    Events.$on('setting:hideRewards', () => this.checkStorage());
  },
});
