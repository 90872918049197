





























































































import Vue from 'vue';
import {fromWeiEther, toBN} from '../utils/common';
import {PropType} from 'vue/types/options';
import axios from 'axios';
import {getConfigValue} from '@/contracts';
import i18n from '@/i18n';
import {TranslateResult} from 'vue-i18n';
import '@/mixins/general';
import Hint from '@/components/Hint.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { SupportedProject } from '@/views/Treasury.vue';
import { Accessors } from 'vue/types/options';


interface StoreMappedTreasuryGetters {
  getPartnerProjects: SupportedProject[];
  getProjectMultipliers: Record<number, string>
}

interface CombatResult {
  isVictory: boolean;
  playerRoll: string
  enemyRoll: string;
  xpGain: string;
  skillGain: string;
  bnbGasUsed: string;
}

interface StoreMappedTreasuryState {
  payoutCurrencyId: string;
  partnerProjectMultipliers: Record<number, string>;
  partnerProjectRatios: Record<number, string>;
  defaultSlippage: string;
}
interface StoreMappedTreasuryActions{
  getPartnerProjectMultiplier(id: number): Promise<string>;
  fetchPartnerProjects(): Promise<void>;
}

interface StoreMappedCombatActions {
  fetchIgoRewardsPerFight(): Promise<string>;
  fetchGasOffsetPerFight(): Promise<string>;
}

export default Vue.extend({
  components: {
    Hint
  },
  props: {
    fightResults: {
      type: Object as PropType<CombatResult>,
      default() {
        return {} as CombatResult;
      },
    },
    staminaUsed: {
      type: Number,
      default: 0,
    },
    isValor: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      skillPrice: 0,
      valorPrice: 0,
      gasToken: '',
      showAds: false,
      gasOffsetPerFight: 0,
      highestMultiplier: 0,
      highestMultiplierProject: {} as SupportedProject,
      partnerProjects: [] as SupportedProject[],
    };
  },
  watch: {
    getPartnerProjects(newval) {
      this.partnerProjects = newval;
      this.getProjectMultiplier();
    }
  },
  computed: {
    ...(mapGetters('treasury', ['getPartnerProjects', 'getProjectMultipliers']) as Accessors<StoreMappedTreasuryGetters>),
    ...(mapState('treasury',
      ['payoutCurrencyId','partnerProjectRatios','defaultSlippage'])as Accessors<StoreMappedTreasuryState>),
    formattedOutcome(): TranslateResult {
      if(this.fightResults.isVictory) return i18n.t('combatResults.won');
      else return i18n.t('combatResults.lost');
    },
    formattedSkill(): string {
      return toBN(fromWeiEther(this.fightResults.skillGain)).toFixed(6);
    },
    formattedSkillNoIGO(): string {
      return toBN(fromWeiEther((parseInt(this.fightResults.skillGain, 10)).toString())).toFixed(6);
    },
    formattedSkillGasOffsetRewards(): string {
      return toBN(fromWeiEther((this.gasOffsetPerFight * this.formattedStaminaUsed).toString())).toFixed(6);
    },
    formattedStaminaUsed(): number {
      return this.staminaUsed / 40;
    },
    formattedXpGain(): string {
      return this.fightResults.xpGain + ' xp';
    },
    highestProjectMultiplier(): number {
      return +(toBN(+this.getProjectMultipliers[+this.highestMultiplierProject.id]).div(toBN(10).pow(18)).toFixed(4) || 0);
    },
    fightRewardsAmountInUSD(): string {
      if(this.isValor) {
        return ((this.valorPrice * +this.formattedSkill * this.highestProjectMultiplier) || 0).toFixed(4);
      } else {
        return ((this.skillPrice * +this.formattedSkill * this.highestProjectMultiplier) || 0).toFixed(4);
      }
    }
  },
  methods: {
    ...(mapActions('treasury', ['getPartnerProjectMultiplier', 'fetchPartnerProjects']) as StoreMappedTreasuryActions),
    ...(mapActions('combat', ['fetchIgoRewardsPerFight', 'fetchGasOffsetPerFight']) as StoreMappedCombatActions),
    async fetchPrices(): Promise<void> {
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=cryptoblades,binancecoin&vs_currencies=usd');
      this.skillPrice = response.data?.cryptoblades.usd;
    },
    async fetchValorPrice(): Promise<void> {
      const response = await axios.get('https://api.dexscreener.com/latest/dex/pairs/bsc/0x8730c8dedc59e3baffb67bf1fa63d4f0e2d9ecc9');
      this.valorPrice = +response.data?.pair?.priceUsd || 0;
    },
    async getProjectMultiplier() {
      const tokenSymbol = this.isValor ? 'VALOR' : 'SKILL';
      this.partnerProjects = this.partnerProjects && this.partnerProjects.filter(partnerProject => partnerProject.tokenSymbol.includes(tokenSymbol));
      if(this.partnerProjects.length > 0) {
        this.partnerProjects.map(async (project) => {
          await this.getPartnerProjectMultiplier(project.id);
        });
        const projectMultipliers = await this.getProjectMultipliers;

        this.highestMultiplierProject = this.partnerProjects.sort((a, b) => {
          const highestMultiplierA = +(toBN(+projectMultipliers[a.id]).div(toBN(10).pow(18)).toFixed(4));
          const highestMultiplierB = +(toBN(+projectMultipliers[b.id]).div(toBN(10).pow(18)).toFixed(4));

          return highestMultiplierA - highestMultiplierB;
        })[0];
      }
    },
    formattedInUsd(value: string): string {
      if(!value) return '';
      return `($${value})`;
    },
    calculateSkillPriceInUsd(skill: number): number {
      if(!skill) return 0;
      return (skill as unknown as number * this.skillPrice as unknown as number);
    },
    calculatedSkillReward(): string {
      return toBN(fromWeiEther(this.fightResults.skillGain)).toFixed(6);
    },
    checkStorage() {
      if (process.env.NODE_ENV === 'development') this.showAds = false;
      else this.showAds = localStorage.getItem('show-ads') === 'true';
    },
  },
  async beforeMount(){
    this.gasOffsetPerFight = parseInt(await this.fetchGasOffsetPerFight(), 10);
  },
  async mounted() {
    await this.fetchPartnerProjects();
    this.gasToken = getConfigValue('currencySymbol') || 'BNB';

    await this.fetchValorPrice();
    await this.fetchPrices();
    await new Promise(f => setTimeout(f, 1000));
    this.checkStorage();
  },
});
