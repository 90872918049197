






















































































































































import Vue from 'vue';
import {mapActions} from 'vuex';
import {isValidWeb3Address} from '../../../utils/common';

interface StoreMappedActions {
  startNewEvent(payload: SpecialWeaponsEvent): Promise<void>;

  getActiveSpecialWeaponsEvents(): Promise<SpecialWeaponsEvent[]>;

  setSpecialWeaponArt(payload: { eventId: number, art: string }): Promise<void>;

  setSpecialWeaponDetails(payload: { eventId: number, details: string }): Promise<void>;

  setSpecialWeaponWebsite(payload: { eventId: number, website: string }): Promise<void>;

  setSpecialWeaponNote(payload: { eventId: number, note: string }): Promise<void>;

  incrementEventCount(): Promise<void>;

  addShards(payload: { user: string, eventId: number, shardsAmount: number }): Promise<void>;

  privatePartnerOrder(payload: { receivers: string[], eventId: number, orderOption: OrderOption }): Promise<void>;

  privatePartnerMint(payload: { receivers: string[], eventId: number, orderOption: OrderOption }): Promise<void>;

  reserveForGiveaways(payload: { reservingAddress: string, eventId: number, orderOption: OrderOption, amount: number }): Promise<void>;
}

interface AddShards {
  user: string;
  eventId?: number;
  shardsAmount?: number;
}

enum SpecialWeaponsEventProperty {
  ART, DETAILS, WEBSITE, NOTE
}

interface SelectedSpecialWeaponsEvent {
  id?: number;
  selectedProperty?: SpecialWeaponsEventProperty;
  value: string;
}

export enum OrderOption {
  THREE_FIVE = 1,
  FOUR_FIVE = 2,
  FIVE = 3,
}

interface PrivatePartner {
  receivers: string;
  eventId?: number;
  orderOption?: OrderOption;
}

interface ReserveForGiveaways {
  reservingAddress: string;
  eventId?: number;
  orderOption?: OrderOption;
  amount?: number;
}

enum Element {
  ALL = 100,
  FIRE = 0,
  WATER = 1,
  EARTH = 2,
  LIGHTNING = 3,
}

export interface SpecialWeaponsEvent {
  id?: number;
  name?: string;
  element?: Element;
  period?: number;
  supply?: number;
  art?: string;
  details?: string;
  website?: string;
  note?: string;
}

interface Data {
  newEvent: SpecialWeaponsEvent;
  selectedSpecialWeaponsEvent: SelectedSpecialWeaponsEvent;
  activeSpecialWeaponsEvents: SpecialWeaponsEvent[];
  specialWeaponsEventProperties: SpecialWeaponsEventProperty[];
  orderOptions: OrderOption[];
  elements: Element[];
  incrementEventCountModal: boolean;
  newAddShardsUser: AddShards;
  newPrivatePartnerOrder: PrivatePartner;
  newPrivatePartnerMint: PrivatePartner;
  newReserveForGiveaways: ReserveForGiveaways;
  isLoading: boolean;
}

export default Vue.extend({
  data() {
    return {
      newEvent: {
        name: '',
        element: undefined,
        period: undefined,
        supply: undefined,
        art: '',
        details: '',
        website: '',
        note: '',
      },
      elements: [Element.ALL, Element.FIRE, Element.WATER, Element.EARTH, Element.LIGHTNING],
      specialWeaponsEventProperties: [
        SpecialWeaponsEventProperty.ART,
        SpecialWeaponsEventProperty.DETAILS,
        SpecialWeaponsEventProperty.WEBSITE,
        SpecialWeaponsEventProperty.NOTE,
      ],
      selectedSpecialWeaponsEvent: {
        id: undefined,
        selectedProperty: undefined,
        value: '',
      },
      activeSpecialWeaponsEvents: [],
      incrementEventCountModal: false,
      newAddShardsUser: {
        user: '',
        eventId: undefined,
        shardsAmount: undefined,
      },
      orderOptions: [
        OrderOption.THREE_FIVE,
        OrderOption.FOUR_FIVE,
        OrderOption.FIVE,
      ],
      newPrivatePartnerOrder: {
        receivers: '',
        eventId: undefined,
        orderOption: undefined,
      },
      newPrivatePartnerMint: {
        receivers: '',
        eventId: undefined,
        orderOption: undefined,
      },
      newReserveForGiveaways: {
        reservingAddress: '',
        eventId: undefined,
        orderOption: undefined,
        amount: undefined,
      },
      isLoading: false,
      Element,
      SpecialWeaponsEventProperty,
      OrderOption,
    } as Data;
  },

  computed: {
    startNewSpecialWeaponsEventButtonDisabled(): boolean {
      return !this.newEvent.name
        || this.newEvent.element === undefined
        || this.newEvent.period === undefined
        || this.newEvent.supply === undefined
        || !this.newEvent.art
        || !this.newEvent.details
        || !this.newEvent.website
        || this.isLoading;
    },
    setSpecialWeaponsEventPropertyButtonDisabled(): boolean {
      return this.selectedSpecialWeaponsEvent.id === undefined
        || this.selectedSpecialWeaponsEvent.selectedProperty === undefined
        || !this.selectedSpecialWeaponsEvent.value
        || this.isLoading;
    },
    addShardsForUserButtonDisabled(): boolean {
      return !isValidWeb3Address(this.newAddShardsUser.user)
        || this.newAddShardsUser.eventId === undefined
        || this.newAddShardsUser.shardsAmount === undefined
        || this.isLoading;
    },
    onNewPrivatePartnerOrderButtonDisabled(): boolean {
      return !this.newPrivatePartnerOrder.receivers
        || this.newPrivatePartnerOrder.eventId === undefined
        || this.newPrivatePartnerOrder.orderOption === undefined
        || this.isLoading;
    },
    onNewPrivatePartnerMintButtonDisabled(): boolean {
      return !this.newPrivatePartnerMint.receivers
        || this.newPrivatePartnerMint.eventId === undefined
        || this.newPrivatePartnerMint.orderOption === undefined
        || this.isLoading;
    },
    onNewReserveForGiveawaysButtonDisabled(): boolean {
      return !isValidWeb3Address(this.newReserveForGiveaways.reservingAddress)
        || this.newReserveForGiveaways.eventId === undefined
        || this.newReserveForGiveaways.orderOption === undefined
        || this.newReserveForGiveaways.amount === undefined
        || this.isLoading;
    },
  },

  methods: {
    ...mapActions('specialWeaponsManager', [
      'startNewEvent',
      'getActiveSpecialWeaponsEvents',
      'setSpecialWeaponArt',
      'setSpecialWeaponDetails',
      'setSpecialWeaponWebsite',
      'setSpecialWeaponNote',
      'incrementEventCount',
      'addShards',
      'privatePartnerOrder',
      'privatePartnerMint',
      'reserveForGiveaways',
    ]) as StoreMappedActions,

    async getActiveEvents() {
      try {
        this.isLoading = true;
        this.activeSpecialWeaponsEvents = await this.getActiveSpecialWeaponsEvents();
      } finally {
        this.isLoading = false;
      }
    },

    async startNewSpecialWeaponsEvent() {
      this.isLoading = true;
      try {
        await this.startNewEvent(this.newEvent);
        this.newEvent = {
          name: '',
          element: undefined,
          period: undefined,
          supply: undefined,
          art: '',
          details: '',
          website: '',
          note: '',
        };
      } finally {
        this.isLoading = false;
      }
    },

    async setSpecialWeaponsEventProperty() {
      this.isLoading = true;
      try {
        switch (this.selectedSpecialWeaponsEvent.selectedProperty) {
        case SpecialWeaponsEventProperty.ART:
          await this.setSpecialWeaponArt({
            eventId: this.selectedSpecialWeaponsEvent.id!,
            art: this.selectedSpecialWeaponsEvent.value,
          });
          break;
        case SpecialWeaponsEventProperty.DETAILS:
          await this.setSpecialWeaponDetails({
            eventId: this.selectedSpecialWeaponsEvent.id!,
            details: this.selectedSpecialWeaponsEvent.value,
          });
          break;
        case SpecialWeaponsEventProperty.WEBSITE:
          await this.setSpecialWeaponWebsite({
            eventId: this.selectedSpecialWeaponsEvent.id!,
            website: this.selectedSpecialWeaponsEvent.value,
          });
          break;
        case SpecialWeaponsEventProperty.NOTE:
          await this.setSpecialWeaponNote({
            eventId: this.selectedSpecialWeaponsEvent.id!,
            note: this.selectedSpecialWeaponsEvent.value,
          });
          break;
        }
        this.selectedSpecialWeaponsEvent = {
          id: undefined,
          selectedProperty: undefined,
          value: '',
        };
      } finally {
        this.isLoading = false;
      }
    },

    async addShardsForUser() {
      this.isLoading = true;
      try {
        await this.addShards({
          user: this.newAddShardsUser.user,
          eventId: this.newAddShardsUser.eventId!,
          shardsAmount: this.newAddShardsUser.shardsAmount!,
        });
        this.newAddShardsUser = {
          user: '',
          eventId: undefined,
          shardsAmount: undefined,
        };
      } finally {
        this.isLoading = false;
      }
    },

    async onNewPrivatePartnerOrder() {
      this.isLoading = true;
      try {
        await this.privatePartnerOrder({
          receivers: this.newPrivatePartnerOrder.receivers.split(',').map(receiver => receiver.trim()),
          eventId: this.newPrivatePartnerOrder.eventId!,
          orderOption: this.newPrivatePartnerOrder.orderOption!,
        });
        this.newPrivatePartnerOrder = {
          receivers: '',
          eventId: undefined,
          orderOption: undefined,
        };
      } finally {
        this.isLoading = false;
      }
    },

    async onNewPrivatePartnerMint() {
      this.isLoading = true;
      try {
        await this.privatePartnerMint({
          receivers: this.newPrivatePartnerMint.receivers.split(',').map(receiver => receiver.trim()),
          eventId: this.newPrivatePartnerMint.eventId!,
          orderOption: this.newPrivatePartnerMint.orderOption!,
        });
        this.newPrivatePartnerMint = {
          receivers: '',
          eventId: undefined,
          orderOption: undefined,
        };
      } finally {
        this.isLoading = false;
      }
    },

    async onNewReserveForGiveaways() {
      this.isLoading = true;
      try {
        await this.reserveForGiveaways({
          reservingAddress: this.newReserveForGiveaways.reservingAddress,
          eventId: this.newReserveForGiveaways.eventId!,
          orderOption: this.newReserveForGiveaways.orderOption!,
          amount: this.newReserveForGiveaways.amount!,
        });
        this.newReserveForGiveaways = {
          reservingAddress: '',
          eventId: undefined,
          orderOption: undefined,
          amount: undefined,
        };
      } finally {
        this.isLoading = false;
      }
    },

    async onIncrementEventCount() {
      this.isLoading = true;
      try {
        await this.incrementEventCount();
      } finally {
        this.isLoading = false;
      }
    },
  }

});
