




























import Vue from 'vue';
import {mapActions} from 'vuex';
import {isValidWeb3Address} from '@/utils/common';

interface StoreMappedActions {
  mintGiveawayShield(payload: { to: string, stars: number, type: number }): Promise<void>;
}

enum Rarity {
  COMMON, UNCOMMON, RARE, EPIC, LEGENDARY
}

enum ShieldType {
  NON_SERIES, FOUNDERS, LEGENDARY
}

interface GiveawayShieldMint {
  to: string;
  stars?: Rarity;
  type?: ShieldType;
}

interface Data {
  giveawayShieldMint: GiveawayShieldMint;
  rarities: Rarity[];
  isLoading: boolean;
}

export default Vue.extend({
  data() {
    return {
      giveawayShieldMint: {
        to: '',
        stars: undefined,
        type: undefined,
      },
      rarities: [Rarity.COMMON, Rarity.UNCOMMON, Rarity.RARE, Rarity.EPIC, Rarity.LEGENDARY],
      shieldTypes: [ShieldType.NON_SERIES, ShieldType.FOUNDERS, ShieldType.LEGENDARY],
      isLoading: false,
      Rarity,
      ShieldType,
    } as Data;
  },

  computed: {
    mintShieldButtonDisabled(): boolean {
      return !isValidWeb3Address(this.giveawayShieldMint.to)
        || this.giveawayShieldMint.stars === undefined
        || this.giveawayShieldMint.type === undefined
        || this.isLoading;
    },
  },

  methods: {
    ...mapActions([
      'mintGiveawayShield',
    ]) as StoreMappedActions,

    async mintShield() {
      if (!isValidWeb3Address(this.giveawayShieldMint.to)
        || this.giveawayShieldMint.stars === undefined
        || this.giveawayShieldMint.type === undefined) {
        return;
      }
      try {
        this.isLoading = true;
        await this.mintGiveawayShield({
          to: this.giveawayShieldMint.to,
          stars: this.giveawayShieldMint.stars,
          type: this.giveawayShieldMint.type,
        });
        this.clearInputs();
      } finally {
        this.isLoading = false;
      }
    },

    clearInputs() {
      this.giveawayShieldMint = {
        to: '',
        stars: undefined,
        type: undefined,
      };
    }
  },
});
