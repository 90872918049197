






export default {
  props: ['bgColor', 'width'],
};

