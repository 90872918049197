









import { secondsToRelativeAge } from '@/utils/date-time';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    hash: {
      type: String as PropType<string>,
    },
    title: {
      type: String as PropType<string>,
    },
    link: {
      type: String as PropType<string>,
    },
    timestamp: {
      type: Number as PropType<number>,
    },
    isRead: {
      type: Boolean as PropType<boolean | undefined>,
    }
  },
  computed: {
    getUpdateAge(): string {
      const currentDate = new Date();
      const dateAge = secondsToRelativeAge(+currentDate/1000 - this.timestamp/1000);
      return dateAge + ' ago';
    }
  },
  methods: {
    updateIsRead() {
      if (!this.isRead) {
        this.$emit('update:isRead', true);
        this.$emit('refresh-update-popup');
      }
    }
  },
});
