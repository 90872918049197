






















































import Vue from 'vue';
import {mapActions} from 'vuex';
import {addTokenToMetamask, toBN} from '@/utils/common';
import {PropType} from 'vue/types/options';
import {SupportedProject} from '@/views/Treasury.vue';

interface Data {
  images: any;
  multiplier: string;
  distributionTime: string;
  tokensClaimed: number;
  skillToPartnerRatio: string;
  updateInterval: ReturnType<typeof setInterval> | null;
  detailsOpened: boolean;
}

export default Vue.extend({
  props: {
    partnerProject: {
      type: Object as PropType<SupportedProject>,
      required: true,
    },
  },

  data() {
    return {
      images: require.context('../assets/partners/', false, /\.png$/),
      multiplier: '1',
      distributionTime: '0',
      tokensClaimed: 0,
      skillToPartnerRatio: '0',
      updateInterval: null,
      detailsOpened: false,
    } as Data;
  },

  computed: {
    progressBarWidth(): string {
      if (!this.partnerProject.tokenSupply) return '0%';
      return `${Math.round((this.tokensClaimed / +this.partnerProject.tokenSupply) * 100)}%`;
    },

    moneyPerUnclaimed(): string {
      return toBN(this.partnerProject.tokenPrice).div(toBN(10).pow(18)).div(+this.skillToPartnerRatio).times(+this.multiplier).toFixed(2);
    },

    isValor(): boolean {
      return this.partnerProject.isValor;
    }
  },

  methods: {
    ...mapActions('treasury', ['getPartnerProjectMultiplier', 'getPartnerProjectClaimedAmount', 'getSkillToPartnerRatio', 'getPartnerProjectDistributionTime']),

    async update(): Promise<void> {
      const currentMultiplier = await this.getPartnerProjectMultiplier(this.partnerProject.id);
      this.multiplier = toBN(currentMultiplier).div(toBN(10).pow(18)).toFixed(4);

      this.distributionTime = await this.getPartnerProjectDistributionTime(this.partnerProject.id);

      const currentClaimedTokens = await this.getPartnerProjectClaimedAmount(this.partnerProject.id);
      this.tokensClaimed = +toBN(currentClaimedTokens).div(toBN(10).pow(18)).toFixed(2);

      const currentSkillToPartnerRatio = await this.getSkillToPartnerRatio(this.partnerProject.id);
      this.skillToPartnerRatio = toBN(1).dividedBy(toBN(currentSkillToPartnerRatio).dividedBy(toBN(2).exponentiatedBy(64))).toFixed(4);
    },

    toggleDetails() {
      this.detailsOpened = !this.detailsOpened;
    },

    async addTokenToMetamask() {
      await addTokenToMetamask(this.partnerProject.tokenAddress, this.partnerProject.tokenSymbol);
    },

    openPartnerWebsite() {
      window.open(this.partnerProject.website, '_blank');
    }
  },

  async created() {
    await this.update();
    this.updateInterval = setInterval(async () => {
      await this.update();
    }, 10000);
  },

  beforeDestroy() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  }
});

