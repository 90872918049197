




















































































import {mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { getCharacterArt } from '../../character-arts-placeholder';
import Events from '../../events';
import { CharacterPower, CharacterTrait } from '../../interfaces';
import { RequiredXp } from '../../interfaces';
import Vue from 'vue';
import { toBN, fromWeiEther } from '../../utils/common';
import { getCleanName } from '../../rename-censor';
import {Nft, NftStatus} from '../../interfaces/Nft';
import {Accessors} from 'vue/types/options';


interface Data {
  isLoading: boolean;
  character?: Nft;
  charOnRaid: string[],
  sideBarBlacksmith: string[],
  isCombatPage: boolean,
  isTeamAdventureTab: boolean,
}

interface RaidMappedActions {
  fetchIsCharacterRaiding(payload: { characterID: string }): Promise<boolean>;
}

interface StoreMappedGetters {
  charactersWithIds(ids: (string | number)[]): Nft[];
  getCharacterPower(characterId: number): Promise<string>;
  getCharacterSecondsPerStamina(): number
}

interface StoreMappedCombatGetters {
  getIsInCombat: boolean;
  fightGasOffset: string;
  fightBaseline: string;
}

interface StoreMappedActions {
  getCharacterBusyStatus(payload: { characterId: string | number }): Promise<number>;
}
import i18n from '@/i18n';
import EarningsCalculator from './EarningsCalculator.vue';
import { COMBAT_TAB } from '@/views/Combat.vue';

export default Vue.extend({
  props: ['toggled', 'currentPath'],
  watch: {
    currentPath(newVal) {
      if(newVal === '/blacksmith'){
        this.setActiveTab(this.sideBarBlacksmith[0]);
      }
    },
    '$route' () {
      this.isCombatPage = (this as any).$router.currentRoute.path === '/combat';
      this.isTeamAdventureTab = (this as any).$route.query?.tab === COMBAT_TAB[COMBAT_TAB.TeamAdventure];
    }
  },
  computed: {
    ...mapGetters(['charactersWithIds']) as Accessors<StoreMappedGetters>,
    ...mapState(['maxStamina', 'currentCharacterId', 'ownedCharacterIds']),
    ...mapGetters([
      'currentCharacter',
      'currentCharacterStamina',
      'getCharacterName',
      'getCharacterStamina',
      'charactersWithIds',
      'ownCharacters',
      'timeUntilCharacterHasMaxStamina',
      'getIsCharacterViewExpanded',
      'getCharacterIsInArena',
      'getCharacterPower',
      'getCharacterSecondsPerStamina'
    ]),
    ...(mapGetters('combat', [
      'getIsInCombat',
      'fightGasOffset',
      'fightBaseline',
    ]) as Accessors<StoreMappedCombatGetters>),
    isLoadingCharacter(): boolean {
      return !this.currentCharacter;
    },
    filteredCharactersForList(): any {
      const items: any = this.ownCharacters;
      for (const x of items) {
        x.isSelected = false;
        this.setCharacterOnRaid(x.id);
      }
      return items;
    },
  },
  data() {
    return {
      traits: CharacterTrait,
      isPlaza: false,
      sideBarBlacksmith: [
        {
          id: 0,
          title: i18n.t('blacksmith.weapon'),
          desc: i18n.t('blacksmith.weaponDesc'),
          iconName: 'icon-weapon',
          status: 'active',
          route: 'weapon'
        },
        {
          id: 1,
          title: i18n.t('blacksmith.equipment'),
          desc: i18n.t('blacksmith.equipmentDesc'),
          iconName: 'icon-equipment',
          status: '',
          route: 'equipment'
        },
        {
          id: 2,
          title: i18n.t('blacksmith.dustStorage'),
          desc: i18n.t('blacksmith.dustStorageDesc'),
          iconName: 'icon-dust',
          status: '',
          route: 'dust'
        },
        {
          id: 3,
          title: i18n.t('blacksmith.land'),
          desc: i18n.t('blacksmith.landDesc'),
          iconName: 'icon-land',
          status: '',
          route: 'land'
        },
      ],
      charOnRaid: [],
      charOnPvp: [],
      character: undefined,
      isCombatPage: false,
      isTeamAdventureTab: false,
      NftStatus
    } as unknown as Data;
  },
  methods: {
    ...mapActions([
      'getCharacterBusyStatus',
    ]) as StoreMappedActions,
    ...(mapActions(['fetchIsCharacterRaiding']) as RaidMappedActions),
    ...mapMutations(['setCurrentCharacter']),
    getCharacterArt,
    CharacterPower,
    RequiredXp,
    async isCharacterAlreadyRaiding(characterID: string) {
      return await this.fetchIsCharacterRaiding({ characterID });
    },
    changeCurrentCharacter(characterID: number) {
      if((this.isCombatPage && this.isTeamAdventureTab)) return;

      this.setCurrentCharacter(characterID);
    },
    setListClassForSelChar(id: string, currentCharId: string): any {
      const fightMultiplier = Number(localStorage.getItem('fightMultiplier')) || 1;

      if(this.isTeamAdventureTab && this.isCombatPage) {
        const characterStamina = this.getCharacterStamina(id);

        return characterStamina >= (fightMultiplier * 40) ? 'character-highlight' : 'character';
      } else {
        if (id === currentCharId) {
          this.setSelectedCharacter(id);
          return 'character-highlight';
        }
        else
          return 'character';
      }
    },
    getNftStatus(activeCharacter: any) {
      this.composeCharacterData(activeCharacter.id).then(data => {
        if (data.status !== undefined && data.status in NftStatus) {
          for (const character of this.filteredCharactersForList) {
            if (character.id === activeCharacter.id) {
              character.pvpStatus = NftStatus[data.status];
            }
          }
        }
        else {
          for (const character of this.filteredCharactersForList) {
            if (character.id === activeCharacter.id) {
              character.pvpStatus = 'BUSY';
            }
          }
        }
      });
    },
    async composeCharacterData(id: any) {
      let characterStatus;
      // eslint-disable-next-line prefer-const
      characterStatus = await this.charactersWithIds([id]).filter(Boolean)[0];
      characterStatus.status = +await this.getCharacterBusyStatus({ characterId: id });
      return characterStatus;
    },
    async setCharacterOnRaid(id: any) {
      const charId = id;
      if (await this.isCharacterAlreadyRaiding(id)) {
        this.charOnRaid.push(charId);
      }
    },
    getIfCharacterIsInRaid(id: any) {
      let toReturnWarning;
      this.charOnRaid.forEach((characterOnRaid: any) => {
        if (id === characterOnRaid) {
          toReturnWarning = true;
        }
      });
      return toReturnWarning;
    },
    setActiveTab(tab: any) {
      (this as any).$router.push({ path: 'blacksmith', query: { tab: tab.route } }).catch(() => {});
      this.sideBarBlacksmith.forEach((sidebarTab: any) => {
        if (sidebarTab.id === tab.id) {
          sidebarTab.status = 'active';
        }
        else
          sidebarTab.status = '';
      });
    },
    toolTipHtml(time: string): string {
      const minutes = this.getCharacterSecondsPerStamina / 60;
      if(minutes % 1 !== 0) {
        return i18n.t('blacksmith.regenerate', {minutes: minutes.toFixed(4),time}).toString();
      } else {
        return i18n.t('blacksmith.regenerate', {minutes,time}).toString();
      }
    },
    setSelectedCharacter(id: any) {
      if((this.isCombatPage && this.isTeamAdventureTab)) return;

      for (const a of this.filteredCharactersForList) {
        if (a.id === id) {
          a.isSelected = true;
        } else {
          a.isSelected = false;
        }
      }
    },
    //toggle the sidebar
    hideSideBar(bol: any) {
      Events.$emit('toggle-sideBar', bol);
    },
    formattedSkill(skill: number): number {
      const skillBalance = fromWeiEther(skill.toString());
      return toBN(skillBalance).toNumber();
    },
    getCleanCharacterName(id: string): string {
      return getCleanName(this.getCharacterName(id));
    },
    setIdForElement(traits: any, isSelected: boolean) {
      if (traits === '0') {
        if (isSelected || (this.isCombatPage && this.isTeamAdventureTab)) {
          return 'fire-element';
        }
        else {
          return 'w-fire-element';
        }
      }
      else if (traits === '1') {
        if (isSelected || (this.isCombatPage && this.isTeamAdventureTab)) {
          return 'earth-element';
        }
        else {
          return 'w-earth-element';
        }
      }
      else if (traits === '2') {
        if (isSelected || (this.isCombatPage && this.isTeamAdventureTab)) {
          return 'lightning-element';
        }
        else {
          return 'w-lightning-element';
        }
      }
      else if (traits === '3') {
        if (isSelected || (this.isCombatPage && this.isTeamAdventureTab)) {
          return 'water-element';
        }
        else {
          return 'w-water-element';
        }
      }
      else
        return '';
    },
  },
  created() {
    this.isCombatPage = (this as any).$router.currentRoute.path === '/combat';
    this.isTeamAdventureTab = (this as any).$route.query?.tab === COMBAT_TAB[COMBAT_TAB.TeamAdventure];
  },
  components: { EarningsCalculator }
});
