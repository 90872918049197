















































































































import Vue from 'vue';
import {fromWeiEther, toBN} from '../utils/common';
import {PropType} from 'vue/types/options';
import axios from 'axios';
import {getConfigValue} from '@/contracts';
import i18n from '@/i18n';
import {TranslateResult} from 'vue-i18n';
import '@/mixins/general';
import Hint from '@/components/Hint.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { SupportedProject } from '@/views/Treasury.vue';
import { Accessors } from 'vue/types/options';
import { getCleanName } from '@/rename-censor';


interface StoreMappedTreasuryGetters {
  getPartnerProjects: SupportedProject[];
  getProjectMultipliers: Record<number, string>
}

interface CombatResult {
  isVictory: boolean;
  playerRoll: string
  enemyRoll: string;
  xpGain: string;
  skillGain: string;
}

interface StoreMappedTreasuryState {
  payoutCurrencyId: string;
  partnerProjectMultipliers: Record<number, string>;
  partnerProjectRatios: Record<number, string>;
  defaultSlippage: string;
}
interface StoreMappedTreasuryActions{
  getPartnerProjectMultiplier(id: number): Promise<string>;
  fetchPartnerProjects(): Promise<void>;
}

interface IFightResults {
  fightResultsMap: Record<number, CombatResult>,
  bnbGasUsed: string
}

export default Vue.extend({
  components: {
    Hint
  },
  props: {
    fightResults: {
      type: Object as PropType<IFightResults>,
      default() {
        return {} as IFightResults;
      },
    }
  },
  data() {
    return {
      skillPrice: 0,
      valorPrice: 0,
      gasToken: '',
      showAds: false,
      highestMultiplier: 0,
      highestMultiplierProject: {} as Record<string, SupportedProject>,
      partnerProjects: [] as SupportedProject[],
    };
  },
  watch: {
    getPartnerProjects(newval) {
      this.partnerProjects = newval;
      this.getProjectMultiplier('SKILL');
      this.getProjectMultiplier('VALOR');
    }
  },
  computed: {
    ...mapGetters([
      'getCharacterName',
      'ownCharacters',
      'charactersWithIds'
    ]),
    ...(mapGetters('treasury', ['getPartnerProjects', 'getProjectMultipliers']) as Accessors<StoreMappedTreasuryGetters>),
    ...(mapState('treasury',
      ['payoutCurrencyId','partnerProjectRatios','defaultSlippage'])as Accessors<StoreMappedTreasuryState>)
  },
  methods: {
    ...(mapActions('treasury', ['getPartnerProjectMultiplier', 'fetchPartnerProjects']) as StoreMappedTreasuryActions),
    async fetchPrices(): Promise<void> {
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=cryptoblades,binancecoin&vs_currencies=usd');
      this.skillPrice = response.data?.cryptoblades.usd;
    },
    async fetchValorPrice(): Promise<void> {
      const response = await axios.get('https://api.dexscreener.com/latest/dex/pairs/bsc/0x8730c8dedc59e3baffb67bf1fa63d4f0e2d9ecc9');
      this.valorPrice = +response.data?.pair?.priceUsd || 0;
    },
    async getProjectMultiplier(tokenSymbol: 'SKILL' | 'VALOR') {
      const filteredPartnerProjects = this.partnerProjects && this.partnerProjects.filter(partnerProject => partnerProject.tokenSymbol.includes(tokenSymbol));
      if(filteredPartnerProjects.length > 0) {
        filteredPartnerProjects.map(async (project) => {
          await this.getPartnerProjectMultiplier(project.id);
        });
        const projectMultipliers = await this.getProjectMultipliers;
        this.highestMultiplierProject[tokenSymbol] = filteredPartnerProjects.sort((a, b) => {
          const highestMultiplierA = +(toBN(+projectMultipliers[a.id]).div(toBN(10).pow(18)).toFixed(4));
          const highestMultiplierB = +(toBN(+projectMultipliers[b.id]).div(toBN(10).pow(18)).toFixed(4));

          return highestMultiplierA - highestMultiplierB;
        })[0];
      }
    },
    getCleanCharacterName(id: string): string {
      return getCleanName(this.getCharacterName(id));
    },
    formattedInUsd(value: string): string {
      if(!value) return '';
      return `($${value})`;
    },
    calculateSkillPriceInUsd(skill: number): number {
      if(!skill) return 0;
      return (skill as unknown as number * this.skillPrice as unknown as number);
    },
    checkStorage() {
      if (process.env.NODE_ENV === 'development') this.showAds = false;
      else this.showAds = localStorage.getItem('show-ads') === 'true';
    },
    formattedOutcome(fightResults: CombatResult): TranslateResult {
      if(fightResults.isVictory) return i18n.t('combatResults.won');
      else return i18n.t('combatResults.lost');
    },
    formattedXpGain(fightResults: CombatResult): string {
      return fightResults.xpGain + ' xp';
    },
    isGenesisCharacter(characterID: number): boolean {
      const currentCharacter = this.charactersWithIds([characterID])[0];

      return currentCharacter?.version === 0;
    },
    formattedSkill(fightResults: CombatResult): string {
      return toBN(fromWeiEther(fightResults.skillGain)).toFixed(6);
    },
    fightRewardsAmountInUSD(fightResults: CombatResult, characterID: number): string {
      if(!this.isGenesisCharacter(characterID)) {
        return ((this.valorPrice * +this.formattedSkill(fightResults) * this.highestProjectMultiplier(characterID)) || 0).toFixed(4);
      } else {
        return ((this.skillPrice * +this.formattedSkill(fightResults) * this.highestProjectMultiplier(characterID)) || 0).toFixed(4);
      }
    },
    getAggregatedCharacterEarnings() {
      let totalEarnings = 0;
      for(const key in this.fightResults.fightResultsMap) {
        const fightResult = this.fightResults.fightResultsMap[key];

        if(!this.isGenesisCharacter(+key)) {
          totalEarnings += ((this.valorPrice * +this.formattedSkill(fightResult) * this.highestProjectMultiplier(+key)) || 0);
        } else {
          totalEarnings += ((this.skillPrice * +this.formattedSkill(fightResult) * this.highestProjectMultiplier(+key)) || 0);
        }
      }

      return totalEarnings.toFixed(4);
    },
    getCharactersWonAFight() {
      let winner = 0;
      for(const key in this.fightResults.fightResultsMap) {
        const fightResult = this.fightResults.fightResultsMap[key];

        if(fightResult.isVictory) {
          winner += 1;
        }
      }

      return winner;
    },
    highestProjectMultiplier(characterID: number): number {
      return +(toBN(+this.getProjectMultipliers[+this.highestMultiplierProject[!this.isGenesisCharacter(characterID) ? 'VALOR' : 'SKILL']?.id || 0])
        .div(toBN(10).pow(18)).toFixed(4) || 0);
    }
  },
  async mounted() {
    await this.fetchPartnerProjects();
    this.gasToken = getConfigValue('currencySymbol') || 'BNB';

    await this.fetchValorPrice();
    await this.fetchPrices();
    this.checkStorage();
  },
});
