






































































import Vue from 'vue';

export default Vue.extend({
  props: {
    activeTab: {
      type: String,
      required: true
    },
    forgeCost: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    disableForge: {
      type: Boolean,
      required: false,
      default: false
    },
    disableX10Forge: {
      type: Boolean,
      required: false,
      default: false
    },
    disableX10ForgeWithStaked: {
      type: Boolean,
      required: false,
      default: false
    },
    useStakedForForge: {
      type: Boolean,
      required: false,
      default: false
    },
    disableUseStakedForForge: {
      type: Boolean,
      required: false,
      default: false
    },
    canClaim: {
      type: Boolean,
      required: false,
      default: false
    },
    ownWeapons: {
      type: Array,
      required: true,
    },
    reforgeWeaponId: {
      type: String,
      required: false,
      default: null
    },
  },

});

