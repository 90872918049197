



































import PartneredProject from '@/components/PartneredProject.vue';
import Vue from 'vue';
import {Accessors} from 'vue/types/options';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

export interface SupportedProject {
  id: number;
  name: string;
  tokenSymbol: string;
  tokenAddress: string;
  tokenSupply: number;
  tokensClaimed: number;
  tokenPrice: number;
  isActive: boolean;
  isValor: boolean;
  logo: string;
  details: string;
  website: string;
  note: string;
}

interface StoreMappedTreasuryGetters {
  getPartnerProjects: SupportedProject[];
}

interface StoreMappedState {
  currentNetworkId: number;
}

interface StoreMappedTreasuryState {
  payoutCurrencyId: string;
}

interface StoreMappedTreasuryActions {
  fetchPartnerProjects(): Promise<void>;
}

interface Data {
  updateInterval: ReturnType<typeof setInterval> | null;
}

export default Vue.extend({
  components: { PartneredProject },

  data() {
    return {
      updateInterval: null
    } as Data;
  },

  computed: {
    ...(mapGetters('treasury', ['getPartnerProjects']) as Accessors<StoreMappedTreasuryGetters>),
    ...(mapState(['currentNetworkId']) as Accessors<StoreMappedState>),
    ...(mapState('treasury',['payoutCurrencyId'])as Accessors<StoreMappedTreasuryState>),

    genesisPartnerProjects(): SupportedProject[] {
      return this.getPartnerProjects.filter(p => !p.isValor);
    },

    nonGenesisPartnerProjects(): SupportedProject[] {
      return this.getPartnerProjects.filter(p => p.isValor);
    }
  },

  methods: {
    ...(mapActions('treasury',['fetchPartnerProjects']) as StoreMappedTreasuryActions),
    ...mapMutations('treasury', ['updatePayoutCurrencyId']),

    getLogoFile(projectName: string): string {
      return `${projectName.toLowerCase()}.png`;
    },

    openFormulaDetails(): void {
      (this.$refs['formula-details-modal'] as any).show();
    }
  },

  async mounted() {
    await this.fetchPartnerProjects();
    this.updateInterval = setInterval(async () => {
      await this.fetchPartnerProjects();
    }, 3000);
  },

  beforeDestroy() {
    if(this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  }

});

