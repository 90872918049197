






























































































































































































import Vue from 'vue';
import {getEnemyArt} from '../../../enemy-art';
import CharacterArt from '../../CharacterArt.vue';
import { Accessors } from 'vue/types/options';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { CharacterTrait, ICharacter, IPowerData, ITarget } from '@/interfaces';
import gasp from 'gsap';
import i18n from '@/i18n';
import { fromWeiEther, toBN } from '@/utils/common';
import BigNumber from 'bignumber.js';
import ModalContainer from '../../modals/ModalContainer.vue';

interface StoreMappedCombatGetters {
  getTargetsByCharacterId(currentCharacterId: number): ITarget | any[],
}

interface StoreMappedCombatActions {
  fetchCharacterStamina(characterId: number): Promise<void>;
  fetchTargets(
    { characterId }:
    { characterId: number }): Promise<void>;
  fetchExpectedPayoutForMonsterPower(
    { power }:
    { power: stringOrNumber }): Promise<string>;
  getFightXpGain(): Promise<number>;
  getNativeTokenPriceInUsd(): Promise<string>;
  getCurrentSkillPrice(): Promise<string>;
  getCombatTokenChargePercent(): Promise<string>;
  doEncountersPayNative({ charactersId, targets, fightMultiplier, offsetCost, }: {
    charactersId: number[];
    targets: number[];
    fightMultiplier: number[];
    offsetCost: BigNumber;
  }): Promise<{
    isVictory: boolean;
    playerRoll: string;
    enemyRoll: string;
    xpGain: number;
    skillGain: number;
    bnbGasUsed: string;
  } | undefined>;
  fetchFightRewardSkill(): Promise<string>;
  fetchFightRewardValor(): Promise<string>;
  fetchFightRewardXp(): Promise<string[][]>;
  fetchHourlyAllowance(): Promise<string>;
}

type stringOrNumber = string | number;
type stringOrNull = string | null;
type numberOrNull = number | null;

interface ITeamAdventureData {
  error: stringOrNull,
  errorCode: numberOrNull,
  showCosmetics: boolean,
  resultsAvailable: boolean,
  selectedCharacterPowerData: IPowerData;
  selectedCharacterID: number,
  selectedCharacterTargets: any[] | ITarget,
  selectedTargetByCharacter: Record<number, ITarget>
  selectedTargetByPayout: Record<number, stringOrNumber>,
  charactersPowerData: Record<number, IPowerData>,
  charactersWeapon: Record<number, number>,
  // eslint-disable-next-line no-undef
  intervalSecondsFn: NodeJS.Timeout | null;
  // eslint-disable-next-line no-undef
  intervalMinutesFn: NodeJS.Timeout | null;
  timeSeconds: number;
  timeMinutes: number;
  activeCard: number;
  fightXpGain: number;
  targetExpectedPayouts: Record<number, stringOrNumber>
  isLoadingTargets: boolean,
  waitingResults: boolean,
  minutesToNextAllowance: any,
  lastAllowanceSkill: any,
  fightResults: any;
  CHANGE_RPC_ERROR_CODE: number
}

interface StoreMappedGetters {
  ownCharacters: Array<ICharacter>,
  allStaminas: Record<number, number>;
  getPowerData(characterId: number): IPowerData;
  charactersWithIds(characterID: any): Array<ICharacter>
}

interface StoreMappedCombatMutations {
  setIsInCombat(isInCombat: boolean): boolean,
}

interface StoreMappedActions{
  fetchCharacterWeapon(characterId: string | number): Promise<number>;
}

const CHANGE_RPC_ERROR_CODE = -32005;

export default Vue.extend({
  props:{
    fightMultiplier: {
      default:  Number(localStorage.getItem('fightMultiplier')),
      required: true
    },
    staminaPerFight: {
      default: 40,
      required: true
    }
  },
  data() {
    return {
      showCosmetics: false,
      selectedCharacterPowerData: {} as IPowerData,
      resultsAvailable: false,
      selectedCharacterID: -1,
      selectedCharacterTargets: [],
      charactersWeapon: {},
      charactersPowerData: {},
      intervalMinutesFn: null,
      intervalSecondsFn: null,
      timeMinutes: 0,
      timeSeconds: 0,
      activeCard: -1,
      fightXpGain: 32,
      targetExpectedPayouts: {},
      isLoadingTargets: false,
      selectedTargetByCharacter: {},
      selectedTargetByPayout: {},
      waitingResults: false,
      fightResults: {},
      error: null,
      errorCode: null,
      lastAllowanceSkill: null,
      minutesToNextAllowance: null,
      CHANGE_RPC_ERROR_CODE
    } as ITeamAdventureData;
  },
  computed: {
    ...(mapGetters([
      'ownCharacters',
      'getCharacterCosmetic',
      'allStaminas',
      'getPowerData',
      'charactersWithIds'
    ]) as Accessors<StoreMappedGetters>),
    ...(mapGetters('combat', [
      'getTargetsByCharacterId'
    ]) as Accessors<StoreMappedCombatGetters>),
    isGenesisCharacter(): boolean {
      const currentCharacter = this.charactersWithIds([this.selectedCharacterID])[0];

      return currentCharacter?.version === 0;
    },
    availableCharactersToFight() {
      let availableCharactersCounter = 0;
      for (const key in this.selectedTargetByCharacter) {
        const element = this.selectedTargetByCharacter[key];

        if(element) availableCharactersCounter+=1;
      }

      return availableCharactersCounter;
    },
    getCharacterIdsWithTarget() {
      const characters: Array<number> = [];
      for (const key in this.selectedTargetByCharacter) {
        const target = this.selectedTargetByCharacter[key];

        if(target) {
          characters.push(+key);
        }
      }

      return characters;
    },
    getTargetIndexesByCharacters() {
      const targets: Array<number> = [];
      for (const key in this.selectedTargetByCharacter) {
        const target = this.selectedTargetByCharacter[key];

        if(target && target?.targetIndex !== undefined) {
          targets.push(+target?.targetIndex);
        }
      }

      return targets;
    },
    updateResults(): any[] {
      return [this.fightResults, this.error, this.errorCode];
    },
    getCharactersFightMultiplier() {
      const multiplier: Array<number> = [];
      for (const key in this.selectedTargetByCharacter) {
        const target = this.selectedTargetByCharacter[key];

        if(target) {
          multiplier.push(this.fightMultiplier);
        }
      }

      return multiplier;
    }
  },
  components: {
    CharacterArt,
    ModalContainer
  },
  watch: {
    error(newValue) {
      this.$emit('error', newValue);
    },
    errorCode(newValue) {
      this.$emit('errorCode', newValue);
    },
    async updateResults([fightResults, error]) {
      this.resultsAvailable = fightResults !== null;
      this.waitingResults = fightResults === null && error === null;
      this.setIsInCombat(this.waitingResults);
      if (this.resultsAvailable && error === null) (this as any).$bvModal.show('modal-info');
    },
  },
  methods: {
    ...(mapActions('combat',
      [
        'fetchTargets',
        'getFightXpGain',
        'fetchExpectedPayoutForMonsterPower',
        'getNativeTokenPriceInUsd',
        'getCurrentSkillPrice',
        'getCombatTokenChargePercent',
        'doEncountersPayNative',
        'fetchFightRewardSkill',
        'fetchCharacterStamina',
        'fetchFightRewardValor',
        'fetchFightRewardXp',
        'fetchHourlyAllowance'
      ]) as StoreMappedCombatActions),
    ...mapActions(['fetchCharacterWeapon']) as StoreMappedActions,
    getEnemyArt,
    ...(mapMutations('combat', ['setIsInCombat']) as StoreMappedCombatMutations),
    checkStorage() {
      this.showCosmetics = localStorage.getItem('showCosmetics') !== 'false';
    },
    getCharacterTrait(trait: CharacterTrait) {
      return CharacterTrait[trait];
    },
    hasSameTarget(latestTargets: Record<number, Record<number, ITarget>>) {
      for (const key in this.selectedTargetByCharacter) {
        const element = this.selectedTargetByCharacter[+key];
        const latestTarget = latestTargets[+key];

        if(element && element.targetIndex !== undefined &&
          element.original === latestTarget[element?.targetIndex].original)
          return true;
      }

      return false;
    },
    async getHourlyAllowance(){
      const fetchHourlyAllowance = await this.fetchHourlyAllowance();
      this.lastAllowanceSkill = this.formattedSkill(fetchHourlyAllowance);
    },
    async onClickEncounter() {
      let hasZeroAllowance = false;

      for (const key in this.targetExpectedPayouts) {
        if (Object.prototype.hasOwnProperty.call(this.targetExpectedPayouts, key)) {
          const element = this.targetExpectedPayouts[key];

          if(element === '0') {
            hasZeroAllowance = true;
          }
        }
      }

      if(hasZeroAllowance){
        await this.getHourlyAllowance();
        (this.$refs['no-skill-warning-modal'] as any).show();
      } else{
        this.fightTargets();
      }
    },
    async totalOffsetToPayInNativeToken() {
      let totalOffsetToPayInNativeToken: BigNumber = new BigNumber(0);
      for (const key in this.selectedTargetByCharacter) {
        const target = this.selectedTargetByCharacter[key];
        if(target) {
          const targetPower = this.selectedTargetByCharacter[key].power;
          const expectedPayoutWei = new BigNumber(
            await this.fetchExpectedPayoutForMonsterPower({ power: targetPower })
          );
          const nativeTokenPriceUsd = new BigNumber(
            await this.getNativeTokenPriceInUsd()
          );
          const skillPriceUsd = new BigNumber(await this.getCurrentSkillPrice());
          const tokenChargePercentage = await this.getCombatTokenChargePercent();
          const offsetToPayInNativeToken = expectedPayoutWei
            .multipliedBy(tokenChargePercentage)
            .div(100)
            .multipliedBy(skillPriceUsd.gt(0) ? skillPriceUsd : 1)
            .div(nativeTokenPriceUsd.gt(0) ? nativeTokenPriceUsd : 1)
            .integerValue(BigNumber.ROUND_DOWN);
          totalOffsetToPayInNativeToken = totalOffsetToPayInNativeToken.plus(
            offsetToPayInNativeToken.multipliedBy(this.fightMultiplier)
          );
        }
      }

      return totalOffsetToPayInNativeToken;
    },
    async fightTargets() {
      if (this.availableCharactersToFight <= 0) {
        return;
      }

      this.waitingResults = true;
      const latestTarget:  Record<number, Record<number, ITarget>> = {};
      // Force a quick refresh of targets
      for (let i = 0; i < this.ownCharacters.length; i++) {
        const character = this.ownCharacters[i];
        await this.fetchTargets({ characterId: character.id });
        latestTarget[character.id] = this.getTargetsByCharacterId(+this.selectedCharacterID) as any[];
      }

      // If the targets list no longer contains the chosen target, return so a new target can be chosen
      if(!this.hasSameTarget(latestTarget)) {
        this.waitingResults = false;
        this.clearSelectedTargetByCharacter();

        return;
      }
      this.fightResults = null;
      //this.error = null;
      this.setIsInCombat(this.waitingResults);
      try {
        const offsetToPayInNativeToken = await this.totalOffsetToPayInNativeToken();
        this.fightResults = await this.doEncountersPayNative({
          charactersId: this.getCharacterIdsWithTarget,
          targets: this.getTargetIndexesByCharacters,
          fightMultiplier: this.getCharactersFightMultiplier,
          offsetCost: offsetToPayInNativeToken
        });
        this.clearSelectedTargetByCharacter();
        await this.fetchFightRewardSkill();
        await this.fetchFightRewardValor();
        await this.fetchFightRewardXp();
        for (let i = 0; i < this.ownCharacters.length; i++) {
          await this.fetchCharacterStamina(this.ownCharacters[i].id);
        }
        this.error = null;
        this.errorCode = null;
      } catch (error: any) {
        console.error(error);
        this.error = error.message;
        if (this.error?.includes(this.CHANGE_RPC_ERROR_CODE.toString())) {
          this.errorCode = this.CHANGE_RPC_ERROR_CODE;
        }
      }
    },
    formattedSkill(skill: stringOrNumber) {
      const skillBalance = fromWeiEther(skill.toString());
      return `${toBN(skillBalance).toFixed(6)} ${this.isGenesisCharacter ? 'SKILL' : 'VALOR'}`;
    },
    async onClickSelectTarget(selectedCharacterID: number) {
      this.isLoadingTargets = true;
      this.selectedCharacterID = selectedCharacterID;
      (this.$refs['select-target-modal'] as any).show();
      await this.fetchTargets({characterId: this.selectedCharacterID});
      this.selectedCharacterTargets = await this.getTargetsByCharacterId(+this.selectedCharacterID);

      this.selectedCharacterPowerData = this.charactersPowerData[+this.selectedCharacterID];

      await this.getExpectedPayouts();
      this.isLoadingTargets = false;
    },
    async onSelectTargetEnemy(target: ITarget, targetIndex: number) {
      Vue.set(this.selectedTargetByCharacter, this.selectedCharacterID, {...target, targetIndex });
      (this.$refs['select-target-modal'] as any).hide();
      await this.getExpectedPayoutsByCharacterTarget(this.selectedCharacterID);
    },
    clearSelectedTargetByCharacter() {
      for(const key in this.selectedTargetByCharacter) {
        Vue.delete(this.selectedTargetByCharacter, +key);
      }
    },
    async getExpectedPayouts() {
      if(!this.selectedCharacterTargets) return;
      const expectedPayouts = new Array(4);
      const targets = this.selectedCharacterTargets as ITarget[];
      for(let i = 0; i < targets.length; i++) {
        const expectedPayout = await this.fetchExpectedPayoutForMonsterPower({ power: targets[i].power });
        expectedPayouts[i] = expectedPayout;
        Vue.set(this.targetExpectedPayouts, i, expectedPayouts[i]);
      }
    },
    async getExpectedPayoutsByCharacterTarget(characterID: number) {
      const target = this.selectedTargetByCharacter[characterID];

      const expectedPayout = await this.fetchExpectedPayoutForMonsterPower({ power: target.power });
      Vue.set(this.selectedTargetByPayout, characterID, expectedPayout);
    },
    enter(el: HTMLElement, done: any) {
      gasp.to(el, {
        opacity: 1,
        y: 0,
        duration: 0.5,
        onComplete: done,
        delay: el.dataset.index as any * 0.1
      });
    },
    beforeEnter(el: HTMLElement){
      el.style.opacity = '0';
      el.style.transform = 'translateY(100px)';
    },
    charHasStamina(characterID: number){
      return this.allStaminas[+characterID] >= this.staminaPerFight;
    },
    changeColorChange(stat: string){
      let bgColor;
      if(stat.toUpperCase() === 'UNLIKELY'){
        bgColor =  'background-color: #B10000 !important';
      }else if(stat.toUpperCase() === 'VERY LIKELY'){
        bgColor =  'background-color: #7BA224 !important';
      }else if(stat.toUpperCase() === 'POSSIBLE'){
        bgColor =  'background-color: #D16100 !important';
      }else{
        bgColor = 'background-color: #ff7700 !important';
      }
      return bgColor;
    },
    getWinChance(enemyPower: number, enemyElement: number, characterID: number) {
      const totalMultipliedPower = this.charactersPowerData[characterID]?.pvePower[enemyElement];
      const playerMin = totalMultipliedPower * 0.9;
      const playerMax = totalMultipliedPower * 1.1;
      const playerRange = playerMax - playerMin;
      const enemyMin = enemyPower * 0.9;
      const enemyMax = enemyPower * 1.1;
      const enemyRange = enemyMax - enemyMin;
      let rollingTotal = 0;
      // shortcut: if it is impossible for one side to win, just say so
      if (playerMin > enemyMax) return i18n.t('combat.winChances.veryLikely');
      if (playerMax < enemyMin) i18n.t('combat.winChances.unlikely');

      // case 1: player power is higher than enemy power
      if (playerMin >= enemyMin) {
        // case 1: enemy roll is lower than player's minimum
        rollingTotal = (playerMin - enemyMin) / enemyRange;
        // case 2: 1 is not true, and player roll is higher than enemy maximum
        rollingTotal += (1 - rollingTotal) * ((playerMax - enemyMax) / playerRange);
        // case 3: 1 and 2 are not true, both values are in the overlap range. Since values are basically continuous, we assume 50%
        rollingTotal += (1 - rollingTotal) * 0.5;
      } // otherwise, enemy power is higher
      else {
        // case 1: player rolls below enemy minimum
        rollingTotal = (enemyMin - playerMin) / playerRange;
        // case 2: enemy rolls above player maximum
        rollingTotal += (1 - rollingTotal) * ((enemyMax - playerMax) / enemyRange);
        // case 3: 1 and 2 are not true, both values are in the overlap range
        rollingTotal += (1 - rollingTotal) * 0.5;
        //since this is chance the enemy wins, we negate it
        rollingTotal = 1 - rollingTotal;
      }
      if (rollingTotal <= 0.3) return i18n.t('combat.winChances.unlikely');
      if (rollingTotal <= 0.5) return i18n.t('combat.winChances.possible');
      if (rollingTotal <= 0.7) return i18n.t('combat.winChances.likely');
      return i18n.t('combat.winChances.veryLikely');
    },
    getPotentialXp(targetToFight: ITarget, characterID: number) {
      const totalPower = this.charactersPowerData[characterID]?.pvePower[4]; // using base power
      //Formula taken from getXpGainForFight funtion of cryptoblades.sol
      return Math.floor((targetToFight.power / totalPower) * this.fightXpGain) * this.fightMultiplier;
    },
    async getCharactersWeapon() {
      for (let i = 0; i < this.ownCharacters.length; i++) {
        const character = this.ownCharacters[i];
        const weapon = await this.fetchCharacterWeapon(character.id);
        Vue.set(this.charactersWeapon, character.id, weapon);
      }
    },
    showLastMinuteErrorModal() {
      if(this.timeMinutes === 59 && this.timeSeconds >= 30) {
        (this.$refs['last-minute-error-modal'] as any).show();
      }
    },
    getCharactersPowerData() {
      for (let i = 0; i < this.ownCharacters.length; i++) {
        const character = this.ownCharacters[i];
        const powerData = this.getPowerData(+character.id);
        Vue.set(this.charactersPowerData, character.id, powerData);
      }
    }
  },
  created() {
    this.intervalSecondsFn = setInterval(() => {
      this.timeSeconds = new Date().getSeconds();
      this.showLastMinuteErrorModal();
    }, 5000);
    this.intervalMinutesFn = setInterval(() => {
      this.timeMinutes = new Date().getMinutes();
      this.showLastMinuteErrorModal();
    }, 20000);
    this.checkStorage();
  },
  async mounted() {
    this.fightXpGain = await this.getFightXpGain();
    await this.getCharactersWeapon();
    this.getCharactersPowerData();
  },
  beforeDestroy() {
    if(this.intervalSecondsFn)
      clearTimeout(this.intervalSecondsFn);
    if(this.intervalMinutesFn)
      clearTimeout(this.intervalMinutesFn);
  }
});
