











import Vue from 'vue';
import {PropType} from 'vue/types/options';
import { QuestItemType, Rarity } from '@/enums/Quest';
import NftList, {NftIdType} from '@/components/smart/NftList.vue';
import QuestComponentIcon from '@/components/smart/QuestComponentIcon.vue';
import {questItemTypeSupportsStars, questItemTypeSupportsTimesValue} from '../../utils/common';

export default Vue.extend({
  components: {NftList, QuestComponentIcon},

  props: {
    type: {
      type: Number as PropType<QuestItemType>,
    },
    rarity: {
      type: Number as PropType<Rarity>,
    },
    amount: {
      type: Number as PropType<number>,
    },
    rewards: {
      type: Array as PropType<NftIdType[]>,
    },
    reputationAmount: {
      type: Number as PropType<number>,
      default: 0,
    },
    externalAddress: {
      type: String as PropType<string>,
    },
  },

  data() {
    return {
      questItemTypeSupportsTimesValue,
      questItemTypeSupportsStars,
      QuestItemType,
      Rarity,
    };
  },

});
