




























































































import Vue from 'vue';
import TeamAdventure from '../components/smart/CombatTabs/TeamAdventure.vue';
import Adventure from '../components/smart/CombatTabs/Adventure.vue';
import i18n from '@/i18n';
import Hint from './../components/Hint.vue';
import { Accessors } from 'vue/types/options';
import { mapGetters } from 'vuex';
import { ICharacter } from '@/interfaces';

export enum COMBAT_TAB {
  Adventure=0,
  TeamAdventure=1
}

type numberOrNull = number | null;
type stringOrNull = string | null;

const CHANGE_RPC_ERROR_CODE = -32005;

interface ICombatData {
  fightMultiplier: number;
  staminaPerFight: number;
  isToggled: boolean;
  error: stringOrNull;
  errorCode: numberOrNull;
  currentCombatTab: COMBAT_TAB
}

interface StoreMappedGetters {
  ownCharacters: Array<ICharacter>
}

export default Vue.extend({
  data() {
    return {
      fightMultiplier: Number(localStorage.getItem('fightMultiplier')),
      staminaPerFight: 40,
      isToggled: false,
      error: null,
      errorCode: -1,
      CHANGE_RPC_ERROR_CODE,
      COMBAT_TAB,
      currentCombatTab: COMBAT_TAB.Adventure
    } as ICombatData;
  },
  methods: {
    setStaminaSelectorValues() {
      const choices: {value: numberOrNull, text: string, disabled?: boolean }[] = [
        {value: null, text: i18n.t('combat.pleaseSelect').toString(), disabled: true},
      ];

      const addChoices = [];
      addChoices.push({ value: 5, text: '200' });
      addChoices.push({ value: 4, text: '160' });
      addChoices.push({ value: 3, text: '120' });
      addChoices.push({ value: 2, text: '80' });
      addChoices.push({ value: 1, text: '40' });
      choices.push(...addChoices.reverse());

      return choices;
    },
    setFightMultiplier() {
      localStorage.setItem('fightMultiplier', this.fightMultiplier.toString());
      this.updateStaminaPerFight();
    },
    updateStaminaPerFight() {
      this.staminaPerFight = 40 * Number(localStorage.getItem('fightMultiplier'));
    },
    hideBottomMenu(bol: boolean){
      this.isToggled = bol;
    },
    onErrorChanged(error: stringOrNull) {
      this.error = error;
    },
    onErrorCodeChanged(errorCode: numberOrNull) {
      this.errorCode = errorCode;
    },
    onChangeCombatTab(tab: COMBAT_TAB) {
      if(this.currentCombatTab === tab) return;

      this.currentCombatTab = tab;
      (this as any).$router.push({ path: '/combat', query: { tab: COMBAT_TAB[this.currentCombatTab] } });
    }
  },
  computed: {
    ...(mapGetters([
      'ownCharacters',
    ]) as Accessors<StoreMappedGetters>),
  },
  components: {
    TeamAdventure,
    Adventure,
    Hint
  },
  mounted() {
    const queryTab = (this as any).$route.query.tab;
    if(queryTab) {
      if(queryTab === COMBAT_TAB.Adventure || queryTab === COMBAT_TAB.TeamAdventure)
        this.currentCombatTab = (this as any).$route.query.tab;
    }
  }
});
