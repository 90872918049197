


























































































import Vue from 'vue';
import {mapActions, mapGetters, mapState} from 'vuex';
import Events from '@/events';
import {shieldFromContract as formatShield} from '@/contract-models';
import PvPShield from '@/components/smart/PvPShield.vue';
import WeaponGrid from '@/components/smart/WeaponGrid.vue';
import NftList from '@/components/smart/NftList.vue';
import {ICharacter} from '@/interfaces';


interface Data {
  equippedWeapon: any;
  equippedWeaponId: number | string;
  equippedShield: any;
  equippedShieldId: number | string;
  ownedShieldsWithInformation: number[];
}

interface StoreMappedActions {
  fetchWeapon(payload: { weaponId: string | number }): Promise<void>;

  fetchShield(payload: { shieldId: string | number }): Promise<void>;

  equipWeapon(payload: { equipperId: string, itemId: number | string }): Promise<void>;

  equipShield(payload: { equipperId: string, itemId: number | string }): Promise<void>;

  unequipWeapon(payload: { equipperId: string }): Promise<void>;

  unequipShield(payload: { equipperId: string }): Promise<void>;

  fetchCharacterWeapon(characterId: string | number): Promise<number>;

  fetchCharacterShield(characterId: string | number): Promise<number>;

  getShield(shieldId: string | number): Promise<string[]>;
}

interface StoredMappedGetters {
  getEquippedWeapon(payload: { characterId: string | number }): number;

  getEquippedShield(payload: { characterId: string | number }): number;
}


export default Vue.extend({
  components: {'pvp-shield': PvPShield, WeaponGrid, NftList},
  props: {
    soulBalance: {
      type: Number,
      default: 0
    }
  },
  data(): Data {
    return {
      equippedWeapon: null,
      equippedWeaponId: '',
      equippedShield: null,
      equippedShieldId: '',
      ownedShieldsWithInformation: [],
    };
  },
  computed: {
    ...mapState([
      'ownedShieldIds',
      'currentCharacterId',
      'characters',
    ]),
    ...mapGetters([
      'getCharacterPower'
    ]),
    selectedCharacter(): ICharacter {
      return this.characters[this.currentCharacterId];
    },
  },
  methods: {
    ...mapActions([
      'fetchWeapon',
      'fetchShield',
      'equipWeapon',
      'equipShield',
      'unequipWeapon',
      'unequipShield',
      'fetchCharacterWeapon',
      'fetchCharacterShield',
      'getShield',
    ]) as StoreMappedActions,
    ...(mapGetters([
      'getEquippedWeapon',
      'getEquippedShield',
    ]) as StoredMappedGetters),

    async getShieldInformation(shieldId: number | string) {
      return formatShield(`${shieldId}`, await this.getShield(shieldId));
    },
    selectWeapon() {
      Events.$emit('weapon-inventory', true);
    },
    async removeWeapon() {
      await this.unequipWeapon({equipperId: this.currentCharacterId});
      this.equippedWeaponId = await this.fetchCharacterWeapon(this.currentCharacterId);
      Events.$emit('weaponChanged', true);
      //re-fetch weapon inventory
    },
    async selectShield(shieldId: number | string) {
      // todo
      await this.equipShield({equipperId: this.currentCharacterId as string, itemId: shieldId});
      Events.$emit('weaponChanged', true);
      //re-fetch shield inventory
    },
    async removeShield() {
      await this.unequipShield({equipperId: this.currentCharacterId});
      this.equippedShieldId = await this.fetchCharacterShield(this.currentCharacterId);
      Events.$emit('weaponChanged', true);
      //re-fetch shield inventory
    },
    async refreshData() {
      this.ownedShieldsWithInformation = await Promise.all(this.ownedShieldIds.map(async (shieldId: number | string) => {
        return {
          shieldId,
          information: await this.getShieldInformation(shieldId)
        };
      }));
      this.equippedWeaponId = await this.fetchCharacterWeapon(this.currentCharacterId);
      this.equippedShieldId = await this.fetchCharacterShield(this.currentCharacterId);
    }
  },
  watch: {
    async selectedCharacter(newValue) {
      if (newValue) {
        await this.refreshData();
      }
    },
  },
  async mounted() {
    console.log('mounted');
    await this.refreshData();
    Events.$on('chooseweapon', async (id: number) => {
      await this.equipWeapon({equipperId: this.currentCharacterId, itemId: id});
      await this.refreshData();
      Events.$emit('weaponChanged', true);
      //re-fetch weapon inventory
    });
  },

  beforeDestroy() {
    Events.$off('chooseweapon');
  }
});
