




















import Vue from 'vue';
import {mapGetters} from 'vuex';
import { DustRarity } from '@/enums/Quest';
import lesserDust from '@/assets/dusts/lesserDust.png';
import greaterDust from '@/assets/dusts/greaterDust.png';
import powerfulDust from '@/assets/dusts/powerfulDust.png';


export default Vue.extend({
  props: {
    rarities: {
      type: Array,
      default() {
        return [DustRarity.LESSER, DustRarity.GREATER, DustRarity.POWERFUL];
      },
    },
  },

  data() {
    return {
      DustRarity
    };
  },

  computed: {
    ...mapGetters(['getPowerfulDust', 'getGreaterDust', 'getLesserDust']),

    singleDust() {
      return this.rarities.length === 1;
    },
  },

  methods: {
    getDustAmount(rarity: DustRarity) {
      if (rarity === DustRarity.LESSER) {
        return this.getLesserDust();
      } else if (rarity === DustRarity.GREATER) {
        return this.getGreaterDust();
      } else if (rarity === DustRarity.POWERFUL) {
        return this.getPowerfulDust();
      }
    },

    getDustIcon(rarity: DustRarity) {
      if (rarity === DustRarity.LESSER) {
        return lesserDust;
      } else if (rarity === DustRarity.GREATER) {
        return greaterDust;
      } else if (rarity === DustRarity.POWERFUL) {
        return powerfulDust;
      }
    },
  }
})
;
