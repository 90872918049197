




















































































import BN from 'bignumber.js';

import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
import _ from 'lodash';
import Vue from 'vue';
import Events from './events';
import MetaMaskOnboarding from '@metamask/onboarding';
import BigButton from './components/BigButton.vue';
import SmallButton from './components/SmallButton.vue';
import NavBar from './components/NavBar.vue';
import CharacterBar from './components/CharacterBar.vue';
import WeaponRowGrid from './components/smart/WeaponRowGrid.vue';
import i18n from './i18n';
import { getConfigValue } from './contracts';
import '@/mixins/general';
import config from '../app-config.json';
import { addChainToRouter } from '@/utils/common';
import Web3 from 'web3';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { Contracts, ICharacter } from '@/interfaces';
import { Accessors } from 'vue/types/options';

Vue.directive('visible', (el, bind) => {
  el.style.visibility = bind.value ? 'visible' : 'hidden';
});

interface RPCS {
  [key: number]: string;
}
interface Data {
  errorMessage: string,
  hideWalletWarning: boolean,
  showAds: boolean,
  isConnecting: boolean,
  isConnected: boolean,
  recruitCost: string,
  showWeapon: boolean,
  currentWeaponId: null | number,
  weaponId: null | number,
  toggleSideBar: boolean,
  currentPath: string,
  showMetamaskWarning: boolean,
  pollCharacterStaminaIntervalId: ReturnType<typeof setInterval> | null,
  slowPollIntervalId: ReturnType<typeof setInterval> | null,
  doPollAccounts: boolean
}

interface StoreMappedState {
  skillBalance: string,
  defaultAccount: string | null,
  currentNetworkId: number,
  currentCharacterId: number,
  web3: Web3,
  staking: string,
}

interface StoreMappedGetters {
  contracts: Contracts,
  ownCharacters: ICharacter[],
  ownGarrisonCharacters: ICharacter[],
  getExchangeUrl: string,
  getExchangeTransakUrl: string,
}

interface StoreMappedActions {
  initializeStore: () => void,
  pollAccountsAndNetwork: () => void,
  configureMetaMask: () => Promise<void>,
  fetchMintCharacterFee: () => Promise<string>,
  fetchUsdSkillValue: (payload: string | number) => Promise<string | number>,
}

interface StoreMappedCombatActions {
  fetchCharacterStamina: (characterId: number) => Promise<void>,
}

interface StoreMappedMutations {
  setWeb3: (web3: Web3) => void,
  updateCurrentChainSupportsPvP: () => void,
  updateCurrentChainSupportsQuests: () => void,
  updateCurrentChainSupportsDrawbridge: () => void,
}

export default Vue.extend({
  components: {
    NavBar,
    CharacterBar,
    BigButton,
    SmallButton,
    WeaponRowGrid,
  },

  data() {
    return {
      errorMessage: '',
      hideWalletWarning: false,
      showAds: false,
      isConnecting: false,
      isConnected: false,
      recruitCost: '',
      showWeapon: false,
      currentWeaponId: null,
      weaponId: null,
      toggleSideBar: false,
      currentPath: '',
      showMetamaskWarning: false,
      pollCharacterStaminaIntervalId: null,
      slowPollIntervalId: null,
      doPollAccounts: false
    } as Data;
  },

  computed: {
    ...(mapState(['skillBalance', 'defaultAccount', 'currentNetworkId', 'currentCharacterId', 'staking', 'web3']) as Accessors<StoreMappedState>),
    ...(mapGetters(['contracts', 'ownCharacters', 'ownGarrisonCharacters', 'getExchangeUrl', 'getExchangeTransakUrl']) as Accessors<StoreMappedGetters>),

    canShowApp(): boolean {
      return (this.contracts !== null && !_.isEmpty(this.contracts)) || this.isOptions;
    },

    currentChain(): string {
      return localStorage.getItem('currentChain') || '';
    },

    isWalletConnect(): boolean {
      return localStorage.getItem('walletconnect') !== null;
    },

    isOptions(): boolean {
      return (this as any).$route.path === '/options';
    }
  },

  watch: {
    defaultAccount(account) {
      this.web3.eth.defaultAccount = account;
    },

    async currentCharacterId() {
      await this.updateCharacterStamina(this.currentCharacterId);
    },
  },

  methods: {
    ...mapActions([
      'initializeStore',
      'pollAccountsAndNetwork',
      'configureMetaMask',
      'fetchMintCharacterFee',
      'fetchUsdSkillValue',
    ]) as StoreMappedActions,
    ...mapActions('combat',
      [
        'fetchCharacterStamina'
      ]) as StoreMappedCombatActions,
    ...mapMutations([
      'setWeb3',
      'updateCurrentChainSupportsPvP',
      'updateCurrentChainSupportsQuests',
      'updateCurrentChainSupportsDrawbridge',
    ])as StoreMappedMutations,
    async checkChainAndParams(){
      const currentChain = localStorage.getItem('currentChain') || 'BNB';

      const paramChain = (this as any).$router.currentRoute.query.chain;
      const supportedChains = window.location.href.startsWith('https://test') ? config.testSupportedChains : config.supportedChains;

      if(!paramChain){
        localStorage.setItem('currentChain', currentChain);
        addChainToRouter(currentChain);
      }

      //add chain as query param if chain unchanged
      if(currentChain === paramChain || !paramChain){
        localStorage.setItem('currentChain', currentChain);
        addChainToRouter(currentChain);
      }

      //if user has an unsupported chain set (e.g. BSC instead of BNB) in storage
      if(!supportedChains.includes(currentChain)){
        localStorage.setItem('currentChain', 'BNB');
        addChainToRouter('BNB');
      }

      //set chain in localStorage & MM from query param; check if supported
      else if (currentChain !== paramChain && supportedChains.includes(paramChain)){
        localStorage.setItem('currentChain', paramChain);
        if(!this.isWalletConnect) await this.configureMetaMask();
      }
      this.updateCurrentChainSupportsPvP();
      this.updateCurrentChainSupportsQuests();
      this.updateCurrentChainSupportsDrawbridge();
    },
    async updateCharacterStamina(id: number) {
      if (id !== null) {
        await this.fetchCharacterStamina(id);
      }
    },

    checkStorage() {
      this.hideWalletWarning = localStorage.getItem('hideWalletWarning') === 'true';
      if (process.env.NODE_ENV === 'development') this.showAds = false;
      else this.showAds = localStorage.getItem('show-ads') === 'true';
    },
    async initializeRecruitCost() {
      if(!this.contracts.CryptoBlades) return;
      const recruitCost = await this.fetchMintCharacterFee();
      const skillRecruitCost = await this.fetchUsdSkillValue(recruitCost);
      this.recruitCost = new BN(skillRecruitCost)
        .div(new BN(10).pow(18))
        .toFixed(4);
    },

    async startOnboarding() {
      const onboarding = new MetaMaskOnboarding();
      onboarding.startOnboarding();
    },
    async configureMetamask() {
      await this.configureMetaMask();
    },

    async connectMetamask() {
      const web3 = new Web3(Web3.givenProvider);
      this.setWeb3(web3);
      this.isConnecting = true;
      this.errorMessage = i18n.t('app.warning.errorMessage.connecting').toString();
      //test connection
      web3.eth
        .requestAccounts()
        .then(() => {
          this.errorMessage = i18n.t('app.warning.errorMessage.success').toString();
          this.isConnecting = false;
          this.isConnected = true;

          this.initializeStore();
        })
        .catch(() => {
          this.errorMessage = i18n.t('app.warning.errorMessage.error').toString();
          this.isConnecting = false;
        });
    },

    toggleHideWalletWarning() {
      this.hideWalletWarning = !this.hideWalletWarning;
      if (this.hideWalletWarning) localStorage.setItem('hideWalletWarning', 'true');
      else localStorage.setItem('hideWalletWarning', 'false');

      Events.$emit('setting:hideWalletWarning', { value: this.hideWalletWarning });
    },

    async showWarningDialog() {
      await new Promise((resolve) => setTimeout(resolve, 7500));

      if (
        this.hideWalletWarning &&
        !this.showMetamaskWarning &&
        (this.errorMessage.includes(i18n.t('app.warning.errorMessage.error').toString()) ||
        (this.ownCharacters.length === 0 && this.skillBalance === '0'))
      ) {
        (this as any).$dialog.notify.warning(i18n.t('app.warning.message.hideWalletWarning'),
          {
            timeout: 0,
          },
        );
      }
    },

    renderPageDisplay(): string{
      let toDisplay;

      if(this.currentCharacterId !== null){
        if(this.toggleSideBar){
          toDisplay = 'can-show-app';
        }else{
          toDisplay = 'col-xl-10 col-lg-9 col-md-9 col-sm-10 cols-11 set-normal';
        }
      }else{
        toDisplay = 'col-xl-12 col-lg-12 col-md-12 col-sm-12 cols-12 set-normal';
      }

      return toDisplay;
    },

    initializeSettings(){
      if (!localStorage.getItem('useGraphics')) localStorage.setItem('useGraphics', 'false');
      if (!localStorage.getItem('hideRewards')) localStorage.setItem('hideRewards', 'false');
      if (!localStorage.getItem('hideWalletWarning')) localStorage.setItem('hideWalletWarning', 'false');
      if (!localStorage.getItem('fightMultiplier')) localStorage.setItem('fightMultiplier', '1');
    },
    async connectWalletConnect(){
      const rpcs = {} as RPCS;
      config.supportedChains.forEach((chain) => {
        const chainId = getConfigValue('VUE_APP_NETWORK_ID', chain);
        rpcs[chainId] = getConfigValue('rpcUrls',chain)[0];
      });
      const provider = new WalletConnectProvider({
        rpc: rpcs,
        chainId: JSON.parse(localStorage.getItem('walletconnect') || '').chainId,
      });

      //  Enable session (triggers QR Code modal)
      await provider.enable();
      this.setWeb3(new Web3(provider as any));
    },
    walletConnectOnboarding(){
      (this as any).$router.push({ name: 'options' });
      this.showMetamaskWarning = false;
      this.hideWalletWarning = true;
    }
  },

  async mounted() {
    Events.$on('setting:hideRewards', () => this.checkStorage());
    Events.$on('setting:hideWalletWarning', () => this.checkStorage());
    // Events.$on('garrison:characterReceived', (e) => {
    //   this.$dialog.notify.warning(`${i18n.t('app.warning.message.newCharacter')} ID: ${e.id} ${i18n.t('app.warning.message.inGarrison')}!`,
    //     {
    //       timeout: 5000,
    //     },
    //   );
    // });
    Events.$on('weapon-inventory', (bol: boolean) =>{
      this.showWeapon = bol;
    });

    Events.$on('chooseweapon', (id: number) =>{
      this.weaponId = id;
    });

    Events.$on('toggle-sideBar', (bol: boolean) =>{
      this.toggleSideBar = bol;
    });
    this.showWarningDialog();
    if(this.hideWalletWarning) {
      this.configureMetamask();
    }
  },
  async created() {
    this.initializeSettings();
    this.checkChainAndParams();
    this.checkStorage();

    if(!this.isWalletConnect){
      await this.connectMetamask();
    }
    else{
      this.showMetamaskWarning = false;
      this.hideWalletWarning = true;
      await this.connectWalletConnect();
    }
    if(!this.web3.currentProvider){
      this.showMetamaskWarning = true;
    }
    try {
      await this.initializeStore();
    } catch (error: any) {
      this.errorMessage = i18n.t('app.warning.errorMessage.welcome').toString();
      if (error.code === 4001) {
        this.errorMessage = i18n.t('app.warning.errorMessage.error').toString();
      }

      console.error(error);
      throw error;
    }

    this.pollCharacterStaminaIntervalId = setInterval(async () => {
      this.ownCharacters.forEach(async (c) => {
        await this.updateCharacterStamina(c.id);
      });
      this.ownGarrisonCharacters.forEach(async (c) => {
        await this.updateCharacterStamina(c.id);
      });
    }, 250000);

    this.doPollAccounts = true;
    const pollAccounts = async () => {
      if (!this.doPollAccounts) return;

      try {
        await this.pollAccountsAndNetwork();
      } catch (error) {
        console.error(error);
      }
    };

    pollAccounts();

    this.initializeRecruitCost();
  },

  beforeDestroy() {
    this.doPollAccounts = false;
    if(this.pollCharacterStaminaIntervalId) clearInterval(this.pollCharacterStaminaIntervalId);
    if(this.slowPollIntervalId) clearInterval(this.slowPollIntervalId);
  },

});
