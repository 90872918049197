





























































































































































import Vue from 'vue';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { BModal } from 'bootstrap-vue';
import BN from 'bignumber.js';
import i18n from '@/i18n';
import { Nft } from '@/interfaces/Nft';
import { CharacterPower } from '@/interfaces';
import BigButton from '@/components/BigButton.vue';
import CharacterList from '@/components/smart/CharacterList.vue';
import CharacterNav from '@/components/CharacterNav.vue';
import Character from '@/components/smart/Character.vue';
import Events from '@/events';
import { getConfigValue } from '@/contracts';


import { fromWeiEther, toBN } from '../utils/common';
import {
  burningManager as featureFlagBurningManager
} from '../feature-flags';


interface StoreMappedActions {
  fetchMintCharacterFee(): Promise<string>;
  mintCharacter(value: boolean): Promise<void>;
  fetchMintCharacterPriceDecreasePerSecond(): Promise<number>;
  fetchCharacterMintIncreasePrice(): Promise<number>;
  fetchMintCharacterMinPrice(): Promise<number>;
  fetchGenesisSoulBalance(): Promise<string>;
  fetchNonGenesisSoulBalance(): Promise<string>;
  fetchBurnPowerMultiplier(): Promise<string>;
  fetchCharactersBurnCost(payload: string[]): Promise<string>;
  burnCharactersIntoSoul(payload: string[]): Promise<void>;
  burnCharactersIntoCharacter(payload: {burnIds: string[], targetId: string}): Promise<void>;
  claimGarrisonXp(payload: string[]): Promise<void>;
  fetchUsdSkillValue(payload: string | number): Promise<string | number>;
}

interface StoreMappedGetters {
  getExchangeTransakUrl(): string;
}

interface Data {
  recruitCost: string;
  showAds: boolean;
  updateInterval: ReturnType<typeof setInterval> | null;
  mintSlippageApproved: boolean;
  mintPriceDecreasePerHour: string;
  mintCharacterPriceIncrease: string;
  mintCharacterMinPrice: string;
  activeTab: string;
  genesisSoulBalance: number;
  nonGenesisSoulBalance: number;
  disableCharacterBurn: boolean;
  burnCost: number;
  burnPowerMultiplier: number;
  burnCharacterIds: string[];
  remainingCharactersIds: string[];
  isUpgrading: boolean;
  isBurning: boolean;
  isTransferring: boolean;
  soulAmount: number;
  targetCharacterId: string;
  remainingPowerLimit: number;
  burnOption: number;
  isBurnInProgress: boolean;
  isClaimingXp: boolean;
  totalSoul: number;
  totalSoulNonGenesis: number;
  glowImage: boolean;
  glowImageNonGenesis: boolean;
}

export default Vue.extend({
  data(): Data{
    return {
      activeTab: 'info',
      updateInterval: null as ReturnType<typeof setInterval> | null,
      recruitCost: '0',
      mintSlippageApproved: true,
      mintPriceDecreasePerHour: '0',
      mintCharacterPriceIncrease: '0',
      mintCharacterMinPrice: '0',
      showAds: false,
      genesisSoulBalance: 0,
      nonGenesisSoulBalance: 0,
      disableCharacterBurn: (getConfigValue('featureSupport').disableDynamicMinting),
      burnCost: 0,
      burnPowerMultiplier: 1,
      burnCharacterIds: [],
      remainingCharactersIds: [],
      isUpgrading: false,
      isBurning: false,
      isTransferring: false,
      soulAmount: 0,
      targetCharacterId: '',
      remainingPowerLimit: 0,
      burnOption: 0,
      isBurnInProgress: false,
      isClaimingXp: false,
      totalSoul: 0,
      totalSoulNonGenesis: 0,
      glowImage: false,
      glowImageNonGenesis: false
    };
  },
  computed: {
    ...mapState([
      'characters',
      'currentCharacterId',
      'maxStamina',
      'ownedGarrisonCharacterIds',
      'characterStaminas',
      'skillBalance',
      'skillRewards',
      'valorRewards',
      'ownedCharacterIds',
      'xpRewards',
      'characterCosmetics',
    ]),
    ...mapGetters([
      'getCharacterName',
      'ownGarrisonCharacters',
      'contracts',
      'ownCharacters',
      'getExchangeUrl',
      'getCharacterIsInArena',
      'getCharacterPower',
    ]),
    selectedCharacter(): Nft{
      return this.characters[this.currentCharacterId];
    },
    burnPower(): number {
      let power = 0;
      if(!this.isUpgrading) {
        this.ownCharacters.map((x: { id: number; }) => {
          if(this.burnCharacterIds.includes(x.id.toString())) {
            power += this.getCharacterPower(x.id);
          }
        });
        this.ownGarrisonCharacters.map((x: { id: number; }) => {
          if(this.burnCharacterIds.includes(x.id.toString())) {
            power += this.getCharacterPower(x.id);
          }
        });
        power = Math.floor(power * this.burnPowerMultiplier);
      }
      else {
        power = this.soulAmount * 10;
      }

      return power;
    },
    powerLimitExceeded(): boolean {
      return (this.isUpgrading || this.burnOption === 1) && this.burnPower > this.remainingPowerLimit;
    },
    haveCharacters(): boolean {
      return this.ownedGarrisonCharacterIds.length > 0 || this.ownCharacters?.length > 0;
    },
    havePlazaCharacters(): boolean {
      return this.ownCharacters?.length > 0;
    },
    canClaimGarrisonXp(): boolean {
      return this.ownedGarrisonCharacterIds.filter((id: string|number) => +this.xpRewards[id] > 0).length > 0;
    },
    burningEnabled(): boolean {
      return featureFlagBurningManager;
    },
  },
  methods: {
    ...mapMutations(['setCurrentCharacter']),
    ...mapActions([
      'mintCharacter',
      'fetchMintCharacterPriceDecreasePerSecond',
      'fetchCharacterMintIncreasePrice',
      'fetchMintCharacterMinPrice',
      'fetchMintCharacterFee',
      'fetchGenesisSoulBalance',
      'fetchNonGenesisSoulBalance',
      'fetchBurnPowerMultiplier',
      'fetchCharactersBurnCost',
      'burnCharactersIntoSoul',
      'burnCharactersIntoCharacter',
      'claimGarrisonXp',
      'fetchUsdSkillValue',
    ]) as StoreMappedActions,
    ...mapGetters(['getExchangeTransakUrl']) as StoreMappedGetters,

    onChangeTab(tab: string) {
      this.activeTab = tab;
    },
    toggleGarrison(tab: string) {
      if (this.activeTab === 'info' && this.ownedGarrisonCharacterIds.includes(this.currentCharacterId)) {
        this.setCurrentCharacter(this.ownedCharacterIds[0]);
      }

      this.activeTab = tab;
    },
    async onClaimGarrisonXp() {
      this.isClaimingXp = true;
      try {
        await this.claimGarrisonXp(this.ownedGarrisonCharacterIds.filter((id: string|number) => +this.xpRewards[id] > 0));
      }
      finally {
        this.isClaimingXp = true;
      }
    },
    async onMintCharacter() {
      try {
        await this.mintCharacter(this.mintSlippageApproved);
        await this.updateMintCharacterFee();
      } catch (e) {
        (this as any).$dialog.notify.error(i18n.t('plaza.couldNotMint'));
      }
    },
    async addBurnCharacter(id: number) {
      if(this.getCharacterIsInArena(id)) {
        (this as any).$dialog.notify.error(i18n.t('plaza.busyInArena'));
        return;
      }

      if(!this.burnCharacterIds.includes(id.toString())){
        this.burnCharacterIds.push(id.toString());
        if(this.characters[id].version === 0) {
          this.totalSoul += this.getCharacterPower(id)/10;
        }
        else {
          this.totalSoulNonGenesis += this.getCharacterPower(id)/10;
        }
      }else{
        this.burnCharacterIds = this.burnCharacterIds.filter(val => val !== id.toString());
        if(this.characters[id].version === 0) {
          this.totalSoul -= this.getCharacterPower(id)/10;
        }
        else {
          this.totalSoulNonGenesis -= this.getCharacterPower(id)/10;
        }
      }
      await this.updateBurnCost();
    },
    async removeBurnCharacter(id: number) {
      this.burnCharacterIds = this.burnCharacterIds.filter(val => !val.includes(id.toString()));
      if(this.characters[id].version === 0) {
        this.totalSoul -= this.getCharacterPower(id)/10;
      }
      else {
        this.totalSoulNonGenesis -= this.getCharacterPower(id)/10;
      }
    },
    canBurn() {
      const cost = toBN(this.burnCost);
      const balance = toBN(+fromWeiEther(this.skillBalance) + +fromWeiEther(this.skillRewards) + +fromWeiEther(this.valorRewards));
      return balance.isGreaterThanOrEqualTo(cost);
    },
    showBurnConfirmation() {
      if(!(this.burnCharacterIds.length === 0 ||  this.powerLimitExceeded || (this.burnOption === 1 && !this.targetCharacterId)
      || !this.canBurn() || this.isBurnInProgress || (this.burnCharacterIds.length > 1 && this.disableCharacterBurn))){
        (this.$refs['burn-confirmation-modal'] as BModal).show();
      }
    },
    async toggleSoulCreation() {
      this.genesisSoulBalance = +(await this.fetchGenesisSoulBalance());
      this.nonGenesisSoulBalance = +(await this.fetchNonGenesisSoulBalance());
      await this.updateBurnCost();
      this.burnPowerMultiplier = +fromWeiEther(await this.fetchBurnPowerMultiplier());
      if(this.activeTab === 'burn') {
        this.remainingCharactersIds = this.ownCharacters.map((x: { id: string; }) => x.id.toString()).concat(this.ownedGarrisonCharacterIds as string[]);
      }
      this.isUpgrading = false;
    },
    async selectAll(filterCharacter: any){
      await this.computeSoul(filterCharacter);
      await this.updateBurnCost();
    },
    computeSoul(filterCharacters: any){
      filterCharacters.forEach((filterCharacter: { id: { toString: () => string; }; })=> {
        if(!this.burnCharacterIds.includes(filterCharacter.id.toString())){
          this.burnCharacterIds.push(filterCharacter.id.toString());
          this.totalSoul += this.getCharacterPower(filterCharacter.id)/10;
        }
      });
    },
    clearAllBurn(){
      this.burnCharacterIds = [];
      this.remainingCharactersIds = (this.ownCharacters.map((x: { id: string; }) => x.id.toString())
        .concat(this.ownedGarrisonCharacterIds) as string[])
        .filter(x => x.toString() !== this.targetCharacterId);
      this.burnCost = 0;
      this.totalSoul = 0;
    },
    async updateBurnCost() {
      this.burnCost = this.burnCharacterIds.length > 0 ? +fromWeiEther(await this.fetchCharactersBurnCost(this.burnCharacterIds)) : 0;
    },
    checkStorage() {
      if (process.env.NODE_ENV === 'development') this.showAds = false;
      else this.showAds = localStorage.getItem('show-ads') === 'true';
    },
    canRecruit() {
      const cost = toBN(this.recruitCost);
      const balance = toBN(+fromWeiEther(this.skillBalance) + +fromWeiEther(this.skillRewards) + +fromWeiEther(this.valorRewards));
      return balance.isGreaterThanOrEqualTo(cost);
    },
    formatSkill() {
      return fromWeiEther(this.skillBalance);
    },
    async updateMintCharacterFee() {
      const recruitCost = await this.fetchMintCharacterFee();
      const skillRecruitCost = await this.fetchUsdSkillValue(recruitCost);
      this.recruitCost = new BN(skillRecruitCost).div(new BN(10).pow(18)).toFixed(4);
    },
    updatedRemainingPowerLimit() {
      const targetCharacter = this.ownCharacters.concat(this.ownGarrisonCharacters)
        .find((x: { id: any; }) => x.id.toString() === this.targetCharacterId.toString());
      this.remainingPowerLimit = 4 * CharacterPower(targetCharacter.level) - this.getCharacterPower(this.targetCharacterId.toString());
    },
    async onBurnConfirm() {
      if(this.burnCharacterIds.length === 0) return;
      this.isBurnInProgress = true;
      try {
        if(this.burnOption === 0) {
          // burning into soul
          await this.burnCharactersIntoSoul(this.burnCharacterIds);
        }
        else {
          // burning into character
          await this.burnCharactersIntoCharacter({ burnIds: this.burnCharacterIds, targetId: this.targetCharacterId });
          this.updatedRemainingPowerLimit();
        }
      }
      finally {
        (this.$refs['burn-confirmation-modal'] as BModal).hide();
        this.isBurnInProgress = false;
      }

      this.genesisSoulBalance = +(await this.fetchGenesisSoulBalance());
      this.nonGenesisSoulBalance = +(await this.fetchNonGenesisSoulBalance());
      this.burnCharacterIds = [];
      this.burnCost = 0;
      this.toggleSoulCreation();
    },
  },
  async mounted(){
    this.checkStorage();
    await this.updateMintCharacterFee();
    Events.$on('select-all', (filteredCharacters: any[])=>{
      this.selectAll(filteredCharacters);
    });
    Events.$on('deselect-all', async (deselectedIds: any[])=>{
      deselectedIds.forEach(deselectedId => {
        this.removeBurnCharacter(deselectedId.id);
      });

      await this.updateBurnCost();
    });
  },
  watch: {
    activeTab(){
      this.toggleSoulCreation();
    },
    async ownedCharacterIds(){
      await this.updateMintCharacterFee();
      this.toggleSoulCreation();
    },
    totalSoul(){
      this.glowImage = true;
      setTimeout(() => {
        this.glowImage = false;
      }, 500);
    },
    totalSoulNonGenesis(){
      this.glowImageNonGenesis = true;
      setTimeout(() => {
        this.glowImageNonGenesis = false;
      }, 500);
    }
  },
  async created(){
    this.mintPriceDecreasePerHour = new BN(await this.fetchMintCharacterPriceDecreasePerSecond()).div(new BN(10).pow(18)).multipliedBy(60*60).toFixed(6);
    this.mintCharacterPriceIncrease = new BN(await this.fetchCharacterMintIncreasePrice()).div(new BN(10).pow(18)).toFixed(6);
    this.mintCharacterMinPrice = new BN(await this.fetchMintCharacterMinPrice()).div(new BN(10).pow(18)).toFixed(4);
    await this.updateMintCharacterFee();
  },
  components:{
    BigButton,
    CharacterList,
    CharacterNav,
    Character
  }
});
