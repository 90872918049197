




















import Vue from 'vue';
import CharacterDisplay from './smart/CharacterDisplay.vue';
import '@/mixins/general';


export default Vue.extend({
  props: ['isToggled', 'sidebarType'],
  components: {
    CharacterDisplay,
  },
  data() {
    return {
      showAds: false,
      componentKey: 0,
      currentPath: ''
    };
  },
  methods: {
    checkStorage() {
      if (process.env.NODE_ENV === 'development') this.showAds = false;
      else this.showAds = localStorage.getItem('show-ads') === 'true';
    },
    renderPageDisplay(){
      let toDisplay;

      if(this.isToggled){
        toDisplay = 'sideBorder';
      }else{
        toDisplay = 'col-xl-2 col-lg-3 col-md-3 col-sm-2 cols-1';
      }
      return toDisplay;
    },
  },
  computed:{
    isTablet() {
      if (screen.width <= 900) {
        return true;
      } else {
        return false;
      }
    },
  },
  async mounted() {
    this.checkStorage();
    this.currentPath = (this as any).$route.path;
  },
  watch:{
    $route (to){
      this.currentPath = to.path;
    },
  }
});
