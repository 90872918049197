

















import Vue, { PropType } from 'vue';
import Update from '@/components/Update.vue';
import { INotification } from '@/interfaces';

export default Vue.extend({
  props: {
    notificationsFromAPI: {
      type: Array as PropType<INotification[]>,
      default() {
        return [];
      },
    },
    updateNotifications: {
      type: Array as PropType<INotification[]>,
      default() {
        return [];
      },
    },
    readAll: {
      type: Boolean as PropType<boolean | undefined>,
    },
  },
  methods: {
    setAllUpdateNotificationsAsRead() {
      if (!this.readAll)
      {
        this.$emit('update:readAll', true);
        this.updateNotifications.forEach((notification) => {
          notification.isRead = true;
        });
        this.$emit('refresh-update-popup');
        this.updateStorageOfUpdateNotifications();
      }
    },

    isEveryUpdateRead() {
      const unreadUpdate = this.updateNotifications.find((notification) => !notification.isRead);
      if (!unreadUpdate) {
        this.$emit('update:readAll', true);
        this.$emit('refresh-update-popup');
      }
    },

    /**
     * If there are new notifications from the API then we add them
     * to the front of the user's notifications on localStorage.
     * If this is the user's first time, we add fresh API data to localStorage.
     */
    async checkForNotificationUpdatesFromAPI() {
      const notificationChanges = [];

      if (this.updateNotifications.length > 0) {
        let j = 0;
        for(let i = 0; i < this.notificationsFromAPI.length; i++) {
          if (this.notificationsFromAPI[i].hash !== this.updateNotifications[i + j].hash) {
            notificationChanges.push(this.notificationsFromAPI[i]);
            j++;
          }
          else {
            break;
          }
        }
        if (notificationChanges.length > 0) {
          this.updateNotifications.unshift(...notificationChanges);
          this.setUpdateNotificationsFromAPI(this.updateNotifications.slice(0, 10));
        }
      }
      else {
        this.setUpdateNotificationsFromAPI(this.notificationsFromAPI);
      }
    },

    async setUpdateNotificationsFromAPI(notificationsFromAPI: INotification[]) {
      this.$emit('update:updateNotifications', notificationsFromAPI);
      this.updateStorageOfUpdateNotifications();
    },

    /**
     * update localStorage with new notification data.
     * stored data is of the form
     * {updateNotifications: INotification[], readAll: boolean}
     */
    updateStorageOfUpdateNotifications() {
      const storageData = {updateNotifications: this.updateNotifications, readAll: this.readAll};
      localStorage.setItem('updateNotifications', JSON.stringify(storageData));
    },

    refreshUpdatePopup() {
      this.updateStorageOfUpdateNotifications();
      this.isEveryUpdateRead();
    },
  },
  async created() {
    await this.checkForNotificationUpdatesFromAPI();
  },
  components: {
    Update,
  },
});
