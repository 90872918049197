














































































































































































































































































































































































































































































































































































































































import BN from 'bignumber.js';
import WeaponGrid from '../components/smart/WeaponGrid.vue';
import RightMenu from '../components/RightMenu.vue';
import BigButton from '../components/BigButton.vue';
import { getWeaponArt } from '@/weapon-arts-placeholder';
import { getWeaponRarity } from '@/weapon-element';
import { getCleanName } from '@/rename-censor';
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import WeaponIcon from '../components/WeaponIcon.vue';
import { BModal } from 'bootstrap-vue';
import NftList from '@/components/smart/NftList.vue';
import { Contracts, IState } from '@/interfaces';
import { ISpecialWeaponsManagerState } from '@/store/specialWeaponsManager';
import { Accessors } from 'vue/types/options';
import DustBalanceDisplay from '@/components/smart/DustBalanceDisplay.vue';
import { fromWeiEther, toBN } from '@/utils/common';
import i18n from '@/i18n';
import Events from '../events';
import SpecialWeaponForgeModal from '@/components/smart/SpecialWeaponForgeModal.vue';
import BlacksmithNav from '@/components/BlacksmithNav.vue';
import { getConfigValue } from '@/contracts';
import {Element} from '@/enums/Element';

type StoreMappedState = Pick<IState, 'defaultAccount' | 'ownedWeaponIds' | 'skillBalance' | 'inGameOnlyFunds' | 'skillRewards' >;

type StoreMappedSpecialWeaponsManagerState = Pick<ISpecialWeaponsManagerState, 'specialWeaponEvents' | 'activeSpecialWeaponEventsIds' | 'specialWeaponEventId'>;

interface StoreMappedGetters {
  contracts: Contracts;
  ownWeapons: any[];
  nftsCount: number;
}

interface Data {
  showReforge: boolean;
  showBlacksmith: boolean,
  showDustForge: boolean,
  showReforgeDust: boolean,
  reforgeWeaponId: string | null;
  burnWeaponId: string | null;
  selectedNft: string | null;
  forgeCost: string;
  reforgeCost: string;
  dustReforgeCost: string,
  burnCost: string,
  disableForge: boolean;
  disableX10Forge: boolean;
  newForged: number[];
  currentListofWeapons: string[];
  selectedElement: number | null,
  chosenElementFee: number | null,
  clickedForgeButton: number | null,
  spin: boolean;
  lesserDust: string,
  greaterDust: string,
  powerfulDust: string,
  dust: string[],
  allowDustForge: false,
  burnWeaponIds: any[],
  onError: boolean;
  hideWeapons: any[];
  useStakedForForge: boolean;
  disableUseStakedForForge: boolean;
  disableX10ForgeWithStaked: boolean;
  forgeCostBN: BN;
  targetSkin: string;
  haveWeaponCosmetic1: number;
  haveWeaponCosmetic2: number;
  selectedSpecialWeaponEventId: number;
  showModal: boolean;
  modalType: string;
  magicCircleSpeed: boolean;
  totalBonusPower: number;
  lesser: number;
  greater: number;
  powerful: number;
  lesserDustReforgeCap: number,
  greaterDustReforgeCap: number,
  powerfulDustReforgeCap: number,
  activeTab: string;
  ctr: number;
  mintSlippageApproved: boolean;
  mintPriceDecreasePerHour: string;
  mintWeaponPriceIncrease: string;
  mintWeaponMinPrice: string;
  cooling: boolean;
  currentFilteredWeapons: any[];
  isLoading: boolean;
  canClaim: boolean;
  showAds: boolean;
}

export default Vue.extend({
  data() {
    return {
      showReforge: false,
      showBlacksmith: true,
      showDustForge: false,
      showReforgeDust: false,
      reforgeWeaponId: null,
      burnWeaponId: null,
      selectedNft: null,
      forgeCost: '0',
      reforgeCost: '0',
      dustReforgeCost: '0',
      burnCost: '0',
      disableForge: false,
      disableX10Forge: (getConfigValue('featureSupport').disableDynamicMinting),
      newForged: [],
      currentListofWeapons: [],
      selectedElement: null,
      chosenElementFee: null,
      clickedForgeButton: null,
      spin: false,
      lesserDust: '0',
      greaterDust: '0',
      powerfulDust: '0',
      dust: [],
      allowDustForge: false,
      burnWeaponIds: [],
      onError: false,
      hideWeapons: [],
      useStakedForForge:false,
      disableUseStakedForForge: false,
      disableX10ForgeWithStaked: false,
      forgeCostBN: new BN(0),
      targetSkin: '',
      haveWeaponCosmetic1: 0,
      haveWeaponCosmetic2: 0,
      selectedSpecialWeaponEventId: 0,
      showModal: false,
      modalType: '',
      magicCircleSpeed: false,
      totalBonusPower: 0,
      lesser: 0,
      greater: 0,
      powerful: 0,
      lesserDustReforgeCap: 100,
      greaterDustReforgeCap: 25,
      powerfulDustReforgeCap: 10,
      activeTab: 'forge',
      ctr: 0,
      mintSlippageApproved: false,
      mintPriceDecreasePerHour: '0',
      mintWeaponPriceIncrease: '0',
      mintWeaponMinPrice: '0',
      cooling: false,
      currentFilteredWeapons: [],
      isLoading: true,
      canClaim: false,
      Element,
      showAds: false,
    } as Data;
  },

  computed: {
    ...mapState(['defaultAccount','ownedWeaponIds','ownedShieldIds','skillBalance', 'inGameOnlyFunds', 'skillRewards',
      'activeSpecialWeaponEventsIds', 'specialWeaponEvents', 'specialWeaponEventId']) as Accessors<StoreMappedState>,
    ...mapState('specialWeaponsManager',
      (['specialWeaponEvents', 'activeSpecialWeaponEventsIds','specialWeaponEventId'])) as Accessors<StoreMappedSpecialWeaponsManagerState>,
    ...(mapGetters([
      'contracts', 'ownWeapons', 'nftsCount', 'ownShields',
      'getPowerfulDust', 'getGreaterDust', 'getLesserDust',
    ]) as Accessors<StoreMappedGetters>),
    ...mapGetters([
      'getWeaponName'
    ]),
    ...(mapGetters('staking', ['stakedSkillBalanceThatCanBeSpent'])) as Accessors<{ stakedSkillBalanceThatCanBeSpent: BN }>,
    currentFilteredWeaponsIds(): string[] {
      return this.currentFilteredWeapons.map(w => w.id);
    },
    totalSkillBalance(): BN {
      return toBN(fromWeiEther(this.skillRewards)).plus(toBN(fromWeiEther(this.inGameOnlyFunds))).plus(toBN(fromWeiEther(this.skillBalance)));
    },

    disableConfirmButton(): boolean {
      return this.selectedElement === null || !this.chosenElementFee ||
        this.totalSkillBalance.lt(this.forgeCostBN.times(this.chosenElementFee).times(this.clickedForgeButton ? 10 : 1));
    }
  },

  watch: {
    defaultAccount() {
      this.updateForgeData();
    },
    activeTab(data){
      if(data === 'forge'){
        this.displayBlacksmith();
      }else{
        this.displayDustCreation();
      }
    },
    currentFilteredWeapons(){
      const currentFilteredForBuringIds = this.currentFilteredWeaponsIds.filter(id => this.burnWeaponIds.includes(id));
      this.$root.$emit('select-all-button-labeler', currentFilteredForBuringIds.length > 0);
    },
    burnWeaponIds(data){
      const currentFilteredForBuringIds = this.currentFilteredWeaponsIds.filter(id => data.includes(id));
      this.$root.$emit('select-all-button-labeler', currentFilteredForBuringIds.length > 0);
    },
    reforgeWeaponId() {
      Events.$emit('hasSelected');
      this.showReforge = false;
      this.burnWeaponId = null;
    },
    stakedSkillBalanceThatCanBeSpent(){
      const stakedSkillBalanceThatCanBeSpentBN: BN = new BN(this.stakedSkillBalanceThatCanBeSpent).div(new BN(10).pow(18));

      if((stakedSkillBalanceThatCanBeSpentBN.minus(this.forgeCostBN.multipliedBy(0.8))).isLessThan(0)) {
        this.disableUseStakedForForge = true;
      }
      if((stakedSkillBalanceThatCanBeSpentBN.minus(this.forgeCostBN.multipliedBy(0.8).multipliedBy(10))).isLessThan(0)){
        this.disableX10ForgeWithStaked = true;
      }
    },
  },

  async mounted(){
    this.checkStorage();
    Events.$on('forge-weapon', (id: number) =>{
      if(id === 0){
        this.onClickForge(id);
        (this.$refs['forge-element-selector-modal']as BModal).show();
      }else if(id === 1){
        this.onClickForge(id);
        (this.$refs['forge-element-selector-modal']as BModal).show();
      }else if(id === 2){
        Events.$emit('show-special-forge-modal');
      }else{
        this.displayDustReforge();
      }
    });

    Events.$on('create-dust', () =>{
      this.showMassDustConfirmation();
    });

    this.updateForgeData();
  },

  methods: {
    ...mapActions(['reforgeWeapon', 'mintWeapons', 'hasWeaponsToClaim', 'claimWeapons',
      'quantityOfWeaponsToClaim',
      'burnWeapon', 'massBurnWeapons',
      'reforgeWeaponWithDust', 'massBurnWeapons',
      'fetchMintWeaponPriceDecreasePerSecond', 'fetchWeaponMintIncreasePrice',
      'fetchMintWeaponMinPrice', 'fetchMintWeaponFee', 'fetchUsdSkillValue',
      'fetchReforgeWeaponFee', 'fetchReforgeWeaponWithDustFee', 'fetchBurnWeaponFee']),
    ...mapActions('specialWeaponsManager', ['fetchSpecialWeaponEvents']),
    ...mapMutations(['updateSpecialWeaponEventId']),
    setStakedForForgeValue(value: boolean){
      this.useStakedForForge = value;
    },
    passFilteredItems(data: any[]){
      this.currentFilteredWeapons = data;
    },
    toggleCheckbox() {
      this.useStakedForForge = !this.useStakedForForge;
      if (this.useStakedForForge) localStorage.setItem('useStakedForForge', 'true');
      else localStorage.setItem('useStakedForForge', 'false');
    },

    onClickSpecialForge() {
      Events.$emit('show-special-forge-modal');
    },

    getWeaponInfo(type: string): any{
      const weaponActive  = this.ownWeapons.find(x => x.id === this.reforgeWeaponId);
      this.totalBonusPower = Number(this.lesserDust) + Number(this.greaterDust) + Number(this.powerfulDust);

      if(type === 'name') return getCleanName(this.getWeaponName(weaponActive.id, weaponActive.stars));
      else if(type === 'rarity') return this.getWeaponRarity(weaponActive.stars);
      else if(type === 'element') return weaponActive.element;
      else if(type === 'stat1') return weaponActive.stat1;
      else if(type === 'stat1Type') return weaponActive.stat1Type;
      else if(type === 'stat1Value') return weaponActive.stat1Value;
      else if(type === 'stat2') return weaponActive.stat2;
      else if(type === 'stat2Type') return weaponActive.stat2Type;
      else if(type === 'stat2Value') return weaponActive.stat2Value;
      else if(type === 'stat3') return weaponActive.stat3;
      else if(type === 'stat3Type') return weaponActive.stat3Type;
      else if(type === 'stat3Value') return weaponActive.stat3Value;
      else if(type === 'bonusPower') return weaponActive.bonusPower;
    },

    async onForgeWeapon(amount: number) {
      this.disableForge = true;
      (this.$refs['forge-element-selector-modal']as BModal)?.hide();
      this.modalType = 'forge';
      this.showModal = true;
      this.spin = true;
      try {
        await this.mintWeapons({
          quantity: amount,
          useStakedSkillOnly: this.useStakedForForge,
          chosenElement: this.selectedElement,
          eventId: this.selectedSpecialWeaponEventId,
          mintSlippageApproved: this.mintSlippageApproved
        });
        this.newForged = this.ownedWeaponIds.splice(this.ownedWeaponIds.length - amount, this.ownedWeaponIds.length);
        (this.$refs['new-forge-weapon'] as BModal).show();
      } catch (error) {
        console.error('Error while forging:', error);
        (this as any).$dialog.notify.error(i18n.t('blacksmith.couldNotForge'));
      } finally {
        this.disableForge = false;
        this.selectedElement = null;
        this.showModal = false;
        this.spin = false;
        //refresh forge data
        this.isLoading = true;
        this.updateForgeData();
      }
    },

    computeDust(operator: string,star: number, lesser: number,greater: number,powerful: number){
      if(operator === 'add'){
        if(star <= 3) this.lesser = this.lesser + (star * 2);
        else if (star === 4) this.greater = this.greater + 2;
        else if (star === 5) this.powerful = this.powerful + 2;

        if(lesser > 0){
          this.lesser = this.lesser + Math.floor(lesser/2);
        }
        if(greater > 0){
          this.greater = this.greater + Math.floor(greater/2);
        }
        if(powerful > 0){
          this.powerful = this.powerful + Math.floor(powerful/2);
        }
      }else{
        if(star <= 3) this.lesser = this.lesser - (star * 2);
        else if (star === 4) this.greater = this.greater - 2;
        else if (star === 5) this.powerful = this.powerful - 2;

        if(lesser > 0){
          this.lesser = this.lesser - Math.floor(lesser/2);
        }
        if(greater > 0){
          this.greater = this.greater - Math.floor(greater/2);
        }
        if(powerful > 0){
          this.powerful = this.powerful - Math.floor(powerful/2);
        }
      }
    },

    onShowForgeDetails() {
      (this.$refs['forge-details-modal'] as BModal).show();
    },

    onClickForge(i: number) {
      if(+this.specialWeaponEventId === 0 && this.activeSpecialWeaponEventsIds.length > 0) {
        this.selectedSpecialWeaponEventId = +this.activeSpecialWeaponEventsIds[0];
      }
      else {
        this.selectedSpecialWeaponEventId = +this.specialWeaponEventId;
      }
      this.clickedForgeButton = i;
      this.chosenElementFee = null;
      (this.$refs['forge-element-selector-modal']as BModal).show();
    },

    async onClickClaimWeapons() {
      try {
        this.disableForge = true;
        this.modalType = 'forge';
        this.showModal = true;
        this.spin = true;
        const quantity = await this.quantityOfWeaponsToClaim();
        await this.claimWeapons();
        this.newForged = this.ownedWeaponIds.splice(this.ownedWeaponIds.length - quantity, this.ownedWeaponIds.length);
        (this.$refs['new-forge-weapon'] as BModal).show();
      } catch (error) {
        console.error('Error while claiming:', error);
        (this as any).$dialog.notify.error(i18n.t('blacksmith.couldNotForge'));
      } finally {
        this.disableForge = false;
        this.selectedElement = null;
        this.showModal = false;
        this.spin = false;
        //refresh forge data
        this.isLoading = true;
        this.updateForgeData();
      }
    },

    setChosenElement(elementObject: any, selectedNumber: Element) {
      if(selectedNumber === this.selectedElement) this.selectedElement = null;
      else this.selectedElement = selectedNumber;

      this.chosenElementFee = selectedNumber === 100 ? 1 : 2;
      elementObject.srcElement.classList.toggle('done');
      Array.from(elementObject.srcElement.parentNode.childNodes).forEach((child: any) => {
        if (child !== elementObject.srcElement && child.classList.contains('done') === true){
          child.classList.toggle('done');
        }
      });
    },

    showReforgeConfirmation() {
      (this.$refs['reforge-confirmation-modal'] as BModal).show();
    },

    showDustReforgeConfirmation() {
      if(this.lesserDust === '0' && this.greaterDust === '0' && this.powerfulDust === '0'){
        (this as any).$dialog.notify.error('No Dust Selected');
      }else{
        (this.$refs['confirm-reforge'] as BModal).show();
      }
    },

    showMassDustConfirmation() {
      (this.$refs['mass-dust-confirmation-modal'] as BModal).show();
    },

    displayDustReforge() {
      this.showReforge = true;
      this.showBlacksmith = false;
      this.showReforgeDust = true;
      this.showDustForge = false;
      this.lesserDust = '0';
      this.greaterDust = '0';
      this.powerfulDust = '0';
    },
    displayDustCreation(){
      this.activeTab = 'salvage';
      return this.showReforge = true,
      this.showBlacksmith = false,
      this.showDustForge = true,
      this.showReforgeDust = false,
      this.hideWeapons = this.ownedWeaponIds;
    },
    displayBlacksmith(){
      this.activeTab = 'forge';
      this.showReforge = false;
      this.showBlacksmith = true;
      this.showDustForge = false;
      this.showReforgeDust = false;
      this.lesser = 0;
      this.greater = 0;
      this.powerful = 0;
      this.burnWeaponIds = [];
    },
    cancelReforge() {
      this.showReforge = false;
      this.showBlacksmith = true;
      this.showDustForge = false;
      this.showReforgeDust = false;
      this.burnWeaponIds = [];
      this.hideWeapons = this.ownedWeaponIds;
      this.lesserDust = '0';
      this.greaterDust = '0';
      this.powerfulDust = '0';
    },
    getWeaponToUpgrade() {
      if(this.reforgeWeaponId){
        return this.ownWeapons.find(x => x.id === this.reforgeWeaponId);
      }
    },

    selectAllForBurn(){
      const currentFilteredForBuringIds = this.currentFilteredWeaponsIds.filter(id => this.burnWeaponIds.includes(id));
      if(currentFilteredForBuringIds.length > 0){
        currentFilteredForBuringIds.forEach(id => {
          this.ctr += 1;
          const weaponDetails = this.ownWeapons.find(y => {
            if(y && +y.id === +id){
              return y;
            }
          });
          if(weaponDetails){
            this.computeDust('sub',(weaponDetails.stars + 1), weaponDetails.lowStarBurnPoints, weaponDetails.fourStarBurnPoints,
              weaponDetails.fiveStarBurnPoints);
          }
          this.burnWeaponIds = this.burnWeaponIds.filter(e => e !== id);
        });
        return;
      }
      const currentFilteredNotForBuringIds = this.currentFilteredWeaponsIds.filter(id => !this.burnWeaponIds.includes(id));
      currentFilteredNotForBuringIds.forEach(id => {
        this.burnWeaponIds.push(id);
        this.ctr += 1;
        const weaponDetails = this.ownWeapons.find(y => +y.id === +id);
        this.computeDust('add',(weaponDetails.stars + 1), weaponDetails.lowStarBurnPoints, weaponDetails.fourStarBurnPoints, weaponDetails.fiveStarBurnPoints);
      });
    },

    addBurnWeapon(id: number){
      this.ctr += 1;
      if(this.burnWeaponIds.includes(+id)){
        this.burnWeaponIds = this.burnWeaponIds.filter(val => +val !== +id);
        const weaponDetails = this.ownWeapons.find(y => y.id === id);
        this.computeDust('sub',(weaponDetails.stars + 1), weaponDetails.lowStarBurnPoints, weaponDetails.fourStarBurnPoints, weaponDetails.fiveStarBurnPoints);
      }else{
        this.burnWeaponIds.push(+id);
        const weaponDetails = this.ownWeapons.find(y => +y.id === +id);
        this.computeDust('add',(weaponDetails.stars + 1), weaponDetails.lowStarBurnPoints, weaponDetails.fourStarBurnPoints, weaponDetails.fiveStarBurnPoints);
      }
      this.burnWeaponId = null;
    },

    closeModal(modalType: string){
      (this.$refs[modalType] as BModal).hide();
    },

    getWeaponArt,
    getWeaponRarity,

    async onReforgeWeaponWithDust() {
      this.showModal = false;
      (this.$refs['confirm-reforge'] as BModal).hide();
      this.magicCircleSpeed = true;

      try {
        await this.reforgeWeaponWithDust({
          reforgeWeaponId: this.reforgeWeaponId,
          lesserDust: this.lesserDust,
          greaterDust: this.greaterDust,
          powerfulDust:this.powerfulDust
        });

        this.modalType = 'successReforge';
        (this.$refs['succesful-reforge'] as BModal).show();
        // this.showModal = true;
        this.magicCircleSpeed = false;
      } catch (error) {
        console.error(error);
        (this as any).$dialog.notify.error(i18n.t('blacksmith.couldNotReforge'));
      }
    },

    clearDust(){
      this.showModal = false;
      if(this.modalType === 'successReforge'){
        this.lesserDust = '0';
        this.greaterDust = '0';
        this.powerfulDust = '0';
      }
    },

    async onMassBurnWeapons() {
      this.cooling = true;

      try {
        await this.massBurnWeapons({
          burnWeaponIds: this.burnWeaponIds,
        });
        this.hideWeapons = this.hideWeapons.filter(id => !this.burnWeaponIds.includes(id));
        this.burnWeaponIds = [];
        this.burnWeaponId = null;
        (this.$refs['mass-dust-confirmation-modal'] as BModal).hide();
        this.cooling = false;
        this.lesser = 0;
        this.greater = 0;
        this.powerful = 0;
      } catch (error) {
        console.error(error);
        (this as any).$dialog.notify.error(i18n.t('blacksmith.couldNotBurn'));
        this.cooling = false;
      }
    },
    async updateForgeData(){
      if(!this.defaultAccount) return;
      this.canClaim = await this.hasWeaponsToClaim();
      const forgeCost = await this.fetchMintWeaponFee();
      const skillForgeCost = await this.fetchUsdSkillValue(forgeCost);
      this.forgeCost = new BN(skillForgeCost).div(new BN(10).pow(18)).toFixed(4);
      this.forgeCostBN = new BN(skillForgeCost).div(new BN(10).pow(18));
      this.isLoading = false;

      const stakedSkillBalanceThatCanBeSpentBN: BN = new BN(this.stakedSkillBalanceThatCanBeSpent).div(new BN(10).pow(18));

      if((stakedSkillBalanceThatCanBeSpentBN.minus(this.forgeCostBN.multipliedBy(0.8))).isLessThan(0)) {
        this.disableUseStakedForForge = true;
      }
      if((stakedSkillBalanceThatCanBeSpentBN.minus(this.forgeCostBN.multipliedBy(0.8).multipliedBy(10))).isLessThan(0)){
        this.disableX10ForgeWithStaked = true;
      }
      const reforgeCost = await this.fetchReforgeWeaponFee();
      const skillReforgeCost = await this.fetchUsdSkillValue(reforgeCost);
      this.reforgeCost = new BN(skillReforgeCost).div(new BN(10).pow(18)).toFixed(4);

      const reforgeDustCost = await this.fetchReforgeWeaponWithDustFee();
      const skillDustReforgeCost = await this.fetchUsdSkillValue(reforgeDustCost);
      this.dustReforgeCost = new BN(skillDustReforgeCost).div(new BN(10).pow(18)).toFixed(4);

      const burnCost = await this.fetchBurnWeaponFee();
      const skillBurnCost = await this.fetchUsdSkillValue(burnCost);
      this.burnCost = new BN(skillBurnCost).div(new BN(10).pow(18)).toFixed(4);
      if(window.location.href.split('&').find(x => x === 'showSpecialForge')) {
        Events.$emit('show-special-forge-modal');
      }
      this.mintPriceDecreasePerHour = new BN(await this.fetchMintWeaponPriceDecreasePerSecond()).div(new BN(10).pow(18)).multipliedBy(60*60).toFixed(6);
      this.mintWeaponPriceIncrease = new BN(await this.fetchWeaponMintIncreasePrice()).div(new BN(10).pow(18)).toFixed(6);
      this.mintWeaponMinPrice = new BN(await this.fetchMintWeaponMinPrice()).div(new BN(10).pow(18)).toFixed(4);

      await this.fetchSpecialWeaponEvents();
      this.selectedSpecialWeaponEventId = +this.specialWeaponEventId;
    },
    checkStorage() {
      if (process.env.NODE_ENV === 'development') this.showAds = false;
      else this.showAds = localStorage.getItem('show-ads') === 'true';
    }
  },

  components: {
    DustBalanceDisplay,
    WeaponGrid,
    RightMenu,
    BigButton,
    WeaponIcon,
    BModal,
    NftList,
    SpecialWeaponForgeModal,
    BlacksmithNav
  },
});
