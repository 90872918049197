
























































































































































































import Vue from 'vue';
import {mapActions, mapGetters, mapState} from 'vuex';
import {Nft} from '@/interfaces/Nft';
import {Accessors} from 'vue/types/options';
import QuestRow from '@/components/smart/QuestRow.vue';
import QuestComponentIcon from '@/components/smart/QuestComponentIcon.vue';
import QuestReward from '@/components/smart/QuestReward.vue';
import QuestsList from '@/components/smart/QuestsList.vue';
import QuestRequirements from '@/components/smart/QuestRequirements.vue';
import QuestRewards from '@/components/smart/QuestRewards.vue';
import hourglass from '@/assets/hourglass.png';
import {getTimeRemaining} from '@/utils/common';
import {NftIdType} from '@/components/smart/NftList.vue';
import QuestNav from '@/components/QuestNav.vue';
import Hint from '@/components/Hint.vue';
import {
  Quest,
  ReputationLevelRequirements,
  WeeklyReward,
  RewardType } from '@/interfaces';
import {
  Rarity,
  QuestTemplateType,
  QuestItemType } from '@/enums/Quest';

interface StoreMappedActions {
  fetchCharacters(characterIds: (string | number)[]): Promise<void>;

  getCharacterQuestData(payload: { characterId: string | number }): Promise<Quest>;

  getReputationLevelRequirements(): Promise<ReputationLevelRequirements>;

  nextWeeklyQuestCompletionGoalReset(): Promise<string>;

  getWeeklyCompletions(): Promise<number>;

  getWeeklyReward(payload: { timestamp: number }): Promise<WeeklyReward>;

  hasClaimedWeeklyReward(): Promise<boolean>;

  claimWeeklyReward(): Promise<number[]>;

  isUsingPromoQuests(): Promise<boolean>;

  getQuestTemplates(payload: { tier: number }): Promise<Quest[]>;
}

interface StoreMappedGetters {
  charactersWithIds(ids: (string | number)[]): Nft[];
}

interface Data {
  weeklyReward?: WeeklyReward;
  characters: Nft[];
  reputationLevelRequirements?: ReputationLevelRequirements;
  weeklyClaimed: boolean;
  showWeeklyClaimedModal: boolean;
  weeklyRewards: NftIdType[];
  isLoading: boolean;
  isLoadingWalletQuests: boolean;
  nextWeekResetTime: string;
  nextWeekResetCheckInterval?: ReturnType<typeof setInterval>;
  currentWeeklyCompletions: number;
  showQuestsListModal: boolean;
  rarities: Rarity[];
  tier?: Rarity;
  usePromoQuests: boolean;
  questTemplateType: QuestTemplateType;
  walletQuests: Quest[];
  walletQuestTier: Rarity;
  activeTab: string;
  pickableQuestTier?: Rarity;
  quests: Quest[];
  pickable: boolean;
  pickedQuestId: number;
  showAds: boolean
}

export default Vue.extend({
  components: {
    QuestRow,
    QuestComponentIcon,
    QuestReward,
    QuestNav,
    QuestRequirements,
    QuestRewards,
    QuestsList,
    Hint,
  },

  props: {
    showCosmetics: {
      type: Boolean,
      default: true
    },
  },

  data() {
    return {
      weeklyReward: undefined,
      characters: [],
      reputationLevelRequirements: undefined,
      weeklyRewards: [],
      weeklyClaimed: true,
      showWeeklyClaimedModal: false,
      isLoading: false,
      isLoadingWalletQuests: false,
      nextWeekResetTime: '',
      currentWeeklyCompletions: 0,
      showQuestsListModal: false,
      rarities: [Rarity.COMMON, Rarity.UNCOMMON, Rarity.RARE, Rarity.EPIC, Rarity.LEGENDARY],
      tier: undefined,
      usePromoQuests: false,
      hourglass,
      QuestItemType,
      Rarity,
      QuestTemplateType,
      questTemplateType: QuestTemplateType.QUEST,
      walletQuests:[],
      walletQuestTier: 0,
      activeTab: 'wallet-quests',
      pickableQuestTier: Rarity.COMMON,
      quests: [],
      pickable: false,
      pickedQuestId: 0,
      showAds: false,
    } as Data;
  },

  computed: {
    ...mapState(['ownedCharacterIds', 'defaultAccount', 'currentNetworkId']),
    ...mapGetters(['charactersWithIds', 'getCharacterCosmetic']) as Accessors<StoreMappedGetters>,

    canClaimWeeklyReward(): boolean {
      return !!this.weeklyReward && !this.weeklyClaimed && this.weeklyGoalReached;
    },

    weeklyGoalReached(): boolean {
      return this.currentWeeklyCompletions >= this.weeklyReward?.completionsGoal!;
    },

    tierOffset(): number {
      switch(this.questTemplateType){
      default:
        return 0;
      case QuestTemplateType.PROMO:
        return 10;
      case QuestTemplateType.PICKABLE:
        return 20;
      case QuestTemplateType.WALLET:
        return 30;
      }
    },
  },

  methods: {
    ...mapActions([
      'fetchCharacters',
      'getCharacterQuestData',
      'getReputationLevelRequirements',
      'nextWeeklyQuestCompletionGoalReset',
      'getWeeklyCompletions',
      'getWeeklyReward',
      'hasClaimedWeeklyReward',
      'claimWeeklyReward',
      'isUsingPromoQuests',
      'getQuestTemplates'
    ]) as StoreMappedActions,

    onChangeTab(tab: string) {
      this.activeTab = tab;
    },

    async fetchPickableQuests() {
      try {
        this.isLoading = true;
        this.quests = await this.getQuestTemplates({tier: this.pickableQuestTier! + 20});
      } finally {
        this.isLoading = false;
      }
    },

    async claimWeekly() {
      if (!this.canClaimWeeklyReward) {
        return;
      }

      try {
        this.isLoading = true;
        const rewards = await this.claimWeeklyReward();
        const rewardType = this.weeklyReward?.rewardType;
        if (!rewardType || rewardType === RewardType.EXPERIENCE || rewardType === RewardType.DUST || rewardType === RewardType.SOUL) {
          this.showWeeklyClaimedModal = true;
          return;
        } else {
          this.weeklyRewards = rewards.map((reward: number) => {
            return {type: QuestItemType[rewardType].toLowerCase(), id: reward} as NftIdType;
          });
          this.weeklyClaimed = true;
          this.showWeeklyClaimedModal = true;
        }
      } finally {
        await this.refreshQuestData();
        this.isLoading = false;
      }
    },

    async refreshQuestData() {
      try {
        this.isLoading = true;
        this.isLoadingWalletQuests = true;
        // TODO: Filter out unavailable wallet quests here
        this.walletQuests = await this.getQuestTemplates({tier: this.walletQuestTier + 30});
        this.isLoadingWalletQuests = false;
        const [
          usePromoQuests,
          currentWeeklyCompletions,
          weeklyReward,
          weeklyClaimed,
          reputationLevelRequirements,
          characters,
        ] = await Promise.all([
          this.isUsingPromoQuests(),
          this.getWeeklyCompletions(),
          this.getWeeklyReward({timestamp: Date.now()}),
          this.hasClaimedWeeklyReward(),
          this.getReputationLevelRequirements(),
          Promise.all(this.charactersWithIds(this.ownedCharacterIds).filter(Boolean).map(async (character) => {
            character.quest = await this.getCharacterQuestData({characterId: character.id});
            return character;
          })),
          this.getNextWeekResetTime(),
        ]);
        this.usePromoQuests = usePromoQuests;
        this.currentWeeklyCompletions = +currentWeeklyCompletions;
        this.weeklyReward = weeklyReward;
        this.weeklyClaimed = weeklyClaimed;
        this.reputationLevelRequirements = reputationLevelRequirements;
        this.characters = characters;
      } finally {
        this.isLoading = false;
      }
    },

    async getNextWeekResetTime() {
      const nextWeekResetTimestamp = await this.nextWeeklyQuestCompletionGoalReset();
      if (this.nextWeekResetCheckInterval) {
        clearInterval(this.nextWeekResetCheckInterval);
      }
      this.nextWeekResetCheckInterval = setInterval(async () => {
        const {total, days, hours, minutes, seconds} = getTimeRemaining(nextWeekResetTimestamp);
        this.nextWeekResetTime = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        if (total <= 1000 && this.nextWeekResetCheckInterval) {
          clearInterval(this.nextWeekResetCheckInterval);
          this.currentWeeklyCompletions = +await this.getWeeklyCompletions();
          this.weeklyReward = await this.getWeeklyReward({timestamp: Date.now()});
          this.weeklyClaimed = await this.hasClaimedWeeklyReward();
        }
      }, 1000);
    },

    async onRefreshQuestData() {
      await this.refreshQuestData();
    },
    checkStorage() {
      if (process.env.NODE_ENV === 'development') this.showAds = false;
      else this.showAds = localStorage.getItem('show-ads') === 'true';
    }
  },

  async mounted() {
    this.checkStorage();
    await this.refreshQuestData();
  },

  beforeDestroy() {
    if (this.nextWeekResetCheckInterval) {
      clearInterval(this.nextWeekResetCheckInterval);
    }
  },

  watch: {
    async defaultAccount(){
      await this.refreshQuestData();
    },
    async ownedCharacterIds(currentcharacterIds, previousCharacterIds) {
      await this.fetchCharacters(currentcharacterIds);
      if (JSON.stringify(currentcharacterIds) !== JSON.stringify(previousCharacterIds)) {
        await this.refreshQuestData();
      }
    },
    async walletQuestTier() {
      this.walletQuests = [];
      this.isLoadingWalletQuests = true;
      this.walletQuests = await this.getQuestTemplates({tier: this.walletQuestTier+30});
      this.isLoadingWalletQuests = false;
    },
    pickableQuestTier(): void {
      this.fetchPickableQuests();
    },
    pickable(isPickable) {
      if (!isPickable) {
        this.pickedQuestId = 0;
      }
      else {
        this.fetchPickableQuests();
      }
    },
    async activeTab(currentTab, previousTab) {
      if (currentTab !== 'character-quests' && previousTab && this.pickable) {
        this.pickable = false;
      }
      else {
        await this.refreshQuestData();
      }
    },
  },
});
