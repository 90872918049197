























































































































































































































































































































































































import Vue from 'vue';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import { getCharacterArt } from '../character-arts-placeholder';
import NftIcon from '@/components/NftIcon.vue';
import NftList, {NftIdType} from '@/components/smart/NftList.vue';
import CurrencyConverter from '../components/CurrencyConverter.vue';
import {ICharacter, IRaidState, IState} from '@/interfaces';
import {getBossArt, getBossName} from '@/raid-boss-art-placeholder';
import {traitNumberToName} from '@/contract-models';
import {fromWeiEther, toBN} from '@/utils/common';
import {staminaToHours} from '@/utils/date-time';
import {BonusXp, Dust4b, Dust5b, DustLb, Junk, Keybox, RaidRewards, Weapon} from '@/interfaces/RaidRewards';
import i18n from '@/i18n';
import { getCleanName } from '../rename-censor';
import BigNumber from 'bignumber.js';

interface RaidMappedActions {
  fetchRaidState(): Promise<void>;
  joinRaid(payload: { characterId: string }): Promise<void>;
  fetchRaidRewards(payload: {startIndex: number, endIndex: string }): Promise<string[]>;
  claimRaidRewards(payload: { rewardIndex: string }): Promise<RaidRewards>;
  fetchRaidingCharacters(): Promise<string[]>;
  fetchRaidingWeapons(): Promise<string[]>;
  fetchIsRaidStarted(): Promise<boolean>;
  canUserAfford(payload: { payingAmount: BigNumber }): Promise<boolean>;
  fetchHaveEnoughEnergy(payload: { characterID: string }): Promise<boolean>;
  fetchIsCharacterRaiding(payload: { characterID: string }): Promise<boolean>;
  fetchIsWeaponRaiding(payload: { weaponID: string }): Promise<boolean>;
  fetchCharacters(payload: { characterIds: (string | number)[]}): Promise<void>;
  fetchCharacterWeapon(characterId: string | number): Promise<number>;
}

interface RaidMappedMutations {
  setCurrentCharacter(state: IState, characterId: number): void;
}

interface RaidMappedGetters {
  getRaidState(): IRaidState;
}

let interval: number;

interface Data {
  raidIndex: string;
  bossName: string;
  raiderCount: number;
  totalPower: number;
  expectedFinishTime: Date;
  xpReward: string;
  staminaCost: string;
  durabilityCost: string;
  joinCost: string;
  raidStatus: string;
  bossPower: number;
  bossTrait: string;
  accountPower: string;
  rewardsRaidId: string;
  rewardIndexes: string[];
  rewards: NftIdType[];
  spin: boolean;
  participatingCharacters: string[];
  participatingWeapons: string[];
  bonuxXpCharacterNames: string[];
  bonuxXpAmounts: string[];
  remainingTime: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  },
  traits: string;
  notifyError: string;
  isLoading: boolean;
  raidStarted: boolean;
  isClaimingRewards: boolean;
  isJoiningRaid: boolean;
  equippedWeaponId: string | number;
}

export default Vue.extend({
  data() {
    return {
      raidIndex: '',
      bossName: '',
      raiderCount: 0,
      totalPower: 0,
      expectedFinishTime: new Date(),
      xpReward: '',
      staminaCost: '',
      durabilityCost: '',
      joinCost: '',
      raidStatus: '',
      bossPower: 0,
      bossTrait: '',
      accountPower: '',
      rewardsRaidId: '',
      rewardIndexes: [],
      rewards: [] ,
      spin: false,
      participatingCharacters: [],
      participatingWeapons: [],
      bonuxXpCharacterNames: [],
      bonuxXpAmounts: [],
      remainingTime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      traits:'',
      notifyError: '',
      isLoading: false,
      raidStarted: false,
      isClaimingRewards: false,
      isJoiningRaid: false,
      equippedWeaponId: '',
    } as Data;
  },

  computed: {
    ...mapState([
      'characters',
      'currentCharacterId',
      'ownedCharacterIds'
    ]),
    ...mapGetters([
      'ownWeapons',
      'currentCharacter',
      'getCharacterName',
      'getCharacterPower',
    ]),

    currentMultiplier(): string {
      return '0';
      /*if(!this.selectedWeaponId) return '0';
      const currentWeapon = this.ownWeapons.find((weapon: IWeapon) => weapon.id === +this.selectedWeaponId);
      if(!currentWeapon) return '0';
      return GetTotalMultiplierForTrait(currentWeapon, this.currentCharacter.trait).toFixed(2);*/
    },

    currentCharacterPower(): number {
      if(!this.currentCharacter) return 0;
      return this.getCharacterPower(this.currentCharacter.id);
    },

    formatStaminaHours(): string {
      return staminaToHours(+this.staminaCost).toFixed(1);
    },

    selectedCharacter(): ICharacter{
      return this.characters[this.currentCharacterId];
    },
  },

  methods: {
    getBossArt,
    traitNumberToName,
    ...(mapActions([
      'fetchRaidState',
      'joinRaid',
      'fetchRaidRewards',
      'claimRaidRewards',
      'fetchRaidingCharacters',
      'fetchRaidingWeapons',
      'fetchIsRaidStarted',
      'fetchHaveEnoughEnergy',
      'fetchIsCharacterRaiding',
      'fetchIsWeaponRaiding',
      'fetchCharacters',
      'canUserAfford',
      'fetchCharacterWeapon',
    ]) as RaidMappedActions),
    ...(mapMutations([
      'setCurrentCharacter'
    ]) as RaidMappedMutations),
    ...(mapGetters([
      'getRaidState',
    ]) as RaidMappedGetters),
    getCharacterArt,

    noRewards(){
      return this.rewardIndexes === null || this.rewardIndexes.length === 0;
    },

    raidStart(){
      return !this.raidStarted;
    },

    generateTooltip(){
      const staminaHrs = this.formatStaminaHours;
      if(this.raidStarted){
        return (i18n.t('raid.joiningCostStamina', {staminaHrs}).toString());
      }else{
        return (i18n.t('raid.errors.raidNotStarted').toString());
      }
    },

    async closeRewardPicker(id: any) {
      try {
        this.isLoading = true;
        this.isClaimingRewards = true;
        this.rewardsRaidId = id;
        await this.claimRewardIndex(id);
      } finally {
        this.isLoading = false;
        this.isClaimingRewards = false;
      }
      (this as any).$bvModal.hide('rewardsRaidPicker');
    },

    viewLoot(){
      (this as any).$bvModal.show('viewLoot');
    },
    openEquipItems(){
      (this as any).$bvModal.show('equipments');
    },

    getTimeRemaining(){
      setInterval(() => {
        const eventTime = this.expectedFinishTime.getTime();
        const currentTime = new Date().getTime();
        const diffTime = eventTime - currentTime;

        const d = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        const h = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const m = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
        const s = Math.floor((diffTime % (1000 * 60)) / 1000);

        this.remainingTime = {days:d,hours:h,minutes:m,seconds:s};

      }, 1000);
    },

    getCleanCharacterName(id: string): string {
      return getCleanName(this.getCharacterName(id));
    },

    convertWeiToSkill(wei: string): string {
      return fromWeiEther(wei);
    },

    async joinRaidMethod() {
      const canUserAfford = await this.canUserAfford({payingAmount: toBN(this.joinCost)});
      if(!canUserAfford) {
        (this as any).$bvModal.show('warningModal');
        this.notifyError = (i18n.t('raid.errors.cannotAffordRaid').toString());
        return;
      }

      if (this.currentCharacterId < 0) {
        (this as any).$bvModal.show('warningModal');
        this.notifyError = (i18n.t('raid.errors.selection').toString());
        return;
      }

      const isRaidStarted = await this.isRaidStarted();
      if(!isRaidStarted) {
        (this as any).$bvModal.show('warningModal');
        this.notifyError = (i18n.t('raid.errors.raidNotStarted').toString());
        return;
      }
      const isCharacterRaiding = await this.isCharacterAlreadyRaiding(this.currentCharacterId);
      if(isCharacterRaiding) {
        (this as any).$bvModal.show('warningModal');
        this.notifyError = (i18n.t('raid.errors.lockedChar').toString());
        return;
      }
      /*const isWeaponRaiding = await this.isWeaponAlreadyRaiding(this.selectedWeaponId);
      if(isWeaponRaiding) {
        (this as any).$bvModal.show('warningModal');
        this.notifyError = (i18n.t('raid.errors.lockedWeapon').toString());
        return;
      }*/
      const haveEnoughEnergy = await this.haveEnoughEnergy(this.currentCharacterId);
      if(!haveEnoughEnergy) {
        (this as any).$bvModal.show('warningModal');
        this.notifyError = (i18n.t('raid.errors.notEnough').toString());
        return;
      }

      try {
        this.isJoiningRaid = true;
        this.isLoading = true;
        await this.joinRaid({ characterId: this.currentCharacterId});
      } catch (error) {
        console.error(error);
        (this as any).$dialog.notify.error(i18n.t('raid.errors.whoops'));
      } finally {
        this.isJoiningRaid = false;
        this.isLoading = false;
      }

      await this.getParticipatingCharacters();
      await this.getParticipatingWeapons();
    },

    async getParticipatingCharacters() {
      // gets the list of this player's raid locked characters
      // TODO store these?
      this.participatingCharacters = await this.fetchRaidingCharacters();
    },

    async getParticipatingWeapons() {
      // gets the list of this player's raid locked weapons
      // TODO store these?
      this.participatingWeapons = await this.fetchRaidingWeapons();
    },

    async isCharacterAlreadyRaiding(characterID: string){
      return await this.fetchIsCharacterRaiding({
        characterID
      });
    },

    async isWeaponAlreadyRaiding(weaponID: string): Promise<boolean> {
      return await this.fetchIsWeaponRaiding({
        weaponID
      });
    },

    async isRaidStarted() {
      return await this.fetchIsRaidStarted();
    },

    async haveEnoughEnergy(characterID: string) {
      return await this.fetchHaveEnoughEnergy({
        characterID
      });
    },

    async getRewardIndexes() {
      if(!this.raidIndex)
        return;
      let startIndex = +this.raidIndex-21; // one week worth
      if(startIndex < 0)
        startIndex = 0;
      const endIndex = this.raidIndex;

      this.rewardIndexes = await this.fetchRaidRewards({
        startIndex,
        endIndex
      });
    },

    async promptRewardClaim() {
      // should offer a popup here to pick which index to claim
      // if only one index, then claim instantly
      if (this.rewardIndexes !== null && this.rewardIndexes.length > 0) {
        if (this.rewardIndexes.length === 1) {
          try {
            this.isClaimingRewards = true;
            this.isLoading = true;
            await this.claimRewardIndex(this.rewardIndexes[0]);
          } finally {
            this.isClaimingRewards = false;
            this.isLoading = false;
          }
        } else {
          (this as any).$bvModal.show('rewardsRaidPicker');
        }
      }
    },

    async claimRewardIndex(rewardIndex: string) {
      this.bonuxXpCharacterNames = [];
      this.bonuxXpAmounts = [];
      const result = await this.claimRaidRewards({
        rewardIndex
      });
      const nfts: NftIdType[] = [];
      if(result.weapons) {
        result.weapons.forEach((x: Weapon) => {
          nfts.push({ type: 'weapon', id: x.tokenID });
        });
      }
      if(result.junks) {
        result.junks.forEach((x: Junk) => {
          nfts.push({ type: 'junk', id: x.tokenID });
        });
      }
      if(result.keyboxes) {
        result.keyboxes.forEach((x: Keybox) => {
          nfts.push({ type: 'keybox', id: x.tokenID });
        });
      }
      if(result.dustLb) {
        result.dustLb.forEach((x: DustLb) => {
          nfts.push({ type: 'dustLb', id: 0, amount: x.amount });
        });
      }
      if(result.dust4b) {
        result.dust4b.forEach((x: Dust4b) => {
          nfts.push({ type: 'dust4b', id: 0, amount: x.amount });
        });
      }
      if(result.dust5b) {
        result.dust5b.forEach((x: Dust5b) => {
          nfts.push({ type: 'dust5b', id: 0, amount: x.amount });
        });
      }
      if(result.bonusXp) {
        result.bonusXp.forEach((x: BonusXp) => {
          this.bonuxXpCharacterNames.push(this.getCharacterName(x.charID));
          this.bonuxXpAmounts.push(x.amount);
        });
      }

      this.rewards = nfts;
      this.spin = true;
      (this as any).$bvModal.show('rewardsModal');
      setTimeout(() => {
        this.spin = false;
      }, 10000);

      await this.fetchCharacters(this.ownedCharacterIds);
    },
    getBossName(): string {
      return getBossName(+this.raidIndex);
    },
    getBossImage(): string {
      return getBossArt(+this.raidIndex);
    },
    zeroPad(num: any, places: any) {
      const zero = places - num.toString().length + 1;
      return Array(+(zero > 0 && zero)).join('0') + num;
    },

    processRaidData() {
      const raidData = this.getRaidState();
      this.raidIndex = raidData.index;
      this.bossName = this.getBossName();
      this.raiderCount = +raidData.raiderCount;
      this.totalPower = +raidData.playerPower;
      this.expectedFinishTime = new Date(+raidData.expectedFinishTime * 1000);
      this.xpReward = raidData.xpReward;
      this.staminaCost = raidData.staminaCost;
      this.durabilityCost = raidData.durabilityCost;
      this.joinCost = raidData.joinSkill;
      this.raidStatus = raidData.status;
      this.bossPower = +raidData.bossPower;
      this.bossTrait = raidData.bossTrait;
      this.accountPower = raidData.accountPower;
    }
  },

  async mounted() {
    this.equippedWeaponId = await this.fetchCharacterWeapon(this.currentCharacterId);
    this.getTimeRemaining();
    const refreshRaidData = async () => {
      await (this as any).getRewardIndexes();
      await (this as any).fetchRaidState();
      (this as any).processRaidData();
      await (this as any).getParticipatingCharacters();
      await (this as any).getParticipatingWeapons();
      this.raidStarted = await this.fetchIsRaidStarted();
    };
    await refreshRaidData();
    this.raidStarted = await this.fetchIsRaidStarted();
    interval = window.setInterval(async () => {
      await refreshRaidData();
    }, 3000);
  },


  beforeDestroy() {
    clearInterval(interval);
  },

  watch: {
    async selectedCharacter(newValue){
      if (newValue) {
        this.equippedWeaponId = await this.fetchCharacterWeapon(this.currentCharacterId);
      }
    },
  },

  components: {
    CurrencyConverter,
    NftIcon,
    NftList,
  },
});
