









































import Vue from 'vue';
import {mapActions} from 'vuex';


interface StoreMappedActions {
  setCharacterMintValue(payload: { cents: number }): Promise<void>;

  setWeaponMintValue(payload: { cents: number }): Promise<void>;

  getCharacterMintValue(): Promise<number>;

  getWeaponMintValue(): Promise<number>;

  setHourlyIncome(payload: { hourlyIncome: number }): Promise<void>;
}

interface StoreMappedCombatActions {
  getFightXpGain(): Promise<number>;
  setFightXpGain(payload: { xpGain: number }): Promise<void>;
}

interface Data {
  newCharacterMintValue?: number;
  newWeaponMintValue?: number;
  currentCharacterMintValue?: number;
  currentWeaponMintValue?: number;
  currentFightXpGain?: number;
  newFightXpGain?: number;
  isLoading: boolean;
  currentHourlyIncome?: number;
  newHourlyIncome?: number;
}

export default Vue.extend({
  data() {
    return {
      newCharacterMintValue: undefined,
      newWeaponMintValue: undefined,
      currentCharacterMintValue: undefined,
      currentWeaponMintValue: undefined,
      currentFightXpGain: undefined,
      newFightXpGain: undefined,
      isLoading: false,
      currentHourlyIncome: undefined,
      newHourlyIncome: undefined
    } as Data;
  },

  computed: {
    setCharacterMintValueButtonDisabled(): boolean {
      return this.newCharacterMintValue === undefined
        || this.isLoading;
    },
    setWeaponMintValueButtonDisabled(): boolean {
      return this.newWeaponMintValue === undefined
        || this.isLoading;
    },
    setNewFightXpGainButtonDisabled(): boolean {
      return this.newFightXpGain === undefined
        || this.isLoading;
    },
    setHourlyIncomeDisabled(): boolean {
      return this.newHourlyIncome === undefined
        || this.isLoading;
    },
  },

  methods: {
    ...mapActions([
      'setCharacterMintValue',
      'setWeaponMintValue',
      'getCharacterMintValue',
      'getWeaponMintValue',
      'setHourlyIncome',
    ]) as StoreMappedActions,
    ...(mapActions('combat',
      [
        'getFightXpGain',
        'setFightXpGain',
      ]) as StoreMappedCombatActions),

    async setNewCharacterMintValue() {
      if (this.setCharacterMintValueButtonDisabled) return;
      try {
        this.isLoading = true;
        await this.setCharacterMintValue({cents: this.newCharacterMintValue!});
        await this.loadCurrentValues();
        this.newCharacterMintValue = undefined;
      } finally {
        this.isLoading = false;
      }
    },

    async setNewWeaponMintValue() {
      if (this.setWeaponMintValueButtonDisabled) return;
      try {
        this.isLoading = true;
        await this.setWeaponMintValue({cents: this.newWeaponMintValue!});
        await this.loadCurrentValues();
        this.newWeaponMintValue = undefined;
      } finally {
        this.isLoading = false;
      }
    },

    async setNewFightXpGain() {
      if (this.setNewFightXpGainButtonDisabled) return;
      try {
        this.isLoading = true;
        await this.setFightXpGain({xpGain: this.newFightXpGain!});
        await this.loadCurrentValues();
        this.newFightXpGain = undefined;
      } finally {
        this.isLoading = false;
      }
    },

    async setNewHourlyIncome() {
      if (this.setHourlyIncomeDisabled) return;
      try {
        this.isLoading = true;
        await this.setHourlyIncome({hourlyIncome: this.newHourlyIncome!});
        await this.loadCurrentValues();
        this.newHourlyIncome = undefined;
      } finally {
        this.isLoading = false;
      }
    },

    async loadCurrentValues() {
      try {
        this.isLoading = true;
        this.currentCharacterMintValue = await this.getCharacterMintValue();
        this.currentWeaponMintValue = await this.getWeaponMintValue();
        this.currentFightXpGain = await this.getFightXpGain();
      } finally {
        this.isLoading = false;
      }
    },
  },

  async mounted() {
    await this.loadCurrentValues();
  },
});
