



























































































































































































import Vue from 'vue';
import Bignumber from 'bignumber.js';
import { Accessors } from 'vue/types/options';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { toBN, fromWeiEther } from '../../utils/common';
import { formatDurationFromSeconds } from '@/utils/date-time';
import { BModal } from 'bootstrap-vue';
import i18n from '@/i18n';
import {TranslateResult} from 'vue-i18n';
import { ICharacter } from '@/interfaces';
import { getCleanName } from '@/rename-censor';
import ElementTrait from '@/components/smart/ElementTrait.vue';
import { SupportedProject } from '@/views/Treasury.vue';
import PartneredProject from '../PartneredProject.vue';
import UpdatePopup from '../UpdatePopup.vue';
import { userProofOfWork } from '@/utils/pow';

interface StoreMappedState {
  skillRewards: string;
  valorRewards: string;
  skillBalance: string;
  inGameOnlyFunds: string;
  waxBridgeWithdrawableBnb: string;
  waxBridgeTimeUntilLimitExpires: number;
  ownedCharacterIds: string[];
  xpRewards: Record<string, string>;
  balance: string;
}

interface StoreMappedTreasuryState {
  payoutCurrencyId: string;
  partnerProjectMultipliers: Record<number, string>;
  partnerProjectRatios: Record<number, string>;
  defaultSlippage: string;
}

interface StoreMappedGetters {
  ownCharacters: ICharacter[];
  getExchangeTransakUrl: string;
  getExchangeUrl: string;
  getBalanceUrl: string;
  getCurrencySymbol: string;
  availableBNB: string;
  currentCharacter: ICharacter | null;
  getCharacterName(id: number): string;
}

interface StoreMappedTreasuryGetters {
  getPartnerProjects: SupportedProject[];
}

interface StoreMappedActions {
  addMoreSkill(skillToAdd: string): Promise<void>;
  withdrawBnbFromWaxBridge(): Promise<void>;
  claimXpRewards(): Promise<void>;
}

interface StoreMappedTreasuryActions{
  fetchPartnerProjects(): Promise<void>;
  getPartnerProjectMultiplier(id: number): Promise<string>;
  claimPartnerToken(
    {id, skillAmount, currentMultiplier, slippage}:
    {id: number, skillAmount: string, currentMultiplier: string, slippage: string}): Promise<void>;
}

interface ICharacterClaimableExp{
  id: number;
  name: string;
  traitName: string;
  xp: any;
}

interface StoreMappedMutations{
  updatePayoutCurrencyId(id: string | number): Promise<void>;
}
enum ClaimStage {
  WaxBridge = 0,
  Stake = 1,
  Claim = 2,
  Summary = 3
}

export default Vue.extend({
  data(){
    return{
      ClaimStage,
      skillAmount: 0,
      valorAmount: 0,
      slippage: 0,
      isToggled: true
    };
  },
  computed: {
    ...mapState(['defaultAccount']),
    ...(mapState(['skillRewards', 'valorRewards', 'skillBalance', 'inGameOnlyFunds', 'waxBridgeWithdrawableBnb',
      'waxBridgeTimeUntilLimitExpires', 'ownedCharacterIds', 'xpRewards', 'balance']) as Accessors<StoreMappedState>),
    ...(mapState('treasury',
      ['payoutCurrencyId','partnerProjectMultipliers', 'partnerProjectRatios','defaultSlippage'])as Accessors<StoreMappedTreasuryState>),
    ...(mapGetters({
      availableBNB: 'waxBridgeAmountOfBnbThatCanBeWithdrawnDuringPeriod',
      getExchangeUrl: 'getExchangeUrl',
      getBalanceUrl: 'getBalanceUrl',
      getCurrencySymbol: 'getCurrencySymbol',
      getExchangeTransakUrl: 'getExchangeTransakUrl',
      ownCharacters: 'ownCharacters',
      getCharacterName: 'getCharacterName',
    }) as Accessors<StoreMappedGetters>),
    ...(mapGetters('treasury', ['getPartnerProjects']) as Accessors<StoreMappedTreasuryGetters>),
    isNoProjectAvailable(): boolean {
      this.choosePayoutCurrencyIfNotChosenBefore();
      return this.payoutCurrencyId === '-1';
    },
    nonFormattedRatio(): number {
      return this.selectedPartneredProject &&
        +toBN(1).dividedBy(toBN(this.partnerProjectRatios[+this.selectedPartneredProject.id]).dividedBy(toBN(2).exponentiatedBy(64))) || 1;
    },
    formattedMultiplier(): number {
      return this.selectedPartneredProject && +toBN(this.partnerProjectMultipliers[+this.selectedPartneredProject.id]).div(toBN(10).pow(18)).toFixed(4) || 1;
    },
    skillRewardNumber(): number {
      return +toBN(fromWeiEther(this.skillRewards.substr(0, this.skillRewards.length - 3) + '000'));
    },
    valorRewardNumber(): number {
      return +toBN(fromWeiEther(this.valorRewards.substr(0, this.valorRewards.length - 3) + '000'));
    },
    isSkillAmountValid(): boolean {
      return this.skillAmount <= this.skillRewardNumber && this.skillAmount > 0;
    },
    isValorAmountValid(): boolean {
      return this.valorAmount <= this.valorRewardNumber && this.valorAmount > 0;
    },
    canClaimTokens(): boolean {
      const areRewardsZeroOrLess = toBN(this.isValor ? this.valorRewards : this.skillRewards).lte(0);
      return areRewardsZeroOrLess;
    },
    canClaimSelectedProject(): boolean {
      if(this.selectedPartneredProject) {
        return toBN(+this.selectedPartneredProject.tokensClaimed).div(toBN(10).pow(18)).toNumber()
          < toBN(+this.selectedPartneredProject.tokenSupply).toNumber();
      }
      return false;
    },
    selectedPartneredProject(): SupportedProject | undefined {
      return this.getPartnerProjects.find(partnerProject => partnerProject.id.toString() === this.payoutCurrencyId.toString());
    },
    formattedXpRewardsBar(): ICharacterClaimableExp[] {
      const characterXp: ICharacterClaimableExp[] = [];
      this.xpRewardsForOwnedCharacters.map((xp, i) => {
        const character = this.ownCharacters[i];
        if (character) {
          characterXp.push({
            id: character.id,
            traitName: character.traitName,
            name: this.getCleanCharacterName(character.id),
            xp
          });
        }
      });
      return characterXp;
    },

    canClaimXp(): boolean {
      const areAllXpsZeroOrLess = this.xpRewardsForOwnedCharacters.every(xp => toBN(xp).lte(0));
      return !areAllXpsZeroOrLess;
    },
    xpRewardsForOwnedCharacters(): string[] {
      return this.ownedCharacterIds.map(charaId => this.xpRewards[charaId] || '0');
    },
    formattedTotalSkillBalance(): string {
      const skillBalance = fromWeiEther(Bignumber.sum(toBN(this.skillBalance), toBN(this.inGameOnlyFunds), toBN(this.skillRewards)));

      return `${toBN(skillBalance).toFixed(4)}`;
    },

    formattedSkillBalance(): string {
      const skillBalance = fromWeiEther(this.skillBalance);
      return `${toBN(skillBalance).toFixed(4)} SKILL`;
    },

    formattedBalance(): string {
      const balance = fromWeiEther(this.balance);
      return `${toBN(balance).toFixed(4)} `;
    },

    hasBnbAvailableToWithdraw(): boolean {
      return toBN(this.waxBridgeWithdrawableBnb).gt(0);
    },

    canWithdrawBnb(): boolean {
      return toBN(this.availableBNB).gt(0);
    },

    formattedBnbThatCanBeWithdrawn(): string {
      return this.formatBnb(this.availableBNB);
    },

    formattedTotalAvailableBnb(): string {
      return this.formatBnb(this.waxBridgeWithdrawableBnb);
    },

    durationUntilLimitPeriodOver(): string {
      return formatDurationFromSeconds(this.waxBridgeTimeUntilLimitExpires);
    },

    bnbClaimTooltip(): TranslateResult {
      if(!this.canWithdrawBnb) {
        return i18n.t('skillBalanceDisplay.reachedPortalLimit', {
          durationUntilLimitPeriodOver : this.durationUntilLimitPeriodOver,
          formattedTotalAvailableBnb : this.formattedTotalAvailableBnb,
        });
      }

      return i18n.t('skillBalanceDisplay.withdrawablePortal', {
        formattedBnbThatCanBeWithdrawn : this.formattedBnbThatCanBeWithdrawn,
        formattedTotalAvailableBnb : this.formattedTotalAvailableBnb,
      });
    },
    formattedInGameOnlyFunds(): string {
      const skillBalance = fromWeiEther(this.inGameOnlyFunds);
      return `${toBN(skillBalance).toFixed(4)} SKILL`;
    },
    totalSkillTooltipHtml() {
      const inGameOnlyFundsBalance = fromWeiEther(this.inGameOnlyFunds);
      const skillRewards = fromWeiEther(this.skillRewards);
      const skillBalance = fromWeiEther(this.skillBalance);

      let html =  toBN(skillBalance).toFixed(4) + ' SKILL';

      if(parseFloat(skillRewards) !== 0){
        html += i18n.t('skillBalanceDisplay.withdrawable') + toBN(skillRewards).toFixed(4) + ' SKILL';
      }

      if(parseFloat(inGameOnlyFundsBalance) !== 0){
        html += i18n.t('skillBalanceDisplay.igo') + toBN(inGameOnlyFundsBalance).toFixed(4) + ' SKILL';
      }

      return html;
    },
    hasInGameSkill(): boolean {
      const inGameOnlyFundsBalance = fromWeiEther(this.inGameOnlyFunds);
      return parseFloat(inGameOnlyFundsBalance) !== 0;
    },
    isValor(): boolean {
      return this.selectedPartneredProject?.isValor || false;
    }
  },

  methods: {
    ...mapActions(['fetchSkillBalance']),
    ...(mapActions(['addMoreSkill', 'withdrawBnbFromWaxBridge',
      'claimXpRewards']) as StoreMappedActions),
    ...(mapActions('treasury', ['fetchPartnerProjects',
      'getPartnerProjectMultiplier', 'claimPartnerToken']) as StoreMappedTreasuryActions),
    ...(mapMutations('treasury', ['updatePayoutCurrencyId']) as StoreMappedMutations),
    onBuySkill() {
      if(localStorage.getItem('currentChain') === 'SKALE') window.open(process.env.VUE_APP_DRAWBRIDGE_URL || 'https://drawbridge.cryptoblades.io/');
      else this.showModal();
    },
    async onClaimTokens() {
      if(this.payoutCurrencyId !== '-1') {
        const currentMultiplier = await this.getPartnerProjectMultiplier(+this.payoutCurrencyId);
        if(currentMultiplier === '0') {
          (this as any).$dialog.notify.error(i18n.t('ClaimRewardsBar.multiplierAtZero'));
          return;
        }
        await this.claimPartnerToken(
          {
            id: +this.payoutCurrencyId,
            skillAmount: toBN(this.isValor ? this.valorAmount : this.skillAmount).multipliedBy(toBN(10).pow(18)).toString(),
            currentMultiplier: toBN(currentMultiplier).toString(),
            slippage: toBN(this.slippage).multipliedBy(toBN(10).pow(16)).toString()
          }
        );
      }
    },
    getUnclaimed(): number | string{
      const skillRewards = fromWeiEther(this.skillRewards);
      if(parseFloat(skillRewards) === 0) return 0;
      return toBN(skillRewards).toFixed(4);
    },
    getUnclaimedValor(): number | string {
      const valorRewards = fromWeiEther(this.valorRewards);
      if(parseFloat(valorRewards) === 0) return 0;
      return toBN(valorRewards).toFixed(4);
    },
    async claimSkill(stage: ClaimStage) {
      if(stage === ClaimStage.WaxBridge) {
        (this.$refs['need-gas-modal'] as any).show();
      }
      if(stage === ClaimStage.Stake) {
        (this.$refs['stake-suggestion-modal'] as any).show();
      }
      if(stage === ClaimStage.Claim) {
        (this.$refs['stake-suggestion-modal'] as any).hide();
        (this.$refs['claim-confirmation-modal'] as any).show();
      }
      if(stage === ClaimStage.Summary) {
        await this.fetchPartnerProjects();
        this.skillAmount = this.skillRewardNumber;
        this.slippage = +toBN(this.defaultSlippage).dividedBy(toBN(10).pow(16));
        (this.$refs['claim-summary-modal'] as any).show();
      }
    },
    choosePayoutCurrencyIfNotChosenBefore() {
      const supportedProjects = this.getPartnerProjects;
      if(this.payoutCurrencyId === '-1' && supportedProjects.length !== 0) {
        this.updatePayoutCurrencyId(supportedProjects[0].id);
      }
    },
    setMaxSkillAmount(): void {
      this.skillAmount = this.skillRewardNumber;
    },
    setMaxValorAmount(): void {
      this.valorAmount = this.valorRewardNumber;
    },
    getCleanCharacterName(id: number): string {
      return getCleanName(this.getCharacterName(id));
    },
    async onClaimXp() {
      if(this.canClaimXp) {
        await this.claimXpRewards();
      }
    },
    formatBnb(bnb: string): string {
      const amount = fromWeiEther(bnb);
      return `${toBN(amount).toFixed(4)} BNB`;
    },
    async onWithdrawBNB() {
      if(!this.canWithdrawBnb) return;

      await this.withdrawBnbFromWaxBridge();
    },
    showModal() {
      (this.$refs['transak-buy'] as BModal).show();
    },
    async onClickBalance() {
      if(this.getCurrencySymbol === 'SFUEL') {
        (this.$refs['minting-sfuel-modal'] as any).show();
        try {
          await userProofOfWork({account: this.defaultAccount, network: 'mainnet'});
          await this.fetchSkillBalance();
        } catch(e) {
          console.error(e);
        } finally {
          (this.$refs['minting-sfuel-modal'] as any).hide();
        }
      } else {
        window.open(this.getBalanceUrl, '_blank');
      }
    }
  },
  components: {
    BModal,
    ElementTrait,
    PartneredProject,
    UpdatePopup,
  }
});
