






























import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import { getCharacterArt } from '@/character-arts-placeholder';
import {CharacterCosmetic} from '@/enums/CharacterCosmetic';


interface Data {
  powerAmount: number
}

interface StoreMappedActions {
  removeCharacterCosmetic(payload: {id: number}): Promise<void>
  changeCharacterCosmetic(payload: {id: number, cosmetic: string}): Promise<void>
}

export default Vue.extend({
  props: {
    availableSkins: {
      type: Array,
      default: ()=> []
    }
  },
  data(){
    return {
      powerAmount: 0,
      CharacterCosmetic,
    } as Data;
  },
  computed:{
    ...mapState(['characterCosmetics','currentCharacterId', 'characters']),
    ...mapGetters(['getCharacterPower']),
  },
  methods: {
    ...mapActions(['removeCharacterCosmetic', 'changeCharacterCosmetic']) as StoreMappedActions,
    getCharacterArt,
    async handleSkin(cosmetic: string): Promise<void>{
      if (+cosmetic === 0) {
        await this.removeCharacterCosmetic({ id: +this.currentCharacterId });
      } else {
        await this.changeCharacterCosmetic({ id: +this.currentCharacterId, cosmetic });
      }
      this.$emit('loadCosmeticsCount');
    },
  },
});
