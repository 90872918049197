

















































import Vue from 'vue';
import {PropType} from 'vue/types/options';
import { Quest } from '@/interfaces';
import { QuestTemplateType } from '@/enums/Quest';
import QuestRequirements from '@/components/smart/QuestRequirements.vue';
import QuestRewards from '@/components/smart/QuestRewards.vue';
import QuestActions from '@/components/smart/QuestActions.vue';
import {mapActions} from 'vuex';

interface StoreMappedActions {
  getQuestTemplates(payload: { tier: number }): Promise<Quest[]>;
}

interface Data {
  quests: Quest[];
  isLoading: boolean;
  isQuestActionLoading: boolean;
  showAds: boolean;
}

export default Vue.extend({
  components: {QuestRequirements, QuestRewards, QuestActions},
  props: {
    tier: {
      type: Number as PropType<number>,
      required: true,
    },
    deletable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    questTemplateType: {
      type: Number as PropType<QuestTemplateType>,
      required: true,
    },
  },

  data() {
    return {
      quests: [],
      isLoading: false,
      isQuestActionLoading: false,
      showAds: false,
    } as Data;
  },

  methods: {
    ...mapActions([
      'getQuestTemplates',
    ]) as StoreMappedActions,

    async fetchQuests() {
      try {
        this.isLoading = true;
        this.quests = await this.getQuestTemplates({tier: this.tier});
      } finally {
        this.isLoading = false;
      }
    },

    checkStorage() {
      if (process.env.NODE_ENV === 'development') this.showAds = false;
      else this.showAds = localStorage.getItem('show-ads') === 'true';
    }
  },

  watch: {
    $props: {
      async handler() {
        await this.fetchQuests();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.checkStorage();
  }
});
