






































































import Vue from 'vue';
import {PropType} from 'vue/types/options';
import {Nft, NftStatus} from '../../interfaces/Nft';
import {getCharacterArt} from '@/character-arts-placeholder';
import {mapActions, mapGetters} from 'vuex';
import {getCleanName} from '@/rename-censor';
import {
  RewardType,
  Quest,
  ReputationLevelRequirements,
  TierChances } from '@/interfaces';
import { Rarity, ReputationTier } from '@/enums/Quest';
interface StoreMappedActions {
  getQuestTierChances(payload: { tier: number }): Promise<TierChances>;
}

interface Data {
  tierChances: TierChances[];
  showReputationModal: boolean;
  isReputationInfoLoading: boolean;
}

export default Vue.extend({
  props: {
    character: {
      type: Object as PropType<Nft>,
      required: true,
    },
    quest: {
      type: Object as PropType<Quest>,
      required: true,
    },
    reputationLevelRequirements: {
      type: Object as PropType<ReputationLevelRequirements>,
    }
  },

  data() {
    return {
      tierChances: [] as TierChances[],
      showReputationModal: false,
      isReputationInfoLoading: false,
      RewardType,
      Rarity,
      ReputationTier,
      NftStatus,
    } as Data;
  },

  computed: {
    ...mapGetters([
      'getCharacterCosmetic',
      'getCharacterName',
    ]),

    getNftStatus(): string {
      if (this.character.status !== undefined && this.character.status in NftStatus) {
        return NftStatus[this.character.status];
      } else {
        return 'BUSY';
      }
    },

  },

  methods: {
    ...mapActions([
      'getQuestTierChances',
    ]) as StoreMappedActions,
    getCharacterArt,

    getCleanCharacterName(id: number) {
      return getCleanName(this.getCharacterName(id));
    },

    getReputationLevel(reputation: number) {
      if (!this.reputationLevelRequirements) return;
      if (reputation < this.reputationLevelRequirements.level2) {
        return ReputationTier.PEASANT;
      } else if (reputation < this.reputationLevelRequirements.level3) {
        return ReputationTier.TRADESMAN;
      } else if (reputation < this.reputationLevelRequirements.level4) {
        return ReputationTier.NOBLE;
      } else if (reputation < this.reputationLevelRequirements.level5) {
        return ReputationTier.KNIGHT;
      } else {
        return ReputationTier.KING;
      }
    },

    getReputationBreakpoint(reputation: number) {
      if (!this.reputationLevelRequirements) return;
      if (reputation < this.reputationLevelRequirements.level2) {
        return this.reputationLevelRequirements.level2;
      } else if (reputation < this.reputationLevelRequirements.level3) {
        return this.reputationLevelRequirements.level3;
      } else if (reputation < this.reputationLevelRequirements.level4) {
        return this.reputationLevelRequirements.level4;
      } else if (reputation < this.reputationLevelRequirements.level5) {
        return this.reputationLevelRequirements.level5;
      } else {
        return 0;
      }
    },

    async showReputationInfoModal() {
      try {
        this.showReputationModal = true;
        this.isReputationInfoLoading = true;
        this.tierChances[0] = await this.getQuestTierChances({tier: 0});
        this.tierChances[1] = await this.getQuestTierChances({tier: 1});
        this.tierChances[2] = await this.getQuestTierChances({tier: 2});
        this.tierChances[3] = await this.getQuestTierChances({tier: 3});
        this.tierChances[4] = await this.getQuestTierChances({tier: 4});
      } finally {
        this.isReputationInfoLoading = false;
      }
    },
  }


});
