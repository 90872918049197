export enum CharacterCosmetic {
  GRAYSCALE = 1,
  CONTRAST,
  SEPIA,
  INVERT,
  BLUR,
  FIRE_GLOW,
  EARTH_GLOW,
  LIGHTNING_GLOW,
  WATER_GLOW,
  RAINBOW_GLOW,
  DARK_GLOW,
  GHOST,
  POLICE,
  NEON_BORDER,
  DIAMOND_BORDER,
  GOLD_BORDER,
  SILVER_BORDER,
  BRONZE_BORDER,
}
