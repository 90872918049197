












































































































import Vue from 'vue';
import {getEnemyArt} from '../../../enemy-art';
import {CharacterTrait, ICharacter, IPowerData, ITarget} from '../../../interfaces';
import {fromWeiEther, toBN} from '../../../utils/common';
import BigNumber from 'bignumber.js';
import ModalContainer from '../../modals/ModalContainer.vue';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import gasp from 'gsap';
import i18n from '../../../i18n';
import { Accessors } from 'vue/types/options';

interface StoreMappedCombatActions {
  fetchCharacterStamina(characterId: number): Promise<void>;
  fetchTargets(
    { characterId }:
    { characterId: number }): Promise<void>;
  doEncounterPayNative(
    { characterId,
      targetString,
      fightMultiplier,
      offsetCost }:
    { characterId: number,
      targetString: number,
      fightMultiplier: number,
      offsetCost: BigNumber
    }): Promise<{
    isVictory: boolean,
    playerRoll: string,
    enemyRoll: string,
    xpGain: any,
    skillGain: any,
    bnbGasUsed: string,
  }>;
  fetchFightRewardSkill(): Promise<string>;
  fetchFightRewardValor(): Promise<string>;
  fetchFightRewardXp(): Promise<string[][]>;
  fetchExpectedPayoutForMonsterPower(
    { power }:
    { power: stringOrNumber }): Promise<string>;
  fetchHourlyAllowance(): Promise<string>;
  fetchHourlyPowerAverage(): Promise<string>;
  fetchHourlyPayPerFight(): Promise<string>;
  getCurrentSkillPrice(): Promise<string>;
  getNativeTokenPriceInUsd(): Promise<string>;
  getCombatTokenChargePercent(): Promise<string>;
  getFightXpGain(): Promise<number>;
}

interface StoreMappedActions{
  fetchCharacterWeapon(characterId: string | number): Promise<number>;
}

interface StoreMappedState {
  currentCharacterId: number,
  characters: ICharacter[],
}

interface StoreMappedGetters {
  ownCharacters: ICharacter[],
  currentCharacter: ICharacter,
  currentCharacterStamina: number,
  getPowerData(characterId: number): IPowerData,
}

interface StoreMappedCombatMutations {
  setIsInCombat(isInCombat: boolean): boolean,
}

interface StoreMappedCombatGetters {
  getTargetsByCharacterId(currentCharacterId: number): ITarget | any[],
  fightGasOffset: string,
  fightBaseline: string,
}

type stringOrNull = string | null;
type numberOrNull = number | null;
type stringOrNumber = string | number;

const CHANGE_RPC_ERROR_CODE = -32005;

interface ICombatData {
  powerData: IPowerData;
  error: stringOrNull;
  errorCode: numberOrNull;
  waitingResults: boolean;
  resultsAvailable: boolean;
  fightResults: any;
  intervalSeconds: any;
  intervalMinutes: any;
  timeSeconds: any;
  timeMinutes: any;
  fightXpGain: number;
  targetExpectedPayouts: string[];
  targetToFight: any;
  targetToFightIndex: any;
  minutesToNextAllowance: any;
  secondsToNextAllowance: any;
  lastAllowanceSkill: stringOrNull;
  nextAllowanceCounter: any;
  powerAvg: stringOrNull;
  expectedSkill: stringOrNull;
  activeCard: any;
  isToggled: boolean;
  gridStyling: string;
  counterInterval: any;
  equippedWeaponId: string | number;
  CHANGE_RPC_ERROR_CODE: number
}

export default Vue.extend({
  props: {
    fightMultiplier: {
      default: Number(localStorage.getItem('fightMultiplier')),
      required: true,
    },
    staminaPerFight: {
      default: 40,
      required: true
    }
  },
  data() {
    return {
      powerData: {},
      error: null,
      waitingResults: false,
      resultsAvailable: false,
      fightResults: null,
      intervalSeconds: null,
      intervalMinutes: null,
      timeSeconds: null,
      timeMinutes: null,
      fightXpGain: 32,
      targetExpectedPayouts: new Array(4),
      targetToFight: null,
      targetToFightIndex: null,
      minutesToNextAllowance: null,
      secondsToNextAllowance: null,
      lastAllowanceSkill: null,
      nextAllowanceCounter: null,
      powerAvg: null,
      expectedSkill: null,
      activeCard: null,
      isToggled: false,
      gridStyling:'justify-content:flex-start; gap:2.5vw',
      counterInterval: null,
      equippedWeaponId: '',
      errorCode: null,
      CHANGE_RPC_ERROR_CODE
    } as ICombatData;
  },
  async mounted() {
    this.powerData = this.getPowerData(this.currentCharacterId);
    this.fightXpGain = await this.getFightXpGain();
    this.equippedWeaponId = await this.fetchCharacterWeapon(this.currentCharacterId);
    if(this.currentCharacterId !== null && this.currentCharacterId !== undefined) {
      await this.fetchTargets({ characterId: this.currentCharacterId });
    }
  },
  created() {
    this.intervalSeconds = setInterval(() => (this.timeSeconds = new Date().getSeconds()), 5000);
    this.intervalMinutes = setInterval(() => (this.timeMinutes = new Date().getMinutes()), 20000);
    this.counterInterval = setInterval(async () => {
      await this.getExpectedPayout();
    }, 1000);

  },
  beforeDestroy() {
    clearInterval(this.intervalSeconds);
    clearInterval(this.intervalMinutes);
    clearInterval(this.counterInterval);
  },
  computed: {
    ...(mapState([
      'currentCharacterId',
      'characters',
    ]) as Accessors<StoreMappedState>),
    ...(mapGetters([
      'ownCharacters',
      'currentCharacter',
      'currentCharacterStamina',
      'getPowerData',
    ]) as Accessors<StoreMappedGetters>),
    ...(mapGetters('combat', [
      'getTargetsByCharacterId',
      'fightGasOffset',
      'fightBaseline',
    ]) as Accessors<StoreMappedCombatGetters>),

    targets(): any[] | ITarget {
      return this.getTargetsByCharacterId(this.currentCharacterId);
    },

    isLoadingTargets(): boolean {
      const targets = this.targets as any[];
      return targets.length === 0 && !!this.currentCharacterId;
    },

    selections(): any[] {
      return [this.currentCharacterId];
    },

    updateResults(): any[] {
      return [this.fightResults, this.error, this.errorCode];
    },

    isGenesisCharacter(): boolean {
      return this.currentCharacter.version === 0;
    },

    selectedCharacter(): ICharacter{
      return this.characters[this.currentCharacterId];
    }
  },

  watch: {
    async selections([characterId]) {
      this.powerData = this.getPowerData(characterId);
      if(this.powerData) {
        await this.fetchTargets({ characterId });
      }
    },

    async targets() {
      await this.getExpectedPayouts();
    },

    async updateResults([fightResults, error]) {
      this.resultsAvailable = fightResults !== null;
      this.waitingResults = fightResults === null && error === null;
      this.setIsInCombat(this.waitingResults);
      if (this.resultsAvailable && error === null) (this as any).$bvModal.show('modal-info');
    },

    async selectedCharacter(newValue){
      if (newValue) {
        this.equippedWeaponId = await this.fetchCharacterWeapon(this.currentCharacterId);
      }
    },
    error(newValue) {
      this.$emit('error', newValue);
    },
    errorCode(newValue) {
      this.$emit('errorCode', newValue);
    }
  },

  methods: {
    ...(mapActions('combat',
      [
        'fetchTargets',
        'doEncounterPayNative',
        'fetchFightRewardSkill',
        'fetchFightRewardValor',
        'fetchFightRewardXp',
        'fetchExpectedPayoutForMonsterPower',
        'fetchHourlyAllowance',
        'fetchHourlyPowerAverage',
        'fetchHourlyPayPerFight',
        'getCurrentSkillPrice',
        'getNativeTokenPriceInUsd',
        'getCombatTokenChargePercent',
        'fetchCharacterStamina',
        'getFightXpGain',
      ]) as StoreMappedCombatActions),
    ...(mapMutations('combat', ['setIsInCombat']) as StoreMappedCombatMutations),
    ...mapActions(['fetchCharacterWeapon']) as StoreMappedActions,
    getEnemyArt,
    charHasStamina(){
      return this.currentCharacterStamina >= this.staminaPerFight;
    },
    getCharacterTrait(trait: CharacterTrait) {
      return CharacterTrait[trait];
    },
    getWinChance(enemyPower: number, enemyElement: number) {
      const totalMultipliedPower = this.powerData.pvePower[enemyElement];
      const playerMin = totalMultipliedPower * 0.9;
      const playerMax = totalMultipliedPower * 1.1;
      const playerRange = playerMax - playerMin;
      const enemyMin = enemyPower * 0.9;
      const enemyMax = enemyPower * 1.1;
      const enemyRange = enemyMax - enemyMin;
      let rollingTotal = 0;
      // shortcut: if it is impossible for one side to win, just say so
      if (playerMin > enemyMax) return i18n.t('combat.winChances.veryLikely');
      if (playerMax < enemyMin) i18n.t('combat.winChances.unlikely');

      // case 1: player power is higher than enemy power
      if (playerMin >= enemyMin) {
        // case 1: enemy roll is lower than player's minimum
        rollingTotal = (playerMin - enemyMin) / enemyRange;
        // case 2: 1 is not true, and player roll is higher than enemy maximum
        rollingTotal += (1 - rollingTotal) * ((playerMax - enemyMax) / playerRange);
        // case 3: 1 and 2 are not true, both values are in the overlap range. Since values are basically continuous, we assume 50%
        rollingTotal += (1 - rollingTotal) * 0.5;
      } // otherwise, enemy power is higher
      else {
        // case 1: player rolls below enemy minimum
        rollingTotal = (enemyMin - playerMin) / playerRange;
        // case 2: enemy rolls above player maximum
        rollingTotal += (1 - rollingTotal) * ((enemyMax - playerMax) / enemyRange);
        // case 3: 1 and 2 are not true, both values are in the overlap range
        rollingTotal += (1 - rollingTotal) * 0.5;
        //since this is chance the enemy wins, we negate it
        rollingTotal = 1 - rollingTotal;
      }
      if (rollingTotal <= 0.3) return i18n.t('combat.winChances.unlikely');
      if (rollingTotal <= 0.5) return i18n.t('combat.winChances.possible');
      if (rollingTotal <= 0.7) return i18n.t('combat.winChances.likely');
      return i18n.t('combat.winChances.veryLikely');
    },
    getElementAdvantage(playerElement: number, enemyElement: number) {
      if ((playerElement + 1) % 4 === enemyElement) return 1;
      if ((enemyElement + 1) % 4 === playerElement) return -1;
      return 0;
    },

    // for enemy card animaton
    enter(el: HTMLElement, done: any) {
      gasp.to(el, {
        opacity: 1,
        y: 0,
        duration: 0.5,
        onComplete: done,
        delay: el.dataset.index as any * 0.1
      });
    },
    beforeEnter(el: HTMLElement){
      el.style.opacity = '0';
      el.style.transform = 'translateY(100px)';
    },
    // -------------------

    hideBottomMenu(bol: boolean){
      this.isToggled = bol;
    },

    async getExpectedPayout() {
      this.powerAvg = await this.fetchHourlyPowerAverage();
      this.expectedSkill = fromWeiEther(await this.fetchHourlyPayPerFight());
    },
    async getHourlyAllowance(){
      const fetchHourlyAllowance = await this.fetchHourlyAllowance();
      this.lastAllowanceSkill = this.formattedSkill(fetchHourlyAllowance);
    },
    async onClickEncounter(targetToFight: ITarget, targetIndex: number) {
      // this.$bvModal.show('waitingForResult');
      if(this.targetExpectedPayouts[targetIndex] === '0'){
        this.targetToFight = targetToFight;
        this.targetToFightIndex = targetIndex;
        await this.getHourlyAllowance();
        (this.$refs['no-skill-warning-modal'] as any).show();
      }
      else{
        this.fightTarget(targetToFight, targetIndex);
      }
    },

    async fightTarget(targetToFight: ITarget, targetIndex: number){
      if (this.currentCharacterId === null) {
        return;
      }
      this.waitingResults = true;

      // Force a quick refresh of targets
      await this.fetchTargets({ characterId: this.currentCharacterId });
      // If the targets list no longer contains the chosen target, return so a new target can be chosen
      const targets = this.targets as any[];
      if (targets[targetIndex].original !== targetToFight.original) {
        this.waitingResults = false;
        return;
      }

      this.fightResults = null;
      this.error = null;
      this.setIsInCombat(this.waitingResults);
      try {
        const targetPower = targetToFight.power;
        const expectedPayoutWei = new BigNumber(await this.fetchExpectedPayoutForMonsterPower({ power: targetPower }));

        const nativeTokenPriceUsd = new BigNumber(await this.getNativeTokenPriceInUsd());
        const skillPriceUsd = new BigNumber(await this.getCurrentSkillPrice());
        const tokenChargePercentage = (await this.getCombatTokenChargePercent());

        const offsetToPayInNativeToken = (
          expectedPayoutWei.multipliedBy(tokenChargePercentage).div(100).multipliedBy(skillPriceUsd.gt(0) ? skillPriceUsd : 1)
        ).div(nativeTokenPriceUsd.gt(0) ? nativeTokenPriceUsd : 1).integerValue(BigNumber.ROUND_DOWN);

        this.fightResults = await this.doEncounterPayNative({
          characterId: this.currentCharacterId,
          targetString: targetIndex,
          fightMultiplier: this.fightMultiplier,
          offsetCost: offsetToPayInNativeToken
        });

        await this.fetchFightRewardSkill();
        await this.fetchFightRewardValor();
        await this.fetchFightRewardXp();

        await this.fetchCharacterStamina(this.currentCharacterId);

        this.error = null;
        this.errorCode = null;
      } catch (error: any) {
        console.error(error);
        this.error = error.message;
        if (this.error?.includes(this.CHANGE_RPC_ERROR_CODE.toString())) {
          this.errorCode = this.CHANGE_RPC_ERROR_CODE;
        }
      }
    },

    formattedSkill(skill: stringOrNumber) {
      const skillBalance = fromWeiEther(skill.toString());
      return `${toBN(skillBalance).toFixed(6)} ${this.isGenesisCharacter ? 'SKILL' : 'VALOR'}`;
    },

    getPotentialXp(targetToFight: ITarget) {
      const totalPower = this.powerData.pvePower[4]; // using base power
      //Formula taken from getXpGainForFight funtion of cryptoblades.sol
      return Math.floor((targetToFight.power / totalPower) * this.fightXpGain) * this.fightMultiplier;
    },

    async getExpectedPayouts() {
      if(!this.targets) return;
      const expectedPayouts = new Array(4);
      const targets = this.targets as ITarget[];
      for(let i = 0; i < targets.length; i++) {
        const expectedPayout = await this.fetchExpectedPayoutForMonsterPower({ power: targets[i].power });
        expectedPayouts[i] = expectedPayout;
      }
      this.targetExpectedPayouts = expectedPayouts;
    },

    changeColorChange(stat: string){
      let bgColor;
      if(stat.toUpperCase() === 'UNLIKELY'){
        bgColor =  'background-color: #B10000 !important';
      }else if(stat.toUpperCase() === 'VERY LIKELY'){
        bgColor =  'background-color: #7BA224 !important';
      }else if(stat.toUpperCase() === 'POSSIBLE'){
        bgColor =  'background-color: #D16100 !important';
      }else{
        bgColor = 'background-color: #ff7700 !important';
      }
      return bgColor;
    }
  },

  components: {
    ModalContainer
  },
});
