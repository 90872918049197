












import Vue from 'vue';
import {PropType} from 'vue/types/options';
import { Quest } from '@/interfaces';
import { QuestItemType } from '@/enums/Quest';
import QuestComponentIcon from './QuestComponentIcon.vue';

export default Vue.extend({
  components: {QuestComponentIcon},
  props: {
    quest: {
      type: Object as PropType<Quest>,
      required: true,
    },
  },

  data() {
    return {
      QuestItemType,
    };
  },
});
