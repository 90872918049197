




















































































































































































































































































import Vue from 'vue';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { BModal } from 'bootstrap-vue';
import Events from '@/events';

import SkinsTab from '@/components/smart/CharacterTabs/SkinsTab.vue';
import OptionsTab from '@/components/smart/CharacterTabs/OptionsTab.vue';
import UpgradeTab from '@/components/smart/CharacterTabs/UpgradeTab.vue';
import EquipmentTab from '@/components/smart/CharacterTabs/EquipmentTab.vue';
import { getCharacterArt } from '@/character-arts-placeholder';
import { Quest, ReputationLevelRequirements } from '@/interfaces';
import { ReputationTier } from '@/enums/Quest';
import { CharacterTrait, ICharacter, RequiredXp, IStoredPowerData } from '@/interfaces';
import { isValidWeb3Address } from '@/utils/common';


interface Skin {
  id: number;
  name?: string;
  amount: number;
}


interface Data {
  ReputationTier: typeof ReputationTier;
  reputationLevelRequirements?: ReputationLevelRequirements;
  genesisSoulBalance: number;
  nonGenesisSoulBalance: number;
  powerAmount: number;
  haveRename: number;
  targetTrait: string;
  haveChangeTraitFire: number;
  haveChangeTraitEarth: number;
  haveChangeTraitWater: number;
  haveChangeTraitLightning: number;
  quest: null | Quest;
  haveCharacterCosmetics: Skin[];
  isSending: boolean;
  resultMsg: string;
  receiverAddress: string;
  newName: string;
  isTransferringNonGenesis: boolean;
  soulAmountToTransfer: number;
  hasCharacterPowerDataChanged: boolean;
  characterEquipmentVersion: number,
  isCharacterEquipmentVersionMatch: boolean,
  isRecalculateEquipmentLoading: boolean,
  updatedEquippedCharacterPower: number
}

interface StoreMappedActions {
  getReputationLevelRequirements(): Promise<ReputationLevelRequirements>;
  fetchGenesisSoulBalance(): Promise<number>;
  fetchNonGenesisSoulBalance(): Promise<number>;
  getCharacterQuestData(payload: { characterId: string | number }): Promise<Quest>;
  fetchTotalRenameTags(): Promise<number>;
  fetchTotalCharacterFireTraitChanges(): Promise<number>;
  fetchTotalCharacterEarthTraitChanges(): Promise<number>;
  fetchTotalCharacterWaterTraitChanges(): Promise<number>;
  fetchTotalCharacterLightningTraitChanges(): Promise<number>;
  changeCharacterTraitFire(payload: {id: string}): Promise<void>;
  changeCharacterTraitWater(payload: {id: string}): Promise<void>;
  changeCharacterTraitEarth(payload: {id: string}): Promise<void>;
  changeCharacterTraitLightning(payload: {id: string}): Promise<void>;
  renameCharacter(payload: {id: string; name: string;}): Promise<void>;
  fetchOwnedCharacterCosmetics(payload: {cosmetic: string}): Promise<number>;
  getCharacterStamina(payload: string): Promise<number>;
  transferNFT(payload: {
    nftId: number;
    receiverAddress: string;
    nftType: string;
  }): Promise<void>;
  sendToGarrison(id: string): Promise<void>;
  transferSoul(payload: {targetAddress: string, soulAmount: number}): Promise<void>;
  transferNonGenesisSoul(payload: {targetAddress: string, soulAmount: number}): Promise<void>;
  getEquippedCharacterPower(payload: { characterId: string | number }): Promise<number>,
  getEquippedCharacterPowerStoredPowerData(payload: { characterId: string | number }): Promise<number>,
  getEquippedCharacterPowerStoredData(payload: { characterId: string | number }): Promise<IStoredPowerData>,
  recalculateCharacterEquipmentPower(payload: { characterId: string | number }): Promise<void>,
  getCharacterEquipmentCurrentVersion(payload: { characterId: string | number }): Promise<number>,
  getEquipmentCurrentVersion(): Promise<number>
}

export default Vue.extend({
  components: {EquipmentTab, UpgradeTab, OptionsTab, SkinsTab },
  data(): Data{
    return {   ReputationTier,
      reputationLevelRequirements: undefined,
      genesisSoulBalance: 0,
      nonGenesisSoulBalance: 0,
      powerAmount: 0,
      haveRename: 0,
      targetTrait: '',
      haveChangeTraitFire: 0,
      haveChangeTraitEarth: 0,
      haveChangeTraitWater: 0,
      haveChangeTraitLightning: 0,
      quest: null,
      haveCharacterCosmetics: [{
        id: 0,
        name: 'Default',
        amount: 1
      }],
      isSending: false,
      resultMsg: '',
      receiverAddress: '',
      newName: '',
      isTransferringNonGenesis: false,
      soulAmountToTransfer: 0,
      hasCharacterPowerDataChanged: false,
      characterEquipmentVersion: -1,
      isCharacterEquipmentVersionMatch: true,
      isRecalculateEquipmentLoading: false,
      updatedEquippedCharacterPower: 0
    };
  },
  computed: {
    ...mapState([
      'characterCosmetics',
      'currentCharacterId',
      'characters',
      'characterStaminas',
      'ownedGarrisonCharacterIds',
      'ownedShieldIds',
    ]),
    ...mapGetters([
      'getCharacterName',
      'getCharacterStamina',
      'getCharacterPower',
      'getCharacterEquippedPower'
    ]),
    availableTraits(): string[] {
      const availableTraits = [];
      if(this.haveChangeTraitFire > 0) {
        availableTraits.push('Fire');
      }
      if(this.haveChangeTraitEarth > 0) {
        availableTraits.push('Earth');
      }
      if(this.haveChangeTraitWater > 0) {
        availableTraits.push('Water');
      }
      if(this.haveChangeTraitLightning > 0) {
        availableTraits.push('Lightning');
      }

      return availableTraits;
    },
    totalTraits(): number {
      return +this.haveChangeTraitFire + +this.haveChangeTraitEarth + +this.haveChangeTraitWater + +this.haveChangeTraitLightning;
    },
    reputation(): number {
      return this.quest?.reputation ?? 0;
    },
    characterTrait(): string {
      const characterWithId = this.characters[this.currentCharacterId];
      return CharacterTrait[characterWithId?.trait] ?? '';
    },
    selectedCharacter(): ICharacter{
      return this.characters[this.currentCharacterId];
    },
    characterStamina(): number {
      return this.ownedGarrisonCharacterIds.includes(this.currentCharacterId)
        ? this.characterStaminas[this.currentCharacterId]
        : this.timestampToStamina(this.characters[this.currentCharacterId]?.staminaTimestamp ?? 0);
    },
    characterExp(): number {
      return this.characters[this.currentCharacterId]?.xp ?? 0;
    },
    expDiff(): string {
      const result = RequiredXp(this.characters[this.currentCharacterId]?.level + 1 ?? 0) - (this.characters[this.currentCharacterId]?.xp ?? 0);
      return new Intl.NumberFormat().format(result);
    },
    expPercentage(): number {
      const total = ((this.characters[this.currentCharacterId]?.xp ?? 0)/ RequiredXp(this.characters[this.currentCharacterId]?.level ?? 0) ?? 1) * 100;
      return total;
    },
    availableSkins(): Skin[] {
      const availableSkins = [];

      const currentCosmetic = this.characterCosmetics[this.currentCharacterId];

      if (+currentCosmetic > 0) {
        availableSkins.push({
          id: currentCosmetic,
          amount: 1
        });
      }

      for(let i = 0; i < 19; i++) {
        if(+this.haveCharacterCosmetics[i]?.amount > 0 && !availableSkins.find(item=> +item.id === i)) {
          availableSkins.push(this.haveCharacterCosmetics[i]);
        }
      }
      return availableSkins;
    },
    characterLvl(): number {
      return this.characters[this.currentCharacterId]?.level + 1 ?? 1;
    },
    totalCharacterPower(): number {
      return this.getCharacterPower(this.currentCharacterId);
    },
    totalCharacterEquippedPower(): number {
      return this.getCharacterEquippedPower(this.currentCharacterId);
    },
    isGenesisCharacter(): boolean {
      return this.characters[this.currentCharacterId]?.version === 0;
    }
  },
  methods: {
    ...mapActions([
      'getReputationLevelRequirements',
      'getCharacterQuestData',
      'fetchGenesisSoulBalance',
      'fetchNonGenesisSoulBalance',
      'fetchTotalCharacterFireTraitChanges',
      'fetchTotalCharacterEarthTraitChanges',
      'fetchTotalCharacterWaterTraitChanges',
      'fetchTotalCharacterLightningTraitChanges',
      'changeCharacterTraitFire',
      'changeCharacterTraitWater',
      'changeCharacterTraitEarth',
      'changeCharacterTraitLightning',
      'transferNFT',
      'renameCharacter',
      'sendToGarrison',
      'fetchOwnedCharacterCosmetics',
      'fetchTotalRenameTags',
      'transferSoul',
      'transferNonGenesisSoul',
      'getEquippedCharacterPower',
      'getEquippedCharacterPowerStoredPowerData',
      'getEquippedCharacterPowerStoredData',
      'recalculateCharacterEquipmentPower',
      'getCharacterEquipmentCurrentVersion',
      'getEquipmentCurrentVersion'
    ]) as StoreMappedActions,
    ...mapMutations([
      'updateCharacterPower',
      'updateCharacterEquippedPower'
    ]),
    getCharacterArt,
    RequiredXp,
    removeErrors(){
      this.resultMsg = '';
    },
    async onSoulTransferConfirm() {
      if(!isValidWeb3Address(this.receiverAddress) || this.soulAmountToTransfer === 0) return;
      this.isSending = true;
      if(this.isTransferringNonGenesis) {
        await this.transferNonGenesisSoul({ targetAddress: this.receiverAddress, soulAmount: this.soulAmountToTransfer });
        this.nonGenesisSoulBalance = +(await this.fetchNonGenesisSoulBalance());
      }
      else {
        await this.transferSoul({ targetAddress: this.receiverAddress, soulAmount: this.soulAmountToTransfer });
        this.genesisSoulBalance = +(await this.fetchGenesisSoulBalance());
      }
      this.isSending = false;
      this.soulAmountToTransfer = 0;
      this.receiverAddress = '';
      (this.$refs['character-transfer-soul-modal'] as BModal).hide();
    },
    currentTraitTotal() {
      switch(this.targetTrait){
      case 'Fire':
        return +this.haveChangeTraitFire;
      case 'Earth':
        return +this.haveChangeTraitEarth;
      case 'Water':
        return +this.haveChangeTraitWater;
      case 'Lightning':
        return +this.haveChangeTraitLightning;
      default:
        return 0;
      }
    },
    handleMax(){
      if(this.isTransferringNonGenesis) {
        this.soulAmountToTransfer = this.nonGenesisSoulBalance;
      }
      else {
        this.soulAmountToTransfer = this.genesisSoulBalance;
      }
    },
    openTransferSoulModal(){
      (this.$refs['character-transfer-soul-modal'] as BModal).show();
    },
    openTransferModal(){
      (this.$refs['character-transfer-modal'] as BModal).show();
    },
    openChangeTrait(){
      (this.$refs['character-change-trait-modal'] as BModal).show();
    },
    async openChangeNameModal() {
      this.haveRename = await this.fetchTotalRenameTags();
      (this.$refs['character-change-name-modal'] as BModal).show();
    },
    getReputationLevel(reputation: number) {
      if (!this.reputationLevelRequirements) return;
      if (reputation < this.reputationLevelRequirements.level2) {
        return ReputationTier.PEASANT;
      } else if (reputation < this.reputationLevelRequirements.level3) {
        return ReputationTier.TRADESMAN;
      } else if (reputation < this.reputationLevelRequirements.level4) {
        return ReputationTier.NOBLE;
      } else if (reputation < this.reputationLevelRequirements.level5) {
        return ReputationTier.KNIGHT;
      } else {
        return ReputationTier.KING;
      }
    },
    timestampToStamina(timestamp: number): number {
      if(timestamp > Math.floor(Date.now()/1000)) return 0;
      return +Math.min((Math.floor(Date.now()/1000) - timestamp) / 300, 200).toFixed(0);
    },
    async checkCharacterEquipmentVersion() {
      const equipmentVersion = await this.getEquipmentCurrentVersion();
      const characterEquipmentVersion = await this.getCharacterEquipmentCurrentVersion(this.currentCharacterId);

      this.characterEquipmentVersion = +characterEquipmentVersion;
      this.isCharacterEquipmentVersionMatch = +equipmentVersion === +characterEquipmentVersion;
    },
    async reCheckEquippedCharacterPowerData() {
      const powerData = await this.getEquippedCharacterPower(this.currentCharacterId);
      const powerStoredData = await this.getEquippedCharacterPowerStoredPowerData(this.currentCharacterId);
      const powerStored = await this.getEquippedCharacterPowerStoredData(this.currentCharacterId);

      this.updateCharacterEquippedPower({
        characterId: this.currentCharacterId,
        power: +powerStored[0][4]
      });

      this.updatedEquippedCharacterPower = +powerData;
      this.hasCharacterPowerDataChanged = +powerData !== +powerStoredData;
    },
    async refreshData(){
      this.reputationLevelRequirements =  await this.getReputationLevelRequirements();
      this.genesisSoulBalance = +(await this.fetchGenesisSoulBalance());
      this.nonGenesisSoulBalance = +(await this.fetchNonGenesisSoulBalance());
    },
    async fetchCharacterQuestData(){
      this.quest = await this.getCharacterQuestData({characterId: this.currentCharacterId});
    },
    async loadConsumablesCount() {
      this.haveChangeTraitFire = await this.fetchTotalCharacterFireTraitChanges();
      this.haveChangeTraitEarth = await this.fetchTotalCharacterEarthTraitChanges();
      this.haveChangeTraitWater = await this.fetchTotalCharacterWaterTraitChanges();
      this.haveChangeTraitLightning = await this.fetchTotalCharacterLightningTraitChanges();
    },
    async loadCosmeticsCount() {
      for(let i = 1; i < 19; i++) {
        const amount = await this.fetchOwnedCharacterCosmetics({cosmetic: i.toString()});
        this.haveCharacterCosmetics.push({
          id: i,
          amount: +amount
        });
      }
    },
    async changeCharacterTraitCall(bvModalEvt: Event) {
      if(!this.targetTrait) {
        bvModalEvt.preventDefault();
      }
      this.isSending = true;
      try{
        switch(this.targetTrait) {
        case 'Fire':
          await this.changeCharacterTraitFire({ id: this.currentCharacterId });
          this.haveChangeTraitFire = await this.fetchTotalCharacterFireTraitChanges();
          break;
        case 'Earth' :
          await this.changeCharacterTraitEarth({ id: this.currentCharacterId });
          this.haveChangeTraitEarth = await this.fetchTotalCharacterEarthTraitChanges();
          break;
        case 'Water':
          await this.changeCharacterTraitWater({ id: this.currentCharacterId });
          this.haveChangeTraitWater = await this.fetchTotalCharacterWaterTraitChanges();
          break;
        case 'Lightning':
          await this.changeCharacterTraitLightning({ id: this.currentCharacterId });
          this.haveChangeTraitLightning = await this.fetchTotalCharacterLightningTraitChanges();
          break;
        }
      }catch(e: any){
        if(e.code as number === 4001) this.resultMsg = (this as any).$t('Character.cancelledTransaction');
        else this.resultMsg = (this as any).$t('Character.changeTraitError');
        this.isSending = false;
        return;
      }
      this.isSending = false;
      this.resultMsg = '';
      this.targetTrait = '';
      (this.$refs['character-change-trait-modal'] as BModal).hide();
    },
    async transfer(bvModalEvt: Event) {
      bvModalEvt.preventDefault();
      this.isSending = true;
      try {
        await this.transferNFT({
          nftId: this.currentCharacterId,
          receiverAddress: this.receiverAddress,
          nftType: 'character'
        });
      }
      catch(e: any) {
        if(e.code as number === 4001) this.resultMsg = 'You cancelled the transaction.';
        else this.resultMsg = 'Error while transferring your ' + 'character' + ' to ' + this.receiverAddress;
      }
      this.isSending = false;
    },
    async renameCharacterCall(bvModalEvt: Event) {
      if (!this.haveRename) {
        (this.$refs['character-change-name-modal'] as BModal).hide();
        return;
      }
      if(this.newName.length < 2 || this.newName.length > 24){
        bvModalEvt.preventDefault();
        return;
      }

      await this.renameCharacter({id: this.currentCharacterId, name: this.newName.trim()});
      this.haveRename = await this.fetchTotalRenameTags();
      (this.$refs['character-change-name-modal'] as BModal).hide();
    },
    async onSendToGarrison() {
      await this.sendToGarrison(this.currentCharacterId);
    },
    async onClickRecalculate() {
      if(!this.isRecalculateEquipmentLoading) {
        this.isRecalculateEquipmentLoading = true;
        await this.recalculateCharacterEquipmentPower(this.currentCharacterId);
        this.updateCharacterEquippedPower({
          characterId: this.currentCharacterId,
          power: +this.updatedEquippedCharacterPower
        });

        this.isRecalculateEquipmentLoading = false;
      }
    },
    async refreshCharacterEquipmentData() {
      await this.reCheckEquippedCharacterPowerData();
      await this.checkCharacterEquipmentVersion();
    }
  },
  watch: {
    async selectedCharacter(newValue){
      if (newValue) {
        await this.fetchCharacterQuestData();
        await this.refreshData();
        await this.refreshCharacterEquipmentData();
      }
    },
  },
  async mounted(){
    await this.refreshData();
    await this.fetchCharacterQuestData();
    await this.loadConsumablesCount();
    await this.refreshCharacterEquipmentData();

    Events.$on('weaponChanged', async () => {
      console.log('weaponChanged');
      await this.refreshCharacterEquipmentData();
    });
  },
});
