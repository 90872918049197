






















import Vue from 'vue';
import {Accessors, PropType} from 'vue/types/options';
import QuestCharacter from '@/components/smart/QuestCharacter.vue';
import QuestRequirements from '@/components/smart/QuestRequirements.vue';
import QuestRewards from '@/components/smart/QuestRewards.vue';
import QuestActions from '@/components/smart/QuestActions.vue';
import {mapActions, mapGetters, mapState} from 'vuex';
import {
  Quest,
  ReputationLevelRequirements,
  RewardType } from '@/interfaces';
import { Rarity, QuestTemplateType } from '@/enums/Quest';
import {Nft, NftStatus} from '@/interfaces/Nft';

interface StoreMappedActions {
  getCharacterQuestData(payload: { characterId: string | number }): Promise<Quest>;

  getCharacterBusyStatus(payload: { characterId: string | number }): Promise<number>;

  getQuestTemplates(payload: { tier: number }): Promise<Quest[]>;

  isWalletQuestAvailable(payload: { questID: number }): Promise<boolean>;
}

interface StoreMappedGetters {
  charactersWithIds(ids: (string | number)[]): Nft[];
}

interface Data {
  isLoading: boolean;
  character?: Nft;
  componentKey: number;
  questAvailable: boolean;
}

export default Vue.extend({
  components: {QuestCharacter, QuestRequirements, QuestActions, QuestRewards},
  props: {
    characterId: {
      type: Number as PropType<number | string>,
    },
    quest: {
      type: Object as PropType<Quest>,
    },
    reputationLevelRequirements: {
      type: Object as PropType<ReputationLevelRequirements>,
    },
    questTemplateType: {
      type: Number as PropType<QuestTemplateType>,
    },
    walletQuestTier: {
      type: Number as PropType<number>,
    },
    defaultAccountProp: {
      type: String as PropType<string>,
      required: true,
    },
    currentNetworkIdProp: {
      type: Number as PropType<number>,
      required: true,
    },
    pickable: {
      type: Boolean,
      default: false
    },
    pickedQuestId: {
      type: Number as PropType<number>,
      default: 0,
    },
  },

  data() {
    return {
      isLoading: true,
      character: undefined,
      RewardType,
      Rarity,
      NftStatus,
      QuestTemplateType,
      componentKey: 0,
      questAvailable: false,
    } as Data;
  },

  computed: {
    ...mapState(['defaultAccount', 'currentNetworkId']),
    ...mapGetters(['charactersWithIds']) as Accessors<StoreMappedGetters>,

    /**
     * detect if the defaultAccount/networkId have changed.
     */
    hasStateChanged(): boolean {
      return this.defaultAccount + this.currentNetworkId !== this.defaultAccountProp + this.currentNetworkIdProp;
    },
    questCanBeCompleted(): boolean {
      return this.quest.progress >= this.quest.requirementAmount && this.quest.requirementAmount !== 0;
    },
  },

  methods: {
    ...mapActions([
      'getCharacterQuestData',
      'getCharacterBusyStatus',
      'getQuestTemplates',
      'isWalletQuestAvailable',
    ]) as StoreMappedActions,
    async onRefreshQuestData() {
      // if state has changed, trigger full page reload.
      if (this.hasStateChanged) {
        this.$emit('refresh-quest-data');
      }
      // change key of current row to triggering automatic, efficient content update
      else {
        await this.refreshQuestData();
        this.componentKey += 1;
      }
    },

    async refreshQuestData() {
      try {
        this.isLoading = true;
        //update row character-quest
        if(this.character && this.questTemplateType === QuestTemplateType.QUEST){
          this.character.quest = await this.getCharacterQuestData({characterId: this.characterId});
          this.questAvailable = true;
        }
        //update row wallet-quest
        else if(!this.character && this.questTemplateType === QuestTemplateType.WALLET){
          this.questAvailable = await this.isWalletQuestAvailable({questID: this.quest.id});
          const questUpdate = (await this.getQuestTemplates({tier: this.walletQuestTier + 30})).find((x) => x.id === this.quest.id) as Quest;
          this.quest.progress = questUpdate.progress;
        }
      } finally {
        this.isLoading = false;
      }
    },
  },


  async mounted() {
    if(this.questTemplateType === QuestTemplateType.QUEST){
      this.character = this.charactersWithIds([this.characterId]).filter(Boolean)[0];
      this.character.status = +await this.getCharacterBusyStatus({characterId: this.characterId});
    }
    await this.refreshQuestData();
  },
})
;
