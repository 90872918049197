



































































import Vue from 'vue';
export default Vue.extend({
  watch: {
  },
  methods:{
  },
});
