







































































































































































































































import Vue from 'vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { IState, IWeapon } from '@/interfaces';
import { ISpecialWeaponsManagerState } from '@/store/specialWeaponsManager';
import { Accessors } from 'vue/types/options';
import Events from '../../events';
import { secondsToDDHHMMSS } from '@/utils/date-time';
import eventShard from '../../assets/special-weapons/eventShard.png';
import generalShard from '../../assets/special-weapons/generalShard.png';
import forgingGif from '../../assets/special-weapons/forging.gif';
import WeaponIcon from '../WeaponIcon.vue';
import { fromWeiEther, toBN } from '@/utils/common';

type StoreMappedState = Pick<IState,'ownedWeaponIds' |'skillBalance' | 'skillRewards' | 'valorRewards'>;

type StoreMappedSpecialWeaponsManagerState = Pick<ISpecialWeaponsManagerState,
'specialWeaponEvents' | 'activeSpecialWeaponEventsIds' | 'specialWeaponEventId' | 'shardsSupply' | 'inactiveSpecialWeaponEventsIds'>;


interface StoreMappedGetters {
  weaponsWithIds(weaponIds: (string | number)[]): IWeapon[];
}

interface StoreMappedSpecialWeaponsManagerActions {
  fetchSpecialWeaponEvents(): Promise<void>;
  fetchForgeCosts(): Promise<number[]>;
  fetchShardsConvertDenominator(): Promise<number>;
  convertShards({ eventFromId, eventToId, amount }: { eventFromId: number, eventToId: number, amount: number }): Promise<void>;
  orderSpecialWeapon({ eventId, orderOption, orderWithSkill}: { eventId: number, orderOption: number, orderWithSkill: boolean}): Promise<void>;
  forgeSpecialWeapon(eventId: number): Promise<void>;
  fetchEventTotalOrderedCount(eventId: number): Promise<void>;
  fetchShardsStakingRewards(): Promise<string>;
  claimShardsStakingRewards({ eventId, amount}: { eventId: number, amount: number}): Promise<void>;
}

interface StoreMappedMutations {
  updateSpecialWeaponEventId(e: Event): void;
}

interface Data {
  images: any;
  selectedSpecialWeaponEventId: number;
  showModal: boolean;
  forgeOption: number;
  forgeCosts: number[];
  endsIn: number;
  updateEndTime: ReturnType<typeof setInterval> | null;
  orderedCountInterval: ReturnType<typeof setInterval> | null;
  convertAmount: number;
  selectedConvertFromId: number;
  selectedConvertToId: number;
  isConverting: boolean;
  isForging: boolean;
  isClaiming: boolean;
  shardsCostDenominator: number;
  shardsStakingRewards: string;
  isClaimingStakingRewards: boolean;
  claimRewardsIntoId: number;
  claimRewardsAmount: number;
  isLoading: boolean;
}

export default Vue.extend({
  data() {
    return {
      images: require.context('../../assets/special-weapons/', false, /\.png$/),
      selectedSpecialWeaponEventId: 0,
      forgeOption: 1,
      showModal: false,
      forgeCosts: [],
      endsIn: 0,
      updateEndTime: null,
      orderedCountInterval: null,
      convertAmount: 0,
      selectedConvertFromId: -1,
      selectedConvertToId: -1,
      isConverting: false,
      isForging: false,
      isClaiming: false,
      shardsCostDenominator: 0,
      shardsStakingRewards: '',
      isClaimingStakingRewards: false,
      claimRewardsIntoId: 0,
      claimRewardsAmount: 0,
      isLoading: false
    } as Data;
  },

  components: {
    WeaponIcon
  },

  computed: {
    ...(mapState([
      'ownedWeaponIds',
      'skillBalance',
      'skillRewards',
      'valorRewards'
    ]) as Accessors<StoreMappedState>),
    ...mapState('specialWeaponsManager',
      ([
        'specialWeaponEvents',
        'activeSpecialWeaponEventsIds',
        'specialWeaponEventId',
        'shardsSupply',
        'inactiveSpecialWeaponEventsIds'
      ])) as Accessors<StoreMappedSpecialWeaponsManagerState>,
    ...(mapGetters(['weaponsWithIds']) as Accessors<StoreMappedGetters>),

    eventDetails(): string {
      if(!this.specialWeaponEvents[this.selectedSpecialWeaponEventId]) return '';
      return this.specialWeaponEvents[this.selectedSpecialWeaponEventId].details;
    },

    eventPartnerWebsite(): string {
      if(!this.specialWeaponEvents[this.selectedSpecialWeaponEventId]) return '';
      return this.specialWeaponEvents[this.selectedSpecialWeaponEventId].website;
    },

    eventNote(): string {
      if(!this.specialWeaponEvents[this.selectedSpecialWeaponEventId]) return '';
      return this.specialWeaponEvents[this.selectedSpecialWeaponEventId].note;
    },

    eventArt(): string {
      if(!this.specialWeaponEvents[this.selectedSpecialWeaponEventId]) return '';
      return this.specialWeaponEvents[this.selectedSpecialWeaponEventId].art;
    },

    forgeCostShards(): number {
      return this.forgeCosts && this.forgeCosts[this.forgeOption - 1];
    },

    forgeCostSkill(): number {
      return this.forgeCosts && +this.formattedSkillCost(this.forgeOption + 3);
    },

    canForgeWithShards(): boolean {
      return !!this.selectedSpecialWeaponEventId &&
        this.shardsSupply[this.selectedSpecialWeaponEventId] >= this.forgeCostShards &&
        this.endsIn > 0;
    },

    canForgeWithSkill(): boolean {
      const cost = toBN(this.forgeCostSkill);
      const balance = toBN(+fromWeiEther(this.skillBalance) + +fromWeiEther(this.skillRewards) + +fromWeiEther(this.valorRewards));
      return !!this.selectedSpecialWeaponEventId &&
         balance.isGreaterThanOrEqualTo(cost) &&
        this.endsIn > 0;
    },

    canClaimStakingRewards(): boolean {
      return !!this.claimRewardsIntoId && +this.shardsStakingRewards >= 1 && +this.claimRewardsAmount <= +this.shardsStakingRewards;
    },

    hasSupplyLeft(): boolean {
      if(!+this.specialWeaponEvents[this.selectedSpecialWeaponEventId].supply) return true;
      return +this.specialWeaponEvents[this.selectedSpecialWeaponEventId].orderedCount< +this.specialWeaponEvents[this.selectedSpecialWeaponEventId].supply;
    },

    canConvert(): boolean {
      return this.selectedConvertFromId >= 0 &&
      this.selectedConvertToId >= 0 &&
      this.shardsSupply[this.selectedConvertFromId] >= this.convertAmount &&
      this.convertAmount > 0;
    },

    eventEndsIn(): string {
      return secondsToDDHHMMSS(this.endsIn);
    },

    partnerName(): string {
      if(!this.specialWeaponEvents[this.selectedSpecialWeaponEventId]) return '';
      return this.specialWeaponEvents[this.selectedSpecialWeaponEventId].name;
    },

    availableElement(): number {
      if(!this.specialWeaponEvents[this.selectedSpecialWeaponEventId]) return -1;
      return +this.specialWeaponEvents[this.selectedSpecialWeaponEventId].weaponElement;
    },

    isEventWeaponOrdered(): boolean {
      if(!this.specialWeaponEvents[this.selectedSpecialWeaponEventId]) return false;
      return this.specialWeaponEvents[this.selectedSpecialWeaponEventId].ordered;
    },

    isEventWeaponForged(): boolean {
      if(!this.specialWeaponEvents[this.selectedSpecialWeaponEventId]) return false;
      return this.specialWeaponEvents[this.selectedSpecialWeaponEventId].forged;
    },

    eventShardImg(): string {
      return eventShard;
    },

    generalShardImg(): string {
      return generalShard;
    },

    forgingGifSrc(): string {
      return forgingGif;
    },

    validInactiveSpecialWeaponsEventsIds(): number[] {
      return this.inactiveSpecialWeaponEventsIds.filter(id => this.specialWeaponEvents[id]?.name);
    },

    allEventsIds(): number[] {
      return this.activeSpecialWeaponEventsIds.concat(this.validInactiveSpecialWeaponsEventsIds);
    },

    convertOutputAmount(): number {
      if(!this.shardsCostDenominator) return 0;
      return +this.convertAmount === +this.shardsSupply[this.selectedConvertFromId] ?
        Math.ceil(this.convertAmount / this.shardsCostDenominator) :
        Math.floor(this.convertAmount / this.shardsCostDenominator);
    },

    forgedWeapon(): IWeapon | undefined {
      return this.weaponsWithIds(this.ownedWeaponIds).find(w => {
        return w?.weaponType === this.selectedSpecialWeaponEventId;
      });
    },

    orderedCount(): number {
      if(!this.specialWeaponEvents[this.selectedSpecialWeaponEventId]) return 0;
      return +this.specialWeaponEvents[this.selectedSpecialWeaponEventId].orderedCount;
    },

    specialWeaponsSupply(): number {
      if(!this.specialWeaponEvents[this.selectedSpecialWeaponEventId]) return 0;
      return +this.specialWeaponEvents[this.selectedSpecialWeaponEventId].supply;
    },

    progressBarWidth(): string {
      if(!this.specialWeaponEvents[this.selectedSpecialWeaponEventId]) return '0%';
      return `${Math.round((
        +this.specialWeaponEvents[this.selectedSpecialWeaponEventId].orderedCount /
        +this.specialWeaponEvents[this.selectedSpecialWeaponEventId].supply) * 100
      )}%`;
    },

    inactiveEventsIdsWithUnclaimedOrders(): number[] {
      return this.validInactiveSpecialWeaponsEventsIds.filter(id => this.specialWeaponEvents[id]?.ordered && !this.specialWeaponEvents[id]?.forged);
    }
  },

  watch: {
    selectedSpecialWeaponEventId(newValue, oldValue) {
      if(newValue !== oldValue && this.specialWeaponEvents[this.selectedSpecialWeaponEventId]) {
        this.endsIn = +(+this.specialWeaponEvents[this.selectedSpecialWeaponEventId].endTime - Date.now()/1000).toFixed(0);
      }
    }
  },

  methods: {
    ...mapActions('specialWeaponsManager',
      [
        'fetchSpecialWeaponEvents',
        'fetchForgeCosts',
        'convertShards',
        'fetchShardsConvertDenominator',
        'orderSpecialWeapon',
        'forgeSpecialWeapon',
        'fetchEventTotalOrderedCount',
        'fetchShardsStakingRewards',
        'claimShardsStakingRewards'
      ]) as StoreMappedSpecialWeaponsManagerActions,
    ...mapMutations(['updateSpecialWeaponEventId']) as StoreMappedMutations,

    imgPath(img: string): string {
      return this.images('./' + img);
    },

    async onForgeSpecialWeapon(forgeWithSkill: boolean = false) {
      try {
        this.isForging = true;
        if(forgeWithSkill) {
          await this.orderSpecialWeapon({
            eventId: this.selectedSpecialWeaponEventId,
            orderOption: this.forgeOption,
            orderWithSkill: true
          });
        } else {
          await this.orderSpecialWeapon({
            eventId: this.selectedSpecialWeaponEventId,
            orderOption: this.forgeOption,
            orderWithSkill: false
          });
        }
      }
      finally {
        this.isForging = false;
      }
    },

    async onClaimSpecialWeapon() {
      try {
        this.isClaiming = true;
        await this.forgeSpecialWeapon(this.selectedSpecialWeaponEventId);
      }
      finally {
        this.isClaiming = false;
      }
    },

    async onConvertShards() {
      try {
        this.isConverting = true;
        await this.convertShards({
          eventFromId: this.selectedConvertFromId,
          eventToId: this.selectedConvertToId,
          amount: this.convertAmount
        });
        this.selectedConvertFromId = -1;
        this.selectedConvertToId = -1;
        this.convertAmount = 0;
      }
      finally {
        this.isConverting = false;
      }
    },

    async onClaimStakingRewards() {
      try {
        this.isClaimingStakingRewards = true;
        this.claimRewardsAmount = Math.floor(this.claimRewardsAmount);
        await this.claimShardsStakingRewards({
          eventId: this.claimRewardsIntoId,
          amount: Math.floor(this.claimRewardsAmount)
        });
      }
      finally {
        this.isClaimingStakingRewards = false;
      }
    },

    openPartnerWebsite() {
      window.open(this.eventPartnerWebsite, '_blank');
    },

    formattedSkillCost(orderOption: number): string {
      return toBN(this.forgeCosts[orderOption - 1]).div(1e18).toFixed(4);
    }
  },

  async mounted() {
    this.updateEndTime = setInterval(() => {
      if(this.endsIn > 0) {
        this.endsIn--;
      }
    }, 1000);
    this.orderedCountInterval = setInterval(async () => {
      if(this.selectedSpecialWeaponEventId) {
        await this.fetchEventTotalOrderedCount(this.selectedSpecialWeaponEventId);
      }
      this.shardsStakingRewards = toBN(await this.fetchShardsStakingRewards()).div(1e18).toFixed(4);
    }, 3000);
    Events.$on('show-special-forge-modal', async () => {
      this.showModal = true;
      try {
        this.isLoading = true;
        await this.fetchSpecialWeaponEvents();
        this.forgeCosts = await this.fetchForgeCosts();
        if(+this.specialWeaponEventId === 0 && (this.activeSpecialWeaponEventsIds.length > 0 || this.inactiveEventsIdsWithUnclaimedOrders.length > 0)) {
          this.selectedSpecialWeaponEventId = +this.activeSpecialWeaponEventsIds.concat(this.inactiveEventsIdsWithUnclaimedOrders)[0];
        }
        else {
          this.selectedSpecialWeaponEventId = +this.specialWeaponEventId;
        }
        this.shardsCostDenominator = await this.fetchShardsConvertDenominator();
      }
      finally {
        this.isLoading = false;
      }
    });
  },

  beforeDestroy() {
    if(this.updateEndTime) {
      clearInterval(this.updateEndTime);
    }
    if(this.orderedCountInterval) {
      clearInterval(this.orderedCountInterval);
    }
  }
});
