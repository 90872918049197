

















import Vue from 'vue';
import Loader from '../components/Loader.vue';
export default Vue.extend({
  props:{
    title:{
      type:String,
      required:true
    },
    subTitle:{
      type:String,
      required:false
    },
    toolTip:{
      type: String,
      required: false
    },
    isDisabled:{
      type:Boolean,
      required:false,
      default: false
    },
    loadingText:{
      type:String,
      required: false
    },
    customTitleClass:{
      type:String,
      required: false
    },
    customSubTitleClass:{
      type:String,
      required: false
    },
    isLoading:{
      type: Boolean,
      default: false
    }
  },
  components:{
    Loader
  }
});
