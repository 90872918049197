





























































































































































































import Vue from 'vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {getBossArt, getBossName} from '@/raid-boss-art-placeholder';
import {traitNumberToName} from '@/contract-models';
import { Nft } from '@/interfaces/Nft';
import { CharacterTrait } from '@/interfaces';

export default Vue.extend({
  data() {
    return {
      duelHistory: [],
      raidData: {
        raidIndex: '',
        bossName: '',
        raiderCount: '',
        totalPower: 0,
        expectedFinishTime: new Date(),
        xpReward: '',
        staminaCost: '',
        durabilityCost: '',
        joinCost: '',
        raidStatus: '',
        bossPower: 0,
        bossTrait: '',
        accountPower: 0
      },
      remainingTime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      pvpRankingPoints: '',
    };
  },

  computed: {
    ...mapState([
      'characters',
      'currentCharacterId',
      'maxStamina',
      'ownedCharacterIds',
      'web3',
      'ownedGarrisonCharacterIds',
    ]),
    ...mapGetters([
      'getCharacterName',
      'getRaidState',
      'ownCharacters',
      'getCharacterRank',
      'getCharacterElement',
      'ownGarrisonCharacters',
      'getCharacterStamina',
      'getCharacterPower',
    ]),
    selectedCharacter(): Nft{
      return this.characters[this.currentCharacterId];
    },
    haveCharacters() {
      return this.ownedGarrisonCharacterIds?.length > 0 || this.ownCharacters?.length > 0;
    },
    characterLvl(): number {
      return this.characters[this.currentCharacterId]?.level + 1 ?? 1;
    },
    totalCharacterPower(): number {
      return this.getCharacterPower(this.currentCharacterId);
    },
    characterTrait(): string {
      const characterWithId = this.characters[this.currentCharacterId];
      return CharacterTrait[characterWithId?.trait] ?? '';
    },
    characterStamina(): string {
      return this.getCharacterStamina(this.currentCharacterId);
    },
    characterName(): string {
      return this.getCharacterName(this.currentCharacterId);
    },
  },

  methods: {
    ...mapMutations(['setCurrentCharacter']),
    ...mapActions([
      'getCharacter',
      'getRankingPointsByCharacter',
      'fetchRaidState'
    ]),

    getBossArt,
    traitNumberToName,
    getBossName(): string {
      return getBossName(+this.raidData.raidIndex);
    },
    getTimeRemaining(){
      setInterval(() => {
        const eventTime = this.raidData.expectedFinishTime.getTime();
        const currentTime = new Date().getTime();
        const diffTime = eventTime - currentTime;

        const d = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        const h = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const m = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
        const s = Math.floor((diffTime % (1000 * 60)) / 1000);

        this.remainingTime = {days:d,hours:h,minutes:m,seconds:s};
      }, 1000);
    },

    async fetchPvpDetails(characterId: string|number) {
      this.pvpRankingPoints = await this.getRankingPointsByCharacter(characterId);
      if(this.pvpRankingPoints  === '0'){
        this.pvpRankingPoints = '-';
      }
    },
    async processRaidData() {
      const raidData = this.getRaidState;
      this.raidData.raidIndex = raidData.index;
      this.raidData.bossName = this.getBossName();
      this.raidData.raiderCount = raidData.raiderCount;
      this.raidData.totalPower = +raidData.playerPower;
      this.raidData.expectedFinishTime = new Date(+raidData.expectedFinishTime * 1000);
      this.raidData.xpReward = raidData.xpReward;
      this.raidData.staminaCost = raidData.staminaCost;
      this.raidData.durabilityCost = raidData.durabilityCost;
      this.raidData.joinCost = raidData.joinSkill;
      this.raidData.raidStatus = raidData.status;
      this.raidData.bossPower = +raidData.bossPower;
      this.raidData.bossTrait = raidData.bossTrait;
      this.raidData.accountPower = +raidData.accountPower;
    }
  },

  async mounted(){
    this.getTimeRemaining();
    const refreshRaidData = async () => {
      await (this as any).fetchRaidState();
      (this as any).processRaidData();
    };
    await refreshRaidData();
    window.setInterval(async () => {
      await refreshRaidData();
    }, 3000);

    Promise.all([
      await this.fetchPvpDetails(this.currentCharacterId),
      await this.fetchRaidState(),
      await this.processRaidData()
    ]);
  },
  watch: {
    currentCharacterId(newId: string|number){
      this.fetchPvpDetails(newId);
      if(this.currentCharacterId === null){
        this.setCurrentCharacter(this.ownedCharacterIds[0]);
      }
    },
  }
});
