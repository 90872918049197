



































































































































































































































































































































import Vue from 'vue';
import {mapActions} from 'vuex';
import {Quest, ReputationLevelRequirements, RewardType, TierChances} from '@/interfaces';
import {DustRarity, QuestItemType, QuestTemplateType, Rarity, RequirementType} from '@/enums/Quest';
import QuestTemplatesDisplay from '../QuestTemplatesDisplay.vue';
import QuestRequirements from '../QuestRequirements.vue';
import QuestRewards from '../QuestRewards.vue';
import QuestActions from '../QuestActions.vue';
import {isValidWeb3Address} from '@/utils/common';

interface StoreMappedActions {
  addQuestTemplate(payload: { questTemplate: Quest, tierOffset: number, supply: number, deadline: number }): Promise<void>;

  addPromoQuestTemplate(payload: { questTemplate: Quest }): Promise<void>;

  getReputationLevelRequirements(): Promise<ReputationLevelRequirements>;

  setReputationLevelRequirements(payload: { requirements: number[] }): Promise<void>;

  setSkipQuestStaminaCost(payload: { staminaCost: number }): Promise<void>;

  getSkipQuestStaminaCost(): Promise<number>;

  getQuestTierChances(payload: { tier: number }): Promise<TierChances>;

  setQuestTierChances(payload: { tier: number, tierChances: TierChances }): Promise<void>;

  isUsingPromoQuests(): Promise<boolean>;

  toggleUsePromoQuests(): Promise<void>;

  setWeeklyReward(payload: { weeklyReward: WeeklyReward }): Promise<void>;
}

interface WeeklyReward {
  rewardType?: QuestItemType;
  rewardRarity?: Rarity;
  rewardAmount?: number;
  rewardExternalAddress: string;
  reputationAmount?: number;
  weekNumber?: number;
  completionsGoal?: number;
}

interface Data {
  questTemplate: Quest;
  weeklyReward: WeeklyReward;
  rarities: Rarity[];
  dustRarities: DustRarity[];
  requirementTypes: RequirementType[];
  rewardTypes: RewardType[];
  weeklyRewardTypes: RewardType[];
  questTemplateType: QuestTemplateType;
  rewardID?: number;
  week?: number;
  isLoading: boolean;
  showTemplateConfirmationModal: boolean;
  showPromoToggleConfirmationModal: boolean;
  reputationLevelRequirements?: ReputationLevelRequirements;
  staminaCost: number;
  tierChances: TierChances[];
  usePromoQuests: boolean;
  supply?: number;
  timestamp?: number;
}

export default Vue.extend({

  components: {QuestTemplatesDisplay, QuestRequirements, QuestRewards, QuestActions},

  data() {
    return {
      questTemplate: {
        progress: 0,
        reputation: 0,
        id: 0,
        requirementAmount: 0,
        rewardAmount: 0,
        reputationAmount: 0,
      },
      weeklyReward: {
        rewardType: undefined,
        rewardRarity: undefined,
        rewardAmount: 0,
        rewardExternalAddress: '',
        reputationAmount: 0,
        weekNumber: 0,
        completionsGoal: 0,
      },
      week: undefined,
      rewardID: undefined,
      rarities: [Rarity.COMMON, Rarity.UNCOMMON, Rarity.RARE, Rarity.EPIC, Rarity.LEGENDARY],
      dustRarities: [DustRarity.LESSER, DustRarity.GREATER, DustRarity.POWERFUL],
      requirementTypes: [RequirementType.WEAPON, RequirementType.JUNK,
        RequirementType.DUST, RequirementType.TRINKET,
        RequirementType.SHIELD, RequirementType.STAMINA,
        RequirementType.SOUL, RequirementType.RAID,
        RequirementType.EXTERNAL, RequirementType.EXTERNAL_HOLD],
      rewardTypes: [RewardType.WEAPON, RewardType.JUNK,
        RewardType.DUST, RewardType.TRINKET,
        RewardType.SHIELD, RewardType.EXPERIENCE,
        RewardType.SOUL, RewardType.EXTERNAL,
        RewardType.CHARACTER],
      weeklyRewardTypes: [RewardType.WEAPON, RewardType.JUNK,
        RewardType.DUST, RewardType.TRINKET,
        RewardType.SHIELD, RewardType.SOUL,
        RewardType.EXTERNAL, RewardType.CHARACTER],
      questTemplateType: QuestTemplateType.WALLET,
      QuestTemplateType,
      isLoading: false,
      showTemplateConfirmationModal: false,
      showPromoToggleConfirmationModal: false,
      reputationLevelRequirements: undefined,
      staminaCost: 0,
      tierChances: [] as TierChances[],
      usePromoQuests: false,
      supply: undefined,
      timestamp: undefined,
      RequirementType,
      RewardType,
      Rarity,
      DustRarity,
    } as Data;
  },

  computed: {
    currentWeekNumber(): number {
      const weekInSeconds = 604800;
      return Math.floor(Date.now() / 1000 / weekInSeconds % 53) + 1;
    },
    tierOffset(): number {
      switch (this.questTemplateType) {
      default:
        return 0;
      case QuestTemplateType.PROMO:
        return 10;
      case QuestTemplateType.PICKABLE:
        return 20;
      case QuestTemplateType.WALLET:
        return 30;
      }
    }
  },

  watch: {
    questTemplateType(questType: QuestTemplateType) {
      if (questType === QuestTemplateType.WALLET) {
        this.questTemplate.reputationAmount = 0;
      }
    }
  },

  methods: {
    ...mapActions([
      'addQuestTemplate',
      'getReputationLevelRequirements',
      'setReputationLevelRequirements',
      'setSkipQuestStaminaCost',
      'getSkipQuestStaminaCost',
      'getQuestTierChances',
      'setQuestTierChances',
      'isUsingPromoQuests',
      'toggleUsePromoQuests',
      'setWeeklyReward',
    ]) as StoreMappedActions,

    showConfirmationModal() {
      if (this.questTemplate.requirementType === RequirementType.RAID
        || this.questTemplate.requirementType === RequirementType.STAMINA
        || this.questTemplate.requirementType === RequirementType.SOUL
        || this.questTemplate.requirementType === RequirementType.EXTERNAL
        || this.questTemplate.requirementType === RequirementType.EXTERNAL_HOLD) {
        this.questTemplate.requirementRarity = Rarity.COMMON;
      }
      if (this.questTemplate.rewardType === RewardType.EXPERIENCE
        || this.questTemplate.rewardType === RewardType.SOUL
        || this.questTemplate.rewardType === RewardType.EXTERNAL
        || this.questTemplate.rewardType === RewardType.CHARACTER) {
        this.questTemplate.rewardRarity = Rarity.COMMON;
      }
      if (!this.questTemplate.deadline && !this.questTemplate.supply) {
        this.questTemplate.deadline = 0;
        this.questTemplate.supply = 0;
      }
      this.showTemplateConfirmationModal = true;
    },

    async addReward() {
      if (this.weeklyReward.rewardType === QuestItemType.SOUL
        || this.weeklyReward.rewardType === QuestItemType.EXTERNAL
        || this.weeklyReward.rewardType === QuestItemType.CHARACTER) {
        this.weeklyReward.rewardRarity = Rarity.COMMON;
      }
      try {
        this.isLoading = true;
        await this.setWeeklyReward({
          weeklyReward: this.weeklyReward,
        });
      } finally {
        this.isLoading = false;
        this.weeklyReward = {
          rewardType: undefined,
          rewardRarity: undefined,
          rewardAmount: 0,
          rewardExternalAddress: '',
          reputationAmount: 0,
          weekNumber: 0,
          completionsGoal: 0
        };
      }
    },

    async onSubmit() {
      try {
        this.isLoading = true;
        await this.addQuestTemplate({
          questTemplate: this.questTemplate,
          tierOffset: this.questTemplateType,
          supply: this.supply ? this.supply : 0,
          deadline: this.timestamp ? this.timestamp : 0,
        });
        this.refreshQuestTemplates();
      } finally {
        this.isLoading = false;
        this.showTemplateConfirmationModal = false;
      }
    },

    async updateRequirements() {
      if (!this.reputationLevelRequirements) return;
      try {
        this.isLoading = true;
        await this.setReputationLevelRequirements({
          requirements: [this.reputationLevelRequirements.level2, this.reputationLevelRequirements.level3,
            this.reputationLevelRequirements.level4, this.reputationLevelRequirements.level5]
        });
      } finally {
        this.isLoading = false;
      }
    },

    async updateStaminaCost() {
      try {
        this.isLoading = true;
        await this.setSkipQuestStaminaCost({staminaCost: this.staminaCost});
        this.staminaCost = await this.getSkipQuestStaminaCost();
      } finally {
        this.isLoading = false;
      }
    },

    async updateTierChances(tier: number, tierChances: TierChances) {
      try {
        this.isLoading = true;
        await this.setQuestTierChances({tier, tierChances});
        await this.refreshTierChance(tier);
      } finally {
        this.isLoading = false;
      }
    },

    async togglePromoQuests() {
      try {
        this.isLoading = true;
        await this.toggleUsePromoQuests();
        this.usePromoQuests = await this.isUsingPromoQuests();
      } finally {
        this.isLoading = false;
        this.showPromoToggleConfirmationModal = false;
      }
    },

    refreshQuestTemplates() {
      this.$root.$emit('refresh-quest-templates');
    },

    addNewQuestDisabled() {
      return this.questTemplate.tier === undefined
        || this.questTemplate.requirementType === undefined
        || (this.questTemplate.requirementRarity === undefined
          && this.questTemplate.requirementType !== RequirementType.RAID
          && this.questTemplate.requirementType !== RequirementType.STAMINA
          && this.questTemplate.requirementType !== RequirementType.SOUL
          && this.questTemplate.requirementType !== RequirementType.EXTERNAL
          && this.questTemplate.requirementType !== RequirementType.EXTERNAL_HOLD)
        || !this.questTemplate.requirementAmount
        || this.questTemplate.rewardType === undefined
        || (this.questTemplate.rewardRarity === undefined
          && this.questTemplate.rewardType !== RewardType.EXPERIENCE
          && this.questTemplate.rewardType !== RewardType.SOUL
          && this.questTemplate.rewardType !== RewardType.EXTERNAL
          && this.questTemplate.rewardType !== RewardType.CHARACTER)
        || !this.questTemplate.rewardAmount
        || this.questTemplate.reputationAmount === undefined
        || (this.timestamp && !this.supply)
        || (this.supply && !this.timestamp)
        || ((this.questTemplate.requirementType === RequirementType.EXTERNAL
            || this.questTemplate.requirementType === RequirementType.EXTERNAL_HOLD)
          && this.questTemplate.requirementExternalAddress
          && !isValidWeb3Address(this.questTemplate.requirementExternalAddress.toLowerCase()))
        || (this.questTemplate.rewardType === RewardType.EXTERNAL
          && this.questTemplate.rewardExternalAddress
          && !isValidWeb3Address(this.questTemplate.rewardExternalAddress.toLowerCase()))
        || this.showTemplateConfirmationModal || this.isLoading;
    },

    addNewWeeklyRewardDisabled() {
      return this.weeklyReward.rewardType === undefined
        || (this.weeklyReward.rewardRarity === undefined
          && this.weeklyReward.rewardType !== QuestItemType.EXPERIENCE
          && this.weeklyReward.rewardType !== QuestItemType.SOUL
          && this.weeklyReward.rewardType !== QuestItemType.EXTERNAL)
        || !this.weeklyReward.rewardAmount
        || (this.weeklyReward.rewardType === QuestItemType.EXTERNAL
          && !isValidWeb3Address(this.weeklyReward.rewardExternalAddress))
        || !this.weeklyReward.weekNumber
        || !this.weeklyReward.completionsGoal
        || this.showTemplateConfirmationModal || this.isLoading;
    },

    updateRequirementsDisabled() {
      return !this.reputationLevelRequirements ||
        !this.reputationLevelRequirements.level2 ||
        !this.reputationLevelRequirements.level3 ||
        !this.reputationLevelRequirements.level4 ||
        !this.reputationLevelRequirements.level5 ||
        this.isLoading || this.showTemplateConfirmationModal;
    },

    async refreshTierChance(tier: number) {
      this.tierChances[tier] = await this.getQuestTierChances({tier});
    },

    async refreshTierChances() {
      this.tierChances[0] = await this.getQuestTierChances({tier: 0});
      this.tierChances[1] = await this.getQuestTierChances({tier: 1});
      this.tierChances[2] = await this.getQuestTierChances({tier: 2});
      this.tierChances[3] = await this.getQuestTierChances({tier: 3});
      this.tierChances[4] = await this.getQuestTierChances({tier: 4});
    },

    updateCommonChance(index: number) {
      this.tierChances[index].common = 100 - this.tierChances[index].uncommon - this.tierChances[index].rare -
        this.tierChances[index].epic - this.tierChances[index].legendary;
      Vue.set(this.tierChances, index, this.tierChances[index]);
    },
  },

  async mounted() {
    try {
      this.isLoading = true;
      this.refreshQuestTemplates();
      this.reputationLevelRequirements = await this.getReputationLevelRequirements();
      this.staminaCost = await this.getSkipQuestStaminaCost();
      this.usePromoQuests = await this.isUsingPromoQuests();
      await this.refreshTierChances();
    } finally {
      this.isLoading = false;
    }
  }

});
