









































import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import { CharacterPower } from '@/interfaces';


interface Data {
  powerAmount: number
}

interface StoreMappedActions {
  upgradeCharacterWithSoul(payload: {charId: number, soulAmount: number}): Promise<void>;
  upgradeNonGenesisCharacterWithSoul(payload: {charId: number, soulAmount: number}): Promise<void>;
}


export default Vue.extend({
  props: {
    soulBalance: {
      type: Number,
      default: 0
    }
  },
  data(): Data{
    return {
      powerAmount: 0
    };
  },
  computed:{
    ...mapState(['characters','currentCharacterId']),
    ...mapGetters(['getCharacterPower']),
    remainingPowerLimit(): number {
      return( 4 * CharacterPower(this.characters[this.currentCharacterId]?.level ?? 0) - this.getCharacterPower(this.currentCharacterId.toString()) ?? 0);
    },
    isGenesisCharacter(): boolean {
      return this.characters[this.currentCharacterId]?.version === 0;
    }
  },
  methods: {
    ...mapActions(['upgradeCharacterWithSoul', 'upgradeNonGenesisCharacterWithSoul']) as StoreMappedActions,
    handlePower(e: { target: HTMLInputElement }){
      if ((this.remainingPowerLimit > (this.soulBalance * 10) && +e.target.value > (this.soulBalance * 10))) {
        this.powerAmount = this.soulBalance * 10;
        return;
      }
      this.powerAmount = Math.ceil(+e.target.value/10)*10;
    },
    handleMax(): void{
      if ((this.soulBalance * 10) > this.remainingPowerLimit){
        this.powerAmount = Math.ceil(this.remainingPowerLimit/10)*10;
        return;
      }
      this.powerAmount = this.soulBalance * 10;
    },
    handleInput(e:  { target: HTMLInputElement }): void{
      if (+e.target.value > (this.soulBalance * 10)) {
        this.powerAmount = this.soulBalance * 10;
        return;
      }
      this.powerAmount = Math.ceil((+e.target.value)/10)*10;
    },
    handleSoulPowerValue(souls: number): number{
      if (souls >= (this.soulBalance * 10)) {
        this.powerAmount = this.soulBalance * 10;
        return this.soulBalance * 10;
      }
      return souls;
    },
    async onUpgradeConfirm() {
      if (!this.currentCharacterId || this.powerAmount === 0) return;
      try {
        if(this.isGenesisCharacter) {
          await this.upgradeCharacterWithSoul({charId: this.currentCharacterId, soulAmount: this.powerAmount / 10});
        }
        else {
          await this.upgradeNonGenesisCharacterWithSoul({charId: this.currentCharacterId, soulAmount: this.powerAmount / 10});
        }
      } catch (error) {
        console.error(error);
        return;
      }
      this.$emit('fetchSoulBalance');
      this.powerAmount = 0;
    },

  },
});
