






























































































































































import { getConfigValue } from '@/contracts';
import { CharacterPower, CharacterTrait, GetTotalMultiplierForTrait, IWeapon, WeaponTrait } from '@/interfaces';
import axios from 'axios';
import Vue from 'vue';
import { Accessors } from 'vue/types/options';
import { mapActions, mapGetters } from 'vuex';
import { toBN, fromWeiEther } from '../../utils/common';

interface PriceJson {
  binancecoin: CoinPrice;
  cryptoblades: CoinPrice;
  'huobi-token': CoinPrice;
  'oec-token': CoinPrice;
  'matic-network': CoinPrice;
  'avalanche-2': CoinPrice;
  ethereum: CoinPrice;
}

interface StoreMappedCombatGetters {
  fightGasOffset: string;
  fightBaseline: string;
}

interface StoreMappedCombatActions {
  fetchExpectedPayoutForMonsterPower(
    { power }:
    { power: string | number }): Promise<string>;
  getCombatTokenChargePercent(): Promise<string>;
}

interface StoreMappedTreasuryActions {
  getCurrentBestMultiplier(): Promise<number>;
}

interface CoinPrice {
  usd: number;
}

export default Vue.extend({
  computed: {
    ...mapGetters([
      'currentCharacter',
      'currentWeapon',
      'getCharacterPower'
    ]),
    ...(mapGetters('combat', [
      'fightGasOffset',
      'fightBaseline'
    ]) as Accessors<StoreMappedCombatGetters>),

    isLoadingCharacter(): boolean {
      return !this.currentCharacter;
    },

    currentTokenPrice(): number {
      switch(this.gasToken) {
      case 'BNB':
        return this.bnbPrice;
      case 'HT':
        return this.htPrice;
      case 'OKT':
        return this.oktPrice;
      case 'MATIC':
        return this.maticPrice;
      case 'AVAX':
        return this.avaxPrice;
      case 'aETH':
        return this.auroraPrice;
      case 'SFUEL':
        return this.skalePrice;
      default:
        return this.bnbPrice;
      }
    },

    maxSoulSliderValue(): number {
      return CharacterPower(this.levelSliderValue - 1) * this.powerMultiplierLimit;
    }
  },

  data() {
    return {
      characterElementValue: '',
      levelSliderValue: 1,
      soulSliderValue: 0,
      staminaSelectValue: 200,
      starsValue: 1,
      wepElementValue: '',
      wepFirstStatElementValue: '',
      wepSecondStatElementValue: '',
      wepThirdStatElementValue: '',
      wepFirstStatSliderValue: 4,
      wepSecondStatSliderValue: 4,
      wepThirdStatSliderValue: 4,
      wepBonusPowerSliderValue: 0,
      bnbPrice: 0,
      htPrice: 0,
      oktPrice: 0,
      maticPrice: 0,
      avaxPrice: 0,
      auroraPrice: 0,
      skalePrice: 0,
      skillPrice: 0,
      calculationResults: [] as number[][],
      gasToken: '',
      fightFeePercentage: 0,
      currentMultiplier: 1,
      isLoading: false,
      powerMultiplierLimit: 3 // hardcoded soul power limit in the contract
    };
  },

  methods: {
    ...(mapActions('combat', ['fetchExpectedPayoutForMonsterPower', 'getCombatTokenChargePercent',]) as StoreMappedCombatActions),
    ...(mapActions('treasury', ['getCurrentBestMultiplier']) as StoreMappedTreasuryActions),

    async onShowEarningsCalculator() {
      if(this.currentCharacter !== null && this.currentCharacter !== undefined) {
        this.characterElementValue = CharacterTrait[this.currentCharacter.trait];
        this.levelSliderValue = this.currentCharacter.level + 1;
        this.soulSliderValue = this.getCharacterPower(this.currentCharacter.id) - CharacterPower(this.currentCharacter.level);
      }

      if(this.currentWeapon !== null) {
        this.starsValue = this.currentWeapon.stars + 1;
        this.wepElementValue = this.currentWeapon.element;
        this.wepFirstStatSliderValue = this.currentWeapon.stat1Value;
        this.wepSecondStatSliderValue = this.starsValue > 3 && this.currentWeapon.stat2Value;
        this.wepThirdStatSliderValue = this.starsValue > 4 && this.currentWeapon.stat3Value;
        this.wepFirstStatElementValue = this.currentWeapon.stat1;
        this.wepSecondStatElementValue = this.starsValue > 3 && this.currentWeapon.stat2;
        this.wepThirdStatElementValue = this.starsValue > 4 && this.currentWeapon.stat3;
        this.wepBonusPowerSliderValue = this.currentWeapon.bonusPower;
      }

      (this.$refs['earnings-calc-modal'] as any).show();
      this.isLoading = true;
      try {
        await this.fetchPricesAndFees();
      }
      finally {
        this.isLoading = false;
      }
    },

    onReset() {
      this.characterElementValue = '';
      this.levelSliderValue =  1;
      this.staminaSelectValue = 200;
      this.starsValue =  1;
      this.wepElementValue =  '';
      this.wepFirstStatElementValue =  '';
      this.wepSecondStatElementValue =  '';
      this.wepThirdStatElementValue =  '';
      this.wepFirstStatSliderValue =  4;
      this.wepSecondStatSliderValue =  4;
      this.wepThirdStatSliderValue =  4;
      this.wepBonusPowerSliderValue =  0;
      this.calculationResults = [] as number[][];
    },

    getMinRoll(stars: number): number {
      switch(stars) {
      case 2: return 180;
      case 3: return 280;
      case 4: return 200;
      case 5: return 268;
      default: return 4;
      }
    },

    getMaxRoll(stars: number): number {
      switch(stars) {
      case 1: return 200;
      case 2: return 300;
      default: return 400;
      }
    },

    async fetchPricesAndFees() {
      await this.fetchPrices();
      this.fightFeePercentage = +await this.getCombatTokenChargePercent();
      this.currentMultiplier = +(+(await this.getCurrentBestMultiplier()/1e18).toFixed(4));
    },

    async fetchPrices() {
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=cryptoblades,binancecoin,huobi-token,oec-token,matic-network,avalanche-2,ethereum&vs_currencies=usd');
      const data = response.data as PriceJson;
      this.bnbPrice = data?.binancecoin.usd;
      this.skillPrice = data?.cryptoblades.usd;
      this.htPrice = data?.['huobi-token'].usd;
      this.oktPrice = data?.['oec-token'].usd;
      this.maticPrice = data?.['matic-network'].usd;
      this.avaxPrice = data?.['avalanche-2'].usd;
      this.auroraPrice = data?.ethereum.usd;
    },

    canCalculate(): boolean {
      if(this.isLoading) return false;
      if(!this.characterElementValue || !this.wepElementValue) return false;
      if(this.starsValue < 4 && !this.wepFirstStatElementValue) return false;
      if(this.starsValue === 4 && (!this.wepFirstStatElementValue || !this.wepSecondStatElementValue)) return false;
      if(this.starsValue === 5 && (!this.wepFirstStatElementValue || !this.wepSecondStatElementValue || !this.wepThirdStatElementValue)) return false;
      return true;
    },

    async calculateEarnings() {
      if(!this.canCalculate()) return;
      this.calculationResults = [];
      const fightFee = +getConfigValue('fightGas') * this.currentTokenPrice;
      const weapon = this.getWeapon();
      const characterTrait = CharacterTrait[this.characterElementValue as keyof typeof CharacterTrait];
      const weaponMultiplier = GetTotalMultiplierForTrait(weapon, characterTrait);

      const totalPower = this.getTotalPower(CharacterPower(this.levelSliderValue - 1) + +this.soulSliderValue, weaponMultiplier, this.wepBonusPowerSliderValue);
      const averageDailyReward = await this.getAverageRewardForPower(totalPower) * 7.2;
      const averageDailyCostInNativeTokenFees = this.gasToken === 'SFUEL' ? 0 : averageDailyReward * this.skillPrice * this.fightFeePercentage / 100;
      const averageFightProfit = averageDailyReward * this.skillPrice * this.currentMultiplier / 7.2;
      for(let i = 1; i < 8; i++) {
        const averageDailyProfitForCharacter = averageFightProfit * i -
          ((this.getNumberOfFights(this.staminaSelectValue) * fightFee)) - averageDailyCostInNativeTokenFees;
        const averageDailyProfitForAllCharacter = 4 * averageDailyProfitForCharacter;
        const averageMonthlyProfitForAllCharacter = 30 * averageDailyProfitForAllCharacter;
        this.calculationResults.push([averageDailyProfitForCharacter, averageDailyProfitForAllCharacter, averageMonthlyProfitForAllCharacter]);
      }
    },

    getNumberOfFights(stamina: number) {
      return 288 / stamina;
    },

    getWeapon(): IWeapon {
      const weapon = {
        stat1Type: WeaponTrait[this.wepFirstStatElementValue as keyof typeof WeaponTrait],
        stat2Type: WeaponTrait[this.wepSecondStatElementValue as keyof typeof WeaponTrait],
        stat3Type: WeaponTrait[this.wepThirdStatElementValue as keyof typeof WeaponTrait],
        stat1Value: this.wepFirstStatSliderValue,
        stat2Value: this.starsValue > 3 && this.wepSecondStatSliderValue || 0,
        stat3Value: this.starsValue > 4 && this.wepThirdStatSliderValue || 0,
      } as IWeapon;
      return weapon;
    },

    getTotalPower(characterPower: number, weaponMultiplier: number, bonusPower: number): number {
      return characterPower * weaponMultiplier + Number(bonusPower);
    },

    async getAverageRewardForPower(power: number) {
      const expectedPayout = parseInt(await this.fetchExpectedPayoutForMonsterPower({ power: Math.round(power) }), 10);
      return this.formattedSkill(expectedPayout);
    },

    getNextMilestoneBonus(level: number): string {
      const nextMilestoneLevel = this.getNextMilestoneLevel(level);
      return this.getRewardDiffBonus(level, nextMilestoneLevel);
    },

    getNextMilestoneLevel(level: number): number {
      return (Math.floor(level / 10) + 1) * 10 + 1;
    },

    getAverageRewardAtLevel(level: number): number {
      return this.formattedSkill(parseInt(this.fightGasOffset, 10))
      + (this.formattedSkill(parseInt(this.fightBaseline, 10))
      * (Math.sqrt(CharacterPower(level - 1)/1000)));
    },

    getRewardDiffBonus(level: number, targetLevel: number): string {
      return (this.getAverageRewardAtLevel(targetLevel) /
        this.getAverageRewardAtLevel(level + 1) * 100 - 100).toFixed(2);
    },

    formattedSkill(skill: number): number {
      const skillBalance = fromWeiEther(skill.toString());
      return toBN(skillBalance).toNumber();
    },

    stringFormattedSkill(skill: number): string {
      const skillBalance = fromWeiEther(skill.toString());
      return toBN(skillBalance).toFixed(6);
    },

    getColoringClass(i: number): string {
      if(!this.calculationResults.length || this.calculationResults[i][0] === 0) return '';
      if(this.calculationResults[i][0] < 0) return 'negative-value';
      return 'positive-value';
    },

    refreshWeaponStats(value: number) {
      this.wepFirstStatSliderValue = this.getMinRoll(value);
      this.wepSecondStatSliderValue = this.getMinRoll(value);
      this.wepThirdStatSliderValue = this.getMinRoll(value);
    }
  },

  mounted() {
    this.gasToken = getConfigValue('currencySymbol') || 'BNB';
  }
});
