import { render, staticRenderFns } from "./BlacksmithAdmin.vue?vue&type=template&id=28736dd9&scoped=true&"
import script from "./BlacksmithAdmin.vue?vue&type=script&lang=ts&"
export * from "./BlacksmithAdmin.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28736dd9",
  null
  
)

export default component.exports