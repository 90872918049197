












































import Vue from 'vue';
import {mapActions} from 'vuex';
import {isValidWeb3Address} from '../../../utils/common';

interface StoreMappedActions {
  storeNftsToPartnerVault(payload: { tokenAddress: string, tokenIds: number[] }): Promise<void>;

  storeCurrencyToPartnerVault(payload: { currencyAddress: string, amount: number }): Promise<void>;

  getCurrencyBalanceInPartnerVault(payload: { currencyAddress: string }): Promise<string[]>;

  getNftsInPartnerVault(payload: { tokenAddress: string }): Promise<string[]>;
}

interface Data {
  nft: {
    address: string;
    identifiers: string;
  };
  currency: {
    address: string;
    amount?: number;
  };
  currencyBalance: {
    address: string;
    amount?: number;
    symbol?: string;
  };
  nftBalance: {
    address: string;
    total?: number;
    ids?: string;
  };
  isLoading: boolean;
}

export default Vue.extend({
  data() {
    return {
      nft: {
        address: '',
        identifiers: '',
      },
      currency: {
        address: '',
        amount: undefined,
      },
      currencyBalance: {
        address: '',
        amount: undefined,
        symbol: undefined,
      },
      nftBalance: {
        address: '',
        total: undefined,
        ids: undefined,
      },
      isLoading: false,
    } as Data;
  },

  computed: {
    storeNftsButtonDisabled(): boolean {
      return !isValidWeb3Address(this.nft.address)
        || this.nft.identifiers === ''
        || this.isLoading;
    },

    storeCurrencyButtonDisabled(): boolean {
      return !isValidWeb3Address(this.currency.address)
        || !this.currency.amount
        || this.isLoading;
    },

    checkNftsBalanceButtonDisabled(): boolean {
      return !isValidWeb3Address(this.nftBalance.address)
        || this.isLoading;
    },

    checkCurrencyBalanceButtonDisabled(): boolean {
      return !isValidWeb3Address(this.currencyBalance.address)
        || this.isLoading;
    },
  },

  methods: {
    ...mapActions([
      'storeNftsToPartnerVault',
      'storeCurrencyToPartnerVault',
      'getCurrencyBalanceInPartnerVault',
      'getNftsInPartnerVault',
    ]) as StoreMappedActions,

    async storeNfts() {
      if (!isValidWeb3Address(this.nft.address) || this.nft.identifiers === '') return;
      try {
        this.isLoading = true;
        await this.storeNftsToPartnerVault({
          tokenAddress: this.nft.address,
          tokenIds: this.nft.identifiers.split(',').map(id => +id),
        });
        this.clearInputs();
      } finally {
        this.isLoading = false;
      }
    },

    async storeCurrency() {
      if (!isValidWeb3Address(this.currency.address) || !this.currency.amount) return;
      try {
        this.isLoading = true;
        await this.storeCurrencyToPartnerVault({
          currencyAddress: this.currency.address,
          amount: this.currency.amount,
        });
        this.clearInputs();
      } finally {
        this.isLoading = false;
      }
    },

    async checkCurrencyBalance() {
      if (!isValidWeb3Address(this.currencyBalance.address)) return;
      try {
        this.isLoading = true;
        const result = await this.getCurrencyBalanceInPartnerVault({
          currencyAddress: this.currencyBalance.address
        });
        this.currencyBalance.amount = +result[0];
        this.currencyBalance.symbol = result[1];
      } finally {
        this.isLoading = false;
      }
    },

    async checkNftsBalance() {
      if (!isValidWeb3Address(this.nftBalance.address)) return;
      try {
        this.isLoading = true;
        const result = await this.getNftsInPartnerVault({
          tokenAddress: this.nftBalance.address
        });
        this.nftBalance.ids = result.join(', ');
        this.nftBalance.total = result.length;
      } finally {
        this.isLoading = false;
      }
    },

    clearInputs() {
      this.nft.address = '';
      this.nft.identifiers = '';
      this.currency.address = '';
      this.currency.amount = undefined;
    }
  },
});
