



















































import Vue from 'vue';
import { PropType } from 'vue/types/options';
import { mapActions } from 'vuex';
import i18n from '@/i18n';
import {isValidWeb3Address} from '../utils/common';

interface StoreMappedActions {
  transferNFT(payload: {
    nftId: number;
    receiverAddress: string;
    nftType: string;
  }): Promise<void>;
}

export interface NftOption {
  name: string;
  amount: number;
  handler: (id: number | string, type?: string) => any;
  hasDefaultOption?: boolean;
  noAmount?: boolean;
}

export default Vue.extend({
  props: {
    options: {
      type: Array as PropType<NftOption[]>,
      default() {
        return [];
      },
    },
    nftId: {
      type: Number,
      default: null
    },
    nftType: {
      type: String,
      default: '',
    },
    showTransfer: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    receiverAddress: '' as string,
    isSending: false as boolean,
    resultMsg: '' as string,
    successfullTransfer: false as boolean,
  }),
  computed: {
    isValidAddress() {
      return isValidWeb3Address(this.receiverAddress);
    },
  },
  methods: {
    ...(mapActions([
      'transferNFT',
    ]) as StoreMappedActions),
    isDisabled(option: NftOption): boolean {
      return !option.hasDefaultOption && +option.amount === 0;
    },
    async transfer(bvModalEvt: Event) {
      bvModalEvt.preventDefault();
      this.isSending = true;
      try {
        await this.transferNFT({
          nftId: this.nftId,
          receiverAddress: this.receiverAddress,
          nftType: this.nftType
        });
        (this.$refs['character-transfer-modal'] as any).hide();
      }
      catch(e: any) {
        if(e.code as number === 4001) this.resultMsg = i18n.t('nftOptionsDropdown.cancelledTransaction').toString();
        else this.resultMsg =
         i18n.t('nftOptionsDropdown.errorTransfer').toString() + ' ' + this.nftType + ' ' +
         i18n.t('nftOptionsDropdown.to').toString() + ' ' + this.receiverAddress;
      }
      this.isSending = false;
    }
  }
});
