


















































































































































import Vue from 'vue';
import { formatDurationFromSeconds } from '../utils/date-time';
import { isStakeType, isNftStakeType, StakeType, NftStakeType } from '../interfaces/State';
import { toBN } from '@/utils/common';
import { secondsToDDHHMMSS } from '../utils/date-time';
import {Multiselect} from 'vue-multiselect';
import {PropType} from 'vue/types/options';
import BN from 'bignumber.js';
import {mapActions, mapState} from 'vuex';
import i18n from '@/i18n';
import { TranslateResult } from 'vue-i18n';
import Events from '../events';
import SkeletonLoader from '../components/SkeletonLoader.vue';
import { stakeTypeThatCanHaveUnclaimedRewardsStakedTo } from '@/stake-types';

interface StoreMappedStakingActions {
  fetchStakeDetails(payload: {stakeType: StakeType | NftStakeType}): Promise<void>;
  stake(payload: {amount: string; stakeType: StakeType | NftStakeType}): void;
  stakeNfts(payload: {ids: string[]; stakeType: StakeType | NftStakeType}): void;
  unstake(payload: {amount: string; stakeType: StakeType | NftStakeType}): void;
  unstakeNfts(payload: {ids: string[]; stakeType: StakeType | NftStakeType}): void;
  unstakeKing(payload: {amount: string;}): Promise<void>;
  claimKingReward(): Promise<void>;
  stakeUnclaimedRewards(payload: {stakeType: StakeType}): Promise<void>;
  claimReward(payload: {stakeType: StakeType | NftStakeType}): Promise<void>;
  getStakedIds(payload: {stakeType: StakeType | NftStakeType}): Promise<LandIds[]>;
}
interface StoreMappedLandActions {
  fetchOwnedLandIdsWithTier(): Promise<LandIds[]>;
}

enum CurrentState {
  CONNECT_WALLET,
  STAKE_LOCKED,
  CONTRACT_FULL,
  AMOUNT_TOO_BIG,
  WAITING,
  INPUT_ZERO,
  INSUFFICIENT_BALANCE,
  NOT_ENOUGH_FUNDS_IN_EXIT_POOL,
  NOT_ENOUGH_FUNDS_IN_EXIT_POOL_FOR_FEE,
  OK,
}

enum RewardClaimState {
  LOADING,
  REWARD_LOCKED,
  OK,
  NO_REWARD,
}

interface LandIds {
  id: string,
  tier: number,
}

interface StakeData {
  contractBalance: string;
  currentRewardEarned: string;
  ownBalance: string;
  remainingCapacityForDeposit: BN;
  remainingCapacityForWithdraw: BN;
  rewardDistributionTimeLeft: number;
  rewardMinimumStakeTime: number;
  stakedBalance: string;
  unlockTimeLeft: number;
}

interface Data {
  textAmount: string,
  idsToStake: LandIds[],
  isOpen: boolean,
  isDeposit: boolean,
  isLoadingClaim: boolean,
  isLoadingStake: boolean,
  isLoadingRestake: boolean,
  startedStaking: boolean,
  rewardClaimLoading: boolean,
  stakeUnlockTimeLeftCurrentEstimate: number,
  stakeRewardDistributionTimeLeftCurrentEstimate: number,
  ownedLandIds: LandIds[],
  stakedIds: LandIds[],
  secondsInterval: ReturnType<typeof setInterval> | null,
  stakeRewardProgressInterval: ReturnType<typeof setInterval> | null,
}

type AllStakeTypes = StakeType | NftStakeType; // PropType<AllStakeTypes>

export default Vue.extend({
  components: {
    Multiselect,
    SkeletonLoader
  },
  props: {
    stakeTitle: {
      type: String,
      required: true,
    },
    stakeType: {
      type: String as PropType<StakeType>,
      required: true,
    },
    stakeTokenName: {
      type: String,
      required: true,
    },
    rewardTokenName: {
      type: String,
      required: true,
    },
    minimumStakeTime: {
      type: Number,
      required: true,
    },
    isLoading:{
      type: Boolean,
      default: true
    },
    estimatedYield: {
      type: BN,
      required: true,
    },
    rewardsDuration: {
      type: Number,
      required: true,
    },
    deprecated: {
      type: Boolean,
    },
    note: {
      type: String
    },
    rewardDistributionTimeLeft: {
      type: Number,
      required: true,
    },
    currentRewardEarned: {
      type: String,
      required: true,
    },
    totalStaked: {
      type: String,
      required: true,
    },
    walletBalance: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      textAmount: '',
      idsToStake: [],
      isOpen: false,
      isDeposit: true,
      isLoadingClaim: false,
      isLoadingStake: false,
      isLoadingRestake: false,
      startedStaking: false,
      rewardClaimLoading: false,
      stakeUnlockTimeLeftCurrentEstimate: 0,
      stakeRewardDistributionTimeLeftCurrentEstimate: 0,
      ownedLandIds: [],
      stakedIds: [],
      secondsInterval: null,
      stakeRewardProgressInterval: null,
      CurrentState,
      RewardClaimState,
    } as Data;
  },
  computed: {
    ...mapState(['defaultAccount']),
    ...mapState('staking', (['staking'])),
    progressBarWidth(): number{
      if(this.minimumStakeTime === 0) return 100;
      return 100 * ((this.minimumStakeTime - this.stakeUnlockTimeLeftCurrentEstimate) / this.minimumStakeTime);
    },
    tier(): number {
      return this.stakeType.startsWith('cbkLand') && + this.stakeType.slice(-1) || 0;
    },
    isNftStaking(): boolean {
      return isNftStakeType(this.stakeType);
    },
    rewardsDurationFormatted(): string {
      return formatDurationFromSeconds(this.rewardsDuration);
    },
    currentRewardEarnedFormatted(): BN {
      return toBN(this.currentRewardEarned).dividedBy(1e18);
    },
    totalStakedFormatted(): string {
      return this.isNftStaking ? this.totalStaked: toBN(this.totalStaked).dividedBy(1e18).toFixed(0);
    },
    walletBalanceFormatted(): string {
      return this.isNftStaking ?
        this.ownedLandIds.filter(land => +land.tier === +this.tier).length.toFixed(3) : toBN(this.walletBalance).dividedBy(1e18).toFixed(3);
    },
    stakedBalanceFormatted(): string {
      return this.isNftStaking ? this.stakedBalance.toFixed(3) : this.stakedBalance.dividedBy(1e18).toFixed(3);
    },
    stakeData(): StakeData {
      return this.staking[this.stakeType];
    },

    rewardDistributionTimeLeftInternal(): number { return this.stakeData.rewardDistributionTimeLeft; },
    unlockTimeLeftInternal(): number { return this.stakeData.unlockTimeLeft; },

    stakingTokenName(): string {
      switch(this.stakeType as AllStakeTypes) {
      case 'skill':
      case 'skill2':
      case 'skill60':
      case 'skill90':
      case 'skill180':
        return 'SKILL';
      case 'king':
      case 'king90':
      case 'king180':
        return 'KING';
      case 'lp':
      case 'lp2':
        return 'SKILL-WBNB';
      case 'lpValor':
        return 'SKILL-VALOR';
      case 'lpValor2':
        return 'KING-VALOR';
      case 'cbkLandT1':
      case 'cbkLandT2':
      case 'cbkLandT3':
        return 'CBKL';
      case 'valor':
        return 'VALOR';
      default:
        return 'unknown';
      }
    },

    minimumStakeTimeFormatted(): string {
      return formatDurationFromSeconds(this.stakeData.rewardMinimumStakeTime);
    },

    estimatedUnlockTimeLeftFormatted(): string {
      return secondsToDDHHMMSS(this.stakeUnlockTimeLeftCurrentEstimate);
    },

    rewardsDepleted(): boolean {
      return this.stakeRewardDistributionTimeLeftCurrentEstimate === 0;
    },

    stakedBalance(): BN{
      return toBN(this.stakeData.stakedBalance);
    },

    remainingCapacityForDeposit(): BN | null {
      if (!this.stakeData.remainingCapacityForDeposit) {
        return null;
      }
      return this.stakeData.remainingCapacityForDeposit;
    },

    remainingCapacityForWithdraw(): BN {
      return this.stakeData.remainingCapacityForWithdraw;
    },

    contractBalance(): BN {
      return toBN(this.stakeData.contractBalance);
    },

    currentState(): CurrentState {
      // no account connect / error
      if (!this.defaultAccount) {
        return CurrentState.CONNECT_WALLET;
      }

      // staked funds still locked
      if (!this.isDeposit && this.unlockTimeLeftInternal > 0) {
        return CurrentState.STAKE_LOCKED;
      }

      // limit of contract reached
      if (
        this.remainingCapacityForDeposit &&
        this.remainingCapacityForDeposit.eq(0) &&
        this.isDeposit
      ) {
        return CurrentState.CONTRACT_FULL;
      }

      // limit of contract will be reached due to deposit
      if (
        this.remainingCapacityForDeposit &&
        this.bigNumberAmount.gt(this.remainingCapacityForDeposit) &&
        this.isDeposit
      ) {
        return CurrentState.AMOUNT_TOO_BIG;
      }

      // no input given
      if (isStakeType(this.stakeType) && +this.textAmount <= 0) {
        return CurrentState.INPUT_ZERO;
      }

      if (isNftStakeType(this.stakeType) && this.idsToStake.length === 0) {
        return CurrentState.INPUT_ZERO;
      }

      const hasSufficientBalance = this.isDeposit
        ? toBN(this.walletBalance).gte(this.bigNumberAmount)
        : this.stakedBalance.gte(this.bigNumberAmount);

      // not enough funds in wallet
      if (!hasSufficientBalance) {
        return CurrentState.INSUFFICIENT_BALANCE;
      }

      // user input bigger than funds in pool
      if (this.bigNumberAmount.gt(this.contractBalance) && !this.isDeposit) {
        return CurrentState.NOT_ENOUGH_FUNDS_IN_EXIT_POOL;
      }

      // user input bigger than funds in pool
      if (
        this.bigNumberAmount.gt(this.remainingCapacityForWithdraw) &&
        !this.isDeposit
      ) {
        return CurrentState.NOT_ENOUGH_FUNDS_IN_EXIT_POOL;
      }

      return CurrentState.OK;
    },

    submitButtonLabel(): TranslateResult {
      switch (this.currentState) {
      case CurrentState.OK:
        return this.isDeposit ? i18n.t('stake.stakeButtonLabel') : i18n.t('stake.unstakeButtonLabel');
      case CurrentState.CONTRACT_FULL:
        return i18n.t('stake.contractIsFullButtonLabel');
      case CurrentState.AMOUNT_TOO_BIG:
        return i18n.t('stake.amountIsTooBigButtonLabel');
      case CurrentState.WAITING:
        return i18n.t('stake.waitingButtonLabel');
      case CurrentState.INPUT_ZERO:
        return i18n.t('stake.enterAnAmountButtonLabel');
      case CurrentState.INSUFFICIENT_BALANCE:
        return i18n.t('stake.insufficientBalanceButtonLabel');
      case CurrentState.NOT_ENOUGH_FUNDS_IN_EXIT_POOL:
        return i18n.t('stake.notEnoughFundsInExitPoolButtonLabel');
      case CurrentState.STAKE_LOCKED:
        return i18n.t('stake.sorryStake', {estimatedUnlockTimeLeftFormatted : this.estimatedUnlockTimeLeftFormatted});
      default:
        return i18n.t('stake.connectToWalletButtonLabel');
      }
    },

    rewardClaimState(): RewardClaimState {
      if (this.isLoadingClaim) {
        return RewardClaimState.LOADING;
      }
      if (this.unlockTimeLeftInternal > 0) {
        return RewardClaimState.REWARD_LOCKED;
      }
      if(!toBN(this.currentRewardEarned).gt(0)){
        return RewardClaimState.NO_REWARD;
      }
      return RewardClaimState.OK;
    },

    claimRewardButtonLabel(): TranslateResult {
      switch (this.rewardClaimState) {
      case RewardClaimState.LOADING:
        return i18n.t('loading');
      case RewardClaimState.REWARD_LOCKED:
        return i18n.t('stake.sorryReward', {estimatedUnlockTimeLeftFormatted : this.estimatedUnlockTimeLeftFormatted});
      default:
        return i18n.t('stake.claimReward');
      }
    },

    bigNumberAmount: {
      get(): BN {
        if (!this.textAmount) return toBN(0);
        return toBN(this.textAmount).multipliedBy(1e18);
      },
      set(newBnAmount: BN): void {
        this.textAmount = newBnAmount.dividedBy(1e18).toString();
      },
    },
    canRestake(): boolean{
      return stakeTypeThatCanHaveUnclaimedRewardsStakedTo === this.stakeType;
    }
  },
  async mounted(){
    this.stakeUnlockTimeLeftCurrentEstimate = this.unlockTimeLeftInternal;
    this.stakeRewardDistributionTimeLeftCurrentEstimate = this.rewardDistributionTimeLeftInternal;

    this.stakeRewardProgressInterval = setInterval(async () => {
      await this.fetchStakeDetails({ stakeType: this.stakeType });

      // set the status of the data as loading is false
      Events.$emit('setLoading', this.stakeType);
    }, 10 * 1000);

    this.secondsInterval = setInterval(() => {
      this.updateEstimates();
    }, 1000);

    await this.fetchData();
    if(this.stakeType.startsWith('cbkLand')) {
      await this.updateOwnedLands();
    }
  },
  beforeDestroy() {
    if(this.stakeRewardProgressInterval) clearInterval(this.stakeRewardProgressInterval);
    if(this.secondsInterval) clearInterval(this.secondsInterval);
  },
  watch: {
    rewardDistributionTimeLeftInternal(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.stakeRewardDistributionTimeLeftCurrentEstimate = newValue;
      }
    },
    unlockTimeLeftInternal(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.stakeUnlockTimeLeftCurrentEstimate = newValue;
      }
    },
    textAmount(newValue, oldVal) {
      if (newValue.length > 40) {
        this.textAmount = oldVal;
        return;
      }
      if (newValue[newValue.length - 1] === 0) {
        this.textAmount = newValue;
        return;
      }
      if (
        newValue[newValue.length - 1] === '.' &&
        newValue[newValue.length - 2] !== '.'
      ) {
        return;
      }
      if (isNaN(newValue)) {
        this.textAmount = oldVal;
        return;
      }
      if (!newValue) {
        this.textAmount = '0';
      }
    },
    isDeposit() {
      this.textAmount = '';
      this.idsToStake = [];
    },
    async defaultAccount(newVal) {
      if (newVal) {
        await this.fetchData();
        if(this.stakeType.startsWith('cbkLand')) {
          await this.updateOwnedLands();
        }
      }
    },
  },
  methods:{
    ...mapActions(
      'staking',
      [
        'getStakedIds',
        'fetchStakeDetails',
        'stake',
        'stakeNfts',
        'unstake',
        'unstakeNfts',
        'unstakeKing',
        'claimKingReward',
        'stakeUnclaimedRewards',
        'claimReward',
      ]) as StoreMappedStakingActions,
    ...mapActions('land', ['fetchOwnedLandIdsWithTier']) as StoreMappedLandActions,

    inputByRatio(ratio: number): any {
      if(this.isDeposit){
        this.textAmount = toBN(this.walletBalance).dividedBy(1/ratio*1e18).toFixed(18);
      }
      else{
        this.textAmount = this.stakedBalance.dividedBy(1/ratio*1e18).toFixed(18);
      }
    },
    idWithTier({ id, tier }: {id: number, tier: number}): string {
      return `${id} (${i18n.t('stake.tier')} ${tier})`;
    },

    updateEstimates(): void {
      if (this.stakeUnlockTimeLeftCurrentEstimate > 0) {
        this.stakeUnlockTimeLeftCurrentEstimate--;
      }

      if (this.stakeRewardDistributionTimeLeftCurrentEstimate > 0) {
        this.stakeRewardDistributionTimeLeftCurrentEstimate--;
      }
    },

    async onSubmit(): Promise<void> {
      if (this.isLoadingStake || this.currentState !== CurrentState.OK) return;
      const amount = this.bigNumberAmount.toString();

      try {
        this.isLoadingStake = true;

        if (this.isDeposit) {
          //stake
          if(this.isNftStaking) {
            await this.stakeNfts({ ids: this.idsToStake.map(x => x.id), stakeType: this.stakeType });
          }
          else {
            await this.stake({ amount, stakeType: this.stakeType });
          }
        } else {
          //unstake
          if(this.stakeType === 'king') {
            await this.unstakeKing({ amount });
          }
          else {
            if(this.isNftStaking) {
              await this.unstakeNfts({ ids: this.idsToStake.map(x => x.id), stakeType: this.stakeType });
            }
            else {
              await this.unstake({ amount, stakeType: this.stakeType });
            }
          }
        }
        if(isNftStakeType(this.stakeType)) {
          await this.updateOwnedLands();
          this.idsToStake = [];
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoadingStake = false;
        this.textAmount = '';
        this.idsToStake = [];
      }
    },
    async onRestake(): Promise<void> {
      try {
        this.isLoadingRestake = true;
        await this.stakeUnclaimedRewards({ stakeType: this.stakeType });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoadingRestake = false;
      }
    },
    async onClaimReward(): Promise<void> {
      if (this.rewardClaimState !== RewardClaimState.OK) return;

      try {
        this.isLoadingClaim = true;

        if(this.stakeType === 'king') {
          await this.claimKingReward();
        }
        else {
          await this.claimReward({ stakeType: this.stakeType });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoadingClaim = false;
      }
    },
    async fetchData(): Promise<void> {
      try {
        await this.fetchStakeDetails({ stakeType: this.stakeType });
      } catch (error) {
        console.error(error);
      }
    },
    async updateOwnedLands(): Promise<void> {
      this.ownedLandIds = await this.fetchOwnedLandIdsWithTier();
      this.stakedIds = await this.getStakedIds({ stakeType: this.stakeType });
    }
  },
});
