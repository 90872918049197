














































































import Vue from 'vue';
import {mapActions} from 'vuex';
import {isValidWeb3Address} from '../../../utils/common';

enum PartnerProperty {
  LOGO, DETAILS, WEBSITE, NOTE, IS_ACTIVE, IS_VALOR
}

interface SelectedPartnerProject {
  id?: number;
  selectedProperty?: PartnerProperty;
  propertyBooleanValue: boolean;
  propertyStringValue: string;
}

export interface NewPartnerProject {
  name: string;
  tokenSymbol: string;
  tokenAddress: string;
  tokenSupply?: number;
  tokensClaimed?: number;
  tokenPrice?: number;
  distributionTime?: number;
  isActive: boolean;
  logo: string;
  details: string;
  website: string;
  note: string;
  isValor: boolean;
}

interface StoreMappedTreasuryActions {
  addPartnerProject(payload: { partnerProject: NewPartnerProject }): Promise<void>;

  getActivePartnerProjects(): Promise<NewPartnerProject[]>;

  setPartnerProjectLogo(payload: { id: number, logo: string }): Promise<void>;

  setPartnerProjectDetails(payload: { id: number, details: string }): Promise<void>;

  setPartnerProjectWebsite(payload: { id: number, website: string }): Promise<void>;

  setPartnerProjectNote(payload: { id: number, note: string }): Promise<void>;

  setPartnerProjectIsActive(payload: { id: number, isActive: boolean }): Promise<void>;

  setPartnerProjectIsValor(payload: { id: number, isValor: boolean }): Promise<void>;
}

interface Data {
  newPartnerProject: NewPartnerProject,
  selectedPartnerProject: SelectedPartnerProject,
  activeProjects: NewPartnerProject[],
  isLoading: boolean;
}

export default Vue.extend({
  data() {
    return {
      newPartnerProject: {
        name: '',
        tokenSymbol: '',
        tokenAddress: '',
        tokenSupply: undefined,
        tokensClaimed: 0,
        tokenPrice: undefined,
        distributionTime: undefined,
        isActive: true,
        logo: '',
        details: '',
        website: '',
        note: '',
        isValor: false
      },
      partnerProjectProperties: [
        PartnerProperty.LOGO,
        PartnerProperty.DETAILS,
        PartnerProperty.WEBSITE,
        PartnerProperty.NOTE,
        PartnerProperty.IS_ACTIVE,
      ],
      selectedPartnerProject: {
        id: undefined,
        selectedProperty: undefined,
        propertyBooleanValue: true,
        propertyStringValue: '',
      },
      activeProjects: [],
      isLoading: false,
      PartnerProperty,
    } as Data;
  },

  computed: {
    addNewPartnerProjectButtonDisabled(): boolean {
      return !isValidWeb3Address(this.newPartnerProject.tokenAddress)
        || this.newPartnerProject.name === ''
        || this.newPartnerProject.tokenSymbol === ''
        || this.newPartnerProject.tokenSupply === undefined
        || this.newPartnerProject.tokensClaimed === undefined
        || this.newPartnerProject.tokenPrice === undefined
        || this.newPartnerProject.distributionTime === undefined
        || this.newPartnerProject.logo === ''
        || this.newPartnerProject.details === ''
        || this.newPartnerProject.website === ''
        || this.isLoading;
    },
    setPartnerProjectPropertyButtonDisabled(): boolean {
      return this.selectedPartnerProject.id === undefined
        || this.selectedPartnerProject.selectedProperty === undefined
        || this.isLoading;
    },
  },

  methods: {
    ...mapActions('treasury',
      [
        'addPartnerProject',
        'getActivePartnerProjects',
        'setPartnerProjectLogo',
        'setPartnerProjectDetails',
        'setPartnerProjectWebsite',
        'setPartnerProjectNote',
        'setPartnerProjectIsActive',
        'setPartnerProjectIsValor'
      ]) as StoreMappedTreasuryActions,

    async getActiveProjects() {
      try {
        this.isLoading = true;
        this.activeProjects = await this.getActivePartnerProjects();
      } finally {
        this.isLoading = false;
      }
    },

    async setPartnerProjectProperty() {
      if (this.selectedPartnerProject.selectedProperty === undefined || this.selectedPartnerProject.id === undefined) return;
      try {
        this.isLoading = true;
        switch (this.selectedPartnerProject.selectedProperty) {
        case PartnerProperty.LOGO:
          await this.setPartnerProjectLogo({
            id: this.selectedPartnerProject.id,
            logo: this.selectedPartnerProject.propertyStringValue
          });
          break;
        case PartnerProperty.DETAILS:
          await this.setPartnerProjectDetails({
            id: this.selectedPartnerProject.id,
            details: this.selectedPartnerProject.propertyStringValue
          });
          break;
        case PartnerProperty.WEBSITE:
          await this.setPartnerProjectWebsite({
            id: this.selectedPartnerProject.id,
            website: this.selectedPartnerProject.propertyStringValue
          });
          break;
        case PartnerProperty.NOTE:
          await this.setPartnerProjectNote({
            id: this.selectedPartnerProject.id,
            note: this.selectedPartnerProject.propertyStringValue
          });
          break;
        case PartnerProperty.IS_ACTIVE:
          await this.setPartnerProjectIsActive({
            id: this.selectedPartnerProject.id,
            isActive: this.selectedPartnerProject.propertyBooleanValue
          });
          break;
        case PartnerProperty.IS_VALOR:
          await this.setPartnerProjectIsValor({
            id: this.selectedPartnerProject.id,
            isValor: this.selectedPartnerProject.propertyBooleanValue
          });
          break;
        }
        this.selectedPartnerProject.id = undefined;
        this.selectedPartnerProject.selectedProperty = undefined;
        this.selectedPartnerProject.propertyBooleanValue = true;
        this.selectedPartnerProject.propertyStringValue = '';
      } finally {
        this.isLoading = false;
      }
    },

    async addNewPartnerProject() {
      if (this.addNewPartnerProjectButtonDisabled) return;
      try {
        this.isLoading = true;
        await this.addPartnerProject({partnerProject: this.newPartnerProject});
        this.clearInputs();
      } finally {
        this.isLoading = false;
      }
    },

    clearInputs() {
      this.newPartnerProject.name = '';
      this.newPartnerProject.tokenSymbol = '';
      this.newPartnerProject.tokenAddress = '';
      this.newPartnerProject.tokenSupply = undefined;
      this.newPartnerProject.tokensClaimed = undefined;
      this.newPartnerProject.tokenPrice = undefined;
      this.newPartnerProject.distributionTime = undefined;
      this.newPartnerProject.logo = '';
      this.newPartnerProject.details = '';
      this.newPartnerProject.website = '';
      this.newPartnerProject.note = '';
    }
  },
});
