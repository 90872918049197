




















































import {portal, pvp, quests, raid} from '@/feature-flags';
import {mapGetters, mapState} from 'vuex';
import Vue from 'vue';

import Hint from '@/components/Hint.vue';

interface Data {
  raid: boolean,
  portal: boolean,
  pvp: boolean,
  quests: boolean,
}

export default Vue.extend({
  data() {
    return {
      raid,
      portal,
      pvp,
      quests,
    } as Data;
  },

  computed: {
    ...mapState(['defaultAccount']),
    ...mapGetters([
      'getCurrentChainSupportsPvP',
      'getCurrentChainSupportsQuests',
      'getHasAdminAccess',
      'getHasMinterAccess',
    ]),
    supportsPvP(): boolean {
      return this.getCurrentChainSupportsPvP;
    },
    supportsQuests(): boolean {
      return this.getCurrentChainSupportsQuests;
    },
    hasAdminAccess(): boolean {
      return this.getHasAdminAccess || this.getHasMinterAccess;
    },
  },
  components: {
    Hint,
  },
  methods: {
    BazaarLink() {
      return process.env.VUE_APP_BAZAAR_URL || 'https://bazaar.market/';
    },
  },
});
