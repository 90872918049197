

































import Vue from 'vue';

export default Vue.extend({
  props: {
    activeTab: {
      type: String,
      default: 'wallet-quests'
    },
  },

});

