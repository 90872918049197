

















































































































































































































































import Vue from 'vue';
import {mapActions, mapGetters, mapState} from 'vuex';
import {isNftType, Nft, NftTransfer, TransferedNft} from '@/interfaces/Nft';
import {Accessors} from 'vue/types/options';
import {Contract, Contracts, IState} from '@/interfaces';
import {NftIdType} from '@/components/smart/NftList.vue';
import {Characters, Shields, Weapons} from '../../../build/abi-interfaces';
import WeaponGrid from '../components/smart/WeaponGrid.vue';
import CharacterList from '../components/smart/CharacterList.vue';
import NftList from '../components/smart/NftList.vue';
import CurrencyConverter from '../components/CurrencyConverter.vue';
import {fromWeiEther, toBN} from '@/utils/common';
import config from '../../app-config.json';
import BigNumber from 'bignumber.js';
import { getConfigValue } from '@/contracts';

type StoreMappedState = Pick<IState, 'defaultAccount'| 'ownedWeaponIds' | 'ownedShieldIds' | 'skillBalance' | 'inGameOnlyFunds' | 'skillRewards'>;

interface StoreMappedGetters {
  contracts: Contracts;
  weaponsWithIds(ids: (string | number)[]): Nft[];
  nftsWithIdType(nftIdType: NftIdType[]): Nft[];
  shieldsWithIds(ids: string[]): Nft[];
  charactersWithIds(ids: (string | number)[]): Nft[];
  ownCharacters: any[];
}
interface StoreMappedActions {
  storeItem(payload: {
    nftContractAddr: string;
    tokenId: number}): Promise<void>;
  getStorageItemIds(payload: {
    nftContractAddr: string;}): Promise<string[]>;
  getNumberOfStoragedItems(payload: {
    nftContractAddr: string;}): Promise<string>;
  withdrawFromStorage(payload: {
    nftContractAddr: string;
    tokenId: number}): Promise<void>;
  getNFTChainId(payload: {
    nftContractAddr: string;
    tokenId: number}): Promise<void>;
  bridgeItem(payload: {
    nftContractAddr: string;
    tokenId: number;
    targetChain: number;
    bridgeFee: string;
  }): Promise<void>;
  getBridgeTransferId(): Promise<string>;
  getBridgeTransfer(payload: {
    transferId: string;
  }): Promise<NftTransfer>;
  cancelBridge(): Promise<void>;
  getReceivedNFTs(): Promise<number[]>;
  getReceivedNFT(payload: {
    tokenId: number;
  }): Promise<TransferedNft>;
  chainEnabled(payload: {
    chainId: number;
  }): Promise<boolean>;
  fetchBridgeFee(): Promise<string>;
  fetchBridgeWithdrawFee(payload: {tokenAddress: string}): Promise<string>;
  fetchIsNftBridged(payload: {tokenAddress: string, tokenId: number | string}): Promise<boolean>;
  fetchBridgeRequestBridgeFee(payload: {tokenAddress: string}): Promise<string>;
}

enum transferStates{
  noTransfer = 'No transfer',
  pending = 'Pending',
  processing = 'Processing',
  done = 'DONE',
  error = 'Error',
  restored = 'Restored'
}

type NftTypeString = 'weapon' | 'shield' | 'character';

export default Vue.extend({
  props: {
    nftTypeProp: {
      type: String,
      validator(type) {
        return isNftType(type);
      },
    },
    nftIdProp: {
      type: String,
    },
  },

  data() {
    return {
      weapon: null as Nft | null,
      character: null as Nft | null,
      shield: null as Nft | null,
      ownerAddress: '',
      nftType: 'weapon',
      selectedNftId: '' as string,
      storedNftsIds: [] as string[],
      currentChain: '',
      targetChain: '',
      targetChainId: 0 as number | null,
      supportedChains: [] as string[],
      supportedChainIds: [] as number[],
      transferStatus: '',
      transferStates,
      currentTransferNFTId: 0 as number | null,
      currentTransferNFTType: '',
      currentTransferChain: '',
      chainsToSendTo: [] as string[],
      incomingNftIds: [] as number[],
      incomingWeapons: [] as TransferedNft[],
      incomingChars: [] as TransferedNft[],
      incomingShields: [] as TransferedNft[],
      weaponIdToWithdraw: '',
      characterIdToWithdraw: '',
      shieldIdToWithdraw: '',
      transferingToStorage: false,
      transferingFromStorage: false,
      cancellingRequest: false,
      withdrawingFromBridge: false,
      enabledChains: [] as string[],
      bridgeFee: '',
      withdrawFee: '',
      bridgeNativeFee: '0',
      loadedStorage: false,
      refreshIntervall: 0 as number,
      isNftBridged: false
    };
  },

  computed: {
    ...(mapState(['defaultAccount','ownedWeaponIds','ownedShieldIds','skillBalance', 'inGameOnlyFunds', 'skillRewards']) as Accessors<StoreMappedState>),
    ...(mapGetters([
      'contracts',
      'weaponsWithIds',
      'nftsWithIdType',
      'shieldsWithIds',
      'charactersWithIds',
      'ownCharacters',
    ]) as Accessors<StoreMappedGetters>),
    isKnownNftType(): boolean {
      return isNftType(this.nftType);
    },

    Weapons(): Contract<Weapons> {
      // we use x! here because we assert that they're set already in created()
      // this helps with typings
      return this.contracts.Weapons!;
    },

    Characters(): Contract<Characters> {
      // we use x! here because we assert that they're set already in created()
      // this helps with typings
      return this.contracts.Characters!;
    },

    Shields(): Contract<Shields> {
      // we use x! here because we assert that they're set already in created()
      // this helps with typings
      return this.contracts.Shields!;
    },

    contractAddress(): string {
      return this.nftType === 'weapon'
        ? this.Weapons.options.address
        : this.nftType === 'character'
          ? this.Characters.options.address
          : this.Shields.options.address;
    },
    canWithdraw(): boolean {
      //check first if weapon/char or other nft
      if(String(this.selectedNftId).split('.').length === 2){
        //is other nft
        const idToCheck = this.selectedNftId.split('.')[1];
        if(this.currentTransferNFTId === +idToCheck && this.transferStatus !== transferStates.restored) return false;
        else return this.selectedNftId !== '';
      }else{
        //is weapon/char
        const idToCheck = this.selectedNftId;
        if(idToCheck === '') return false;
        else if(this.currentTransferNFTId === +this.selectedNftId && this.transferStatus !== transferStates.restored) return false;
        else return this.selectedNftId !== '';
      }
    },
    canBridge(): boolean{
      if (!this.canAffordBridge) return false;

      else if(!this.targetChain) return false;

      else if(!this.enabledChains.length) return false;

      else if(this.nftType === 'shield') {
        if(!this.storedNftsIds.includes(this.selectedNftId.split('.')[1])) return false;
        return this.selectedNftId !== '';
      }
      else if(!this.storedNftsIds.includes(String(this.selectedNftId))) return false;

      else if(this.transferStatus === transferStates.done && this.currentTransferNFTId === +this.selectedNftId) return false;

      else if(this.transferStatus === transferStates.pending || this.transferStatus === this.transferStates.processing) return false;

      else return this.selectedNftId !== '';
    },
    canAffordBridge(){
      const cost = toBN(this.bridgeFee);
      const balance = toBN(this.skillBalance);
      const skillRewards = toBN(this.skillRewards);
      const totalBalance = balance.plus(skillRewards);
      return totalBalance.isGreaterThanOrEqualTo(cost);
    },
    bridgeableCharacters(): number | string[] {
      return this.ownCharacters.filter(c => c.version === 0).map(c => c.id);
    },
    formattedWithdrawFee(): string {
      return `${new BigNumber(this.withdrawFee).div(1e18).toString()} ${getConfigValue('currencySymbol') || 'BNB'}`;
    }
  },
  watch: {
    defaultAccount() {
      this.getBridgeFee();
      this.getIncoming();
    },
    nftType() {
      this.getWithdrawFee();
      this.getRequestBridgeNativeFee();
    },
    selectedNftId() {
      if(this.selectedNftId) {
        this.getIsNftBridged();
      }
      else {
        this.isNftBridged = false;
      }
    }
  },
  created(){
    this.supportedChains = window.location.href.startsWith('https://test') ? config.testSupportedChains : config.supportedChains;

    //remove currentChain from chains to send to
    this.currentChain = localStorage.getItem('currentChain') || 'BNB';
    this.chainsToSendTo = this.supportedChains.filter(item => item !== this.currentChain);

    //check current net by checking url
    const env = 'production'; //const env = 'test';
    const conf = config as any;
    for(let i = 0; i < this.supportedChains.length; i++){
      this.supportedChainIds.push(+conf.environments[env].chains[this.supportedChains[i]].VUE_APP_NETWORK_ID);
    }
  },
  async mounted(){
    if(this.defaultAccount && this.contracts){
      this.getBridgeFee();
      this.getWithdrawFee();
      this.getRequestBridgeNativeFee();
      this.getIncoming();
    }
    await this.showStorage();
    this.refreshIntervall = window.setInterval(async () => await this.showStorage(), 5000);
  },
  beforeDestroy(){
    clearInterval(this.refreshIntervall);
  },
  methods: {
    ...(mapActions([
      'fetchShields',
      'fetchWeapons',
      'fetchCharacters',
      'storeItem',
      'getStorageItemIds',
      'getNumberOfStoragedItems',
      'withdrawFromStorage',
      'getNFTChainId',
      'bridgeItem',
      'getBridgeTransfer',
      'getBridgeTransferId',
      'cancelBridge',
      'getReceivedNFTs',
      'getReceivedNFT',
      'chainEnabled',
      'fetchBridgeFee',
      'fetchBridgeWithdrawFee',
      'fetchIsNftBridged',
      'fetchBridgeRequestBridgeFee'
    ]) as StoreMappedActions),
    convertWeiToSkill(wei: string): string {
      return fromWeiEther(wei);
    },
    showNft(nftType: NftTypeString): void {
      this.nftType = nftType;
      this.selectedNftId = '';
    },
    async transferToStorage(){
      // chars & weapon ids are ints; other nfts are <type.id>
      if(this.nftType !== 'weapon' && this.nftType !== 'character') this.selectedNftId = this.selectedNftId.split('.')[1];

      this.transferingToStorage = true;
      try{
        await this.storeItem({
          nftContractAddr: this.contractAddress,
          tokenId: +this.selectedNftId});
        this.selectedNftId = '';
        this.transferingToStorage = false;
      }
      catch(e){
        this.transferingToStorage = false;
      }
    },
    async withdrawItem(){
      if(this.nftType !== 'weapon' && this.nftType !== 'character') this.selectedNftId = this.selectedNftId.split('.')[1];

      this.transferingFromStorage = true;
      try{
        await this.withdrawFromStorage({
          nftContractAddr: this.contractAddress,
          tokenId: +this.selectedNftId,
        });
        this.transferingFromStorage = false;
        this.selectedNftId = '';
        await this.getStoredIds();
      }
      catch(e){
        console.error(e);
        this.transferingFromStorage = false;
      }
    },
    async cancelAll(){
      this.cancellingRequest = true;
      await this.cancelBridge();
      this.cancellingRequest = false;
      await this.getStatus();
    },
    async getIncoming(){
      this.incomingWeapons = [];
      this.incomingChars = [];
      this.incomingShields = [];
      //get incoming nft ids
      this.incomingNftIds = await this.getReceivedNFTs();
      for(let i = 0; i < this.incomingNftIds.length; i++){
        const incomingNft: TransferedNft  = await this.getReceivedNFT({
          tokenId: +this.incomingNftIds[i]
        });
        incomingNft.targetId = +this.incomingNftIds[i];
        if(incomingNft.nftType === 1) this.incomingWeapons.push(incomingNft);
        if(incomingNft.nftType === 2) this.incomingChars.push(incomingNft);
        if(incomingNft.nftType === 3) this.incomingShields.push(incomingNft);
      }
    },
    async getChainId(tokenId: number){
      return await this.getNFTChainId({
        nftContractAddr: this.contractAddress,
        tokenId: +tokenId,
      });
    },
    async getStatus(){
      const id = await this.getBridgeTransferId();
      const transfer: NftTransfer = await this.getBridgeTransfer({
        transferId: id,
      });

      if(transfer.status === 0){
        this.transferStatus = transferStates.noTransfer;
        this.currentTransferNFTId = null;
        return;
      }
      if(transfer.status === 1){
        this.transferStatus = transferStates.pending;
      }
      else if(transfer.status === 2){
        this.transferStatus = transferStates.processing;
      }
      else if(transfer.status === 3){
        this.transferStatus = transferStates.done;
      }
      else if(transfer.status === 4){
        this.transferStatus = transferStates.error;
      }
      else if(transfer.status === 5){
        this.transferStatus = transferStates.restored;
      }

      this.currentTransferNFTId = transfer.nftId;
      this.currentTransferChain = this.supportedChains[this.supportedChainIds.indexOf(transfer.chainId)];

      const currentTransferTokenAddress: string = transfer.nftAddress;
      if(currentTransferTokenAddress === this.Weapons.options.address) this.currentTransferNFTType = 'weapon';
      else if (currentTransferTokenAddress === this.Characters.options.address) this.currentTransferNFTType = 'character';
      else this.currentTransferNFTType = 'shield';
    },
    async requestBridge(){
      if(this.nftType !== 'weapon' && this.nftType !== 'character') this.selectedNftId = this.selectedNftId.split('.')[1];

      this.transferingFromStorage = true;
      this.targetChainId = this.supportedChainIds[this.supportedChains.indexOf(this.targetChain)];
      try{
        await this.bridgeItem({
          nftContractAddr: this.contractAddress,
          tokenId: +this.selectedNftId,
          targetChain: this.targetChainId,
          bridgeFee: this.bridgeFee,
        });
        await this.getStatus();
        await this.getStoredIds();
        this.selectedNftId = '';
        this.transferingFromStorage = false;
      }
      catch(e){
        this.transferingFromStorage = false;
      }
    },
    async getStoredIds(){
      this.storedNftsIds = await this.getStorageItemIds({
        nftContractAddr: this.contractAddress,
      });
    },
    async showStorage(){
      // check which chains are enabled for transfer
      for (const chainId of this.supportedChainIds){
        if(await this.chainEnabled({chainId})){
          const chain = this.supportedChains[this.supportedChainIds.indexOf(chainId)];
          this.enabledChains.push(chain);
        }
      }
      await this.getStoredIds();
      await this.getStatus();
      this.loadedStorage = true;
    },
    async getBridgeFee(){
      this.bridgeFee = await this.fetchBridgeFee();
    },
    async getWithdrawFee() {
      this.withdrawFee = await this.fetchBridgeWithdrawFee({tokenAddress: this.contractAddress});
    },
    async getIsNftBridged() {
      this.isNftBridged = await this.fetchIsNftBridged({tokenAddress: this.contractAddress, tokenId: this.selectedNftId});
    },
    async getRequestBridgeNativeFee() {
      this.bridgeNativeFee = await this.fetchBridgeRequestBridgeFee({tokenAddress: this.contractAddress});
    },
  },
  components: {
    WeaponGrid,
    CharacterList,
    CurrencyConverter,
    NftList,
  },
});
