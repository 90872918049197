













import Vue from 'vue';
import {mapActions} from 'vuex';

interface StoreMappedActions {
  getRaidXpReward(): Promise<number>;

  setRaidXpReward(payload: { xp: number }): Promise<void>;
}

interface Data {
  newRaidXpReward?: number;
  currentRaidXpReward?: number;
  isLoading: boolean;
}

export default Vue.extend({
  data() {
    return {
      newRaidXpReward: undefined,
      currentRaidXpReward: undefined,
      isLoading: false,
    } as Data;
  },

  computed: {
    setNewRaidXpRewardButtonDisabled(): boolean {
      return this.newRaidXpReward === undefined
        || this.isLoading;
    },
  },

  methods: {
    ...mapActions([
      'getRaidXpReward',
      'setRaidXpReward',
    ]) as StoreMappedActions,

    async setNewRaidXpReward() {
      if (this.setNewRaidXpRewardButtonDisabled) return;
      try {
        this.isLoading = true;
        await this.setRaidXpReward({xp: this.newRaidXpReward!});
        await this.fetchCurrentRaidXpReward();
        this.newRaidXpReward = undefined;
      } finally {
        this.isLoading = false;
      }
    },

    async fetchCurrentRaidXpReward() {
      try {
        this.isLoading = true;
        this.currentRaidXpReward = await this.getRaidXpReward();
      } finally {
        this.isLoading = false;
      }
    }
  },

  async mounted() {
    await this.fetchCurrentRaidXpReward();
  }
});
