














































































































































































































































































import Events from '../events';
import {mapGetters, mapState} from 'vuex';
import BigNumber from 'bignumber.js';
import {Accessors} from 'vue/types/options';
import Vue from 'vue';
import {fromWeiEther, toBN} from '../utils/common';
import {nft_bridge as bridgeEnabled} from './../feature-flags';
import {SupportedProject} from '@/views/Treasury.vue';
import Hint from '@/components/Hint.vue';
import { portal, pvp, quests, raid} from '@/feature-flags';

interface StoreMappedState {
  skillRewards: string;
  directStakeBonusPercent: number;
}

interface Data {
  isBridgeEnabled: boolean;
  showGraphics: boolean;
  hideRewards: boolean;
  hideWalletWarning: boolean;
  showSkillInUsd: boolean;
  isMenuOpen: boolean;
}

interface StoreMappedGetters {
  rewardsClaimTaxAsFactorBN: BigNumber;
  maxRewardsClaimTaxAsFactorBN: BigNumber;
  getPartnerProjects: SupportedProject[];
}


enum ClaimStage {
  WaxBridge = 0,
  Stake = 1,
  Claim = 2
}

export default Vue.extend({
  components: {Hint},
  created() {
    this.showGraphics = localStorage.getItem('useGraphics') === 'true';
    this.hideRewards = localStorage.getItem('hideRewards') === 'true';
    this.hideWalletWarning = localStorage.getItem('hideWalletWarning') === 'true';
    this.showSkillInUsd = localStorage.getItem('showSkillInUsd') === 'true';
  },

  data() {
    return {
      isBridgeEnabled: bridgeEnabled,
      isMenuOpen: false,
      showGraphics: false,
      hideRewards: false,
      hideWalletWarning: false,
      showSkillInUsd: false,
      ClaimStage,
      raid,
      portal,
      pvp,
      quests,
    } as Data;
  },

  watch: {
    $route() {
      this.isMenuOpen = false;
    }
  },
  computed: {
    ...(mapState(['skillRewards', 'directStakeBonusPercent']) as Accessors<StoreMappedState>),
    ...(mapGetters(['rewardsClaimTaxAsFactorBN', 'maxRewardsClaimTaxAsFactorBN']) as Accessors<StoreMappedGetters>),
    ...mapGetters([
      'getCurrentChainSupportsPvP',
      'getCurrentChainSupportsQuests',
      'getCurrentChainSupportsDrawbridge',
      'getHasAdminAccess',
      'getHasMinterAccess',
    ]),
    ...mapGetters('treasury', ['getPartnerProjects']),
    supportsPvP(): boolean {
      return this.getCurrentChainSupportsPvP;
    },
    supportsQuests(): boolean {
      return this.getCurrentChainSupportsQuests;
    },
    supportsDrawbridge(): boolean {
      return this.getCurrentChainSupportsDrawbridge;
    },
    hasAdminAccess(): boolean {
      return this.getHasAdminAccess || this.getHasMinterAccess;
    },
    formattedSkillReward(): string {
      const skillRewards = fromWeiEther(this.skillRewards);
      return `${toBN(skillRewards).toFixed(4)}`;
    },
    formattedTaxAmount(): string {
      const skillRewards = fromWeiEther(parseFloat(String(parseFloat(this.skillRewards) * parseFloat(String(this.rewardsClaimTaxAsFactorBN)))) + '');
      return `${toBN(skillRewards).toFixed(4)}`;
    },
    formattedRewardsClaimTax(): string {
      const frac =
        this.skillRewards === '0'
          ? this.maxRewardsClaimTaxAsFactorBN
          : this.rewardsClaimTaxAsFactorBN;
      return `${frac.multipliedBy(100).decimalPlaces(0, BigNumber.ROUND_HALF_UP)}%`;
    },
    formattedBonusLost(): string {
      const skillLost = fromWeiEther(parseFloat(String(parseFloat(this.skillRewards) * this.directStakeBonusPercent / 100)).toString());
      return `${toBN(skillLost).toFixed(4)}`;
    },
    canClaimTokens(): boolean {
      return !toBN(this.skillRewards).lte(0);
    },
  },

  methods: {
    BazaarLink() {
      return process.env.VUE_APP_BAZAAR_URL || 'https://bazaar.market/';
    },

    DrawbridgeLink() {
      if (!this.supportsDrawbridge) {
        return;
      }
      return process.env.VUE_APP_DRAWBRIDGE_URL || 'https://drawbridge.cryptoblades.io/';
    },
    openMenu() {
      this.isMenuOpen = true;
    },

    closeMenu() {
      this.isMenuOpen = false;
    },

    toggleRewards() {
      this.hideRewards = !this.hideRewards;
      if (this.hideRewards) localStorage.setItem('hideRewards', 'true');
      else localStorage.setItem('hideRewards', 'false');

      Events.$emit('setting:hideRewards', {value: this.hideRewards});
    },
    async claimSkill(stage: ClaimStage) {
      if (stage === ClaimStage.WaxBridge) {
        (this.$refs['need-gas-modal'] as any).show();
      }
      if (stage === ClaimStage.Stake) {
        (this.$refs['stake-suggestion-modal'] as any).show();
      }
      if (stage === ClaimStage.Claim) {
        (this.$refs['stake-suggestion-modal'] as any).hide();
        (this.$refs['claim-confirmation-modal'] as any).show();
      }
    },

    toggleHideWalletWarning() {
      this.hideWalletWarning = !this.hideWalletWarning;
      if (this.hideWalletWarning) localStorage.setItem('hideWalletWarning', 'true');
      else localStorage.setItem('hideWalletWarning', 'false');

      Events.$emit('setting:hideWalletWarning', {value: this.hideWalletWarning});
    },

    toggleShowSkillInUsd() {
      this.showSkillInUsd = !this.showSkillInUsd;
      if (this.showSkillInUsd) localStorage.setItem('showSkillInUsd', 'true');
      else localStorage.setItem('showSkillInUsd', 'false');

      Events.$emit('setting:showSkillInUsd', {value: this.showSkillInUsd});
    },

    currentChainSupportsClaimTokens() {
      return (localStorage.getItem('currentChain') || 'BNB') !== 'BNB';
    },
  }
});
