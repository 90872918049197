










































































import Vue from 'vue';


export default Vue.extend({
  props: {
    genesisSoulBalance: {
      type: Number,
      default: 0
    },
    nonGenesisSoulBalance: {
      type: Number,
      default: 0
    },
    activeTab: {
      type: String,
      default: 'info'
    },
    havePlazaCharacters: {
      type: Boolean,
      default: false
    },
    ownCharacters: {
      type: Array,
      default: ()=> []
    },
    recruitCost: {
      type: String,
      default: '0'
    }
  },

});

