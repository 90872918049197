











import {PropType} from 'vue/types/options';

type Placement =
'auto' | 'auto-start' | 'auto-end' |
'top' | 'top-start' | 'top-end' |
'right' | 'right-start' | 'right-end' |
'bottom' | 'bottom-start' | 'bottom-end'|
'left' | 'left-start' | 'left-end';

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    placement: {
      type: Object as PropType<Placement>,
    },
  },
};
