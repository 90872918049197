
































import Vue from 'vue';
import {PropType} from 'vue/types/options';
import {
  Quest,
  QuestItemsInfo,
  RewardType } from '@/interfaces';
import {
  Rarity,
  RequirementType,
  DustRarity } from '@/enums/Quest';
import QuestComponentIcon from './QuestComponentIcon.vue';
import {questItemTypeSupportsStars, questItemTypeSupportsTimesValue} from '@/utils/common';
import {mapActions} from 'vuex';
import questItemsInfo from '@/data/questItems.json';
import i18n from '@/i18n';

interface StoreMappedActions {
  isExternalCurrency(payload: { currencyAddress: string }): Promise<boolean>;
}

export default Vue.extend({
  components: {QuestComponentIcon},
  props: {
    quest: {
      type: Object as PropType<Quest>,
      required: true,
    },
    index: {
      type: Number as PropType<number | string>,
    },
    progress: {
      type: Number,
    },
  },

  data() {
    return {
      isCurrency: false,
      RequirementType,
      RewardType,
      Rarity,
      questItemTypeSupportsTimesValue,
      questItemTypeSupportsStars,
    };
  },

  computed: {
    requirementName(): string {
      if (this.quest.requirementType === RequirementType.EXTERNAL || this.quest.requirementType === RequirementType.EXTERNAL_HOLD) {
        if (this.quest?.requirementExternalAddress === undefined) return '';
        return (questItemsInfo as QuestItemsInfo).questItems[this.quest.requirementExternalAddress]?.name;
      } else if (this.quest.requirementType === RequirementType.DUST) {
        if(this.quest.requirementRarity === undefined) return '';
        return i18n.t(`quests.dustRarityType.${DustRarity[this.quest.requirementRarity]}`).toString() + ' ' +
          i18n.t(`quests.requirementType.${RequirementType[this.quest.requirementType]}`).toString();
      } else {
        if (!this.quest?.requirementType) return '';
        return i18n.t(`quests.requirementType.${RequirementType[this.quest.requirementType]}`).toString();
      }
    },
    externalTooltip(): string {
      if (!this.quest?.requirementExternalAddress) return '';
      return (questItemsInfo as QuestItemsInfo).questItems[this.quest.requirementExternalAddress]?.description;
    },
    externalWebsite(): string {
      if (!this.quest?.requirementExternalAddress) return '';
      return (questItemsInfo as QuestItemsInfo).questItems[this.quest.requirementExternalAddress]?.website;
    },
  },

  methods: {
    ...mapActions([
      'isExternalCurrency',
    ]) as StoreMappedActions,

    getRarityColor(rarity: Rarity) {
      switch (rarity) {
      case Rarity.LEGENDARY: {
        return 'background-color: #D16100';
      }
      case Rarity.EPIC: {
        return 'background-color: #7C1EC1';
      }
      case Rarity.RARE: {
        return 'background-color: #7ba224';
      }
      case Rarity.UNCOMMON: {
        return 'background-color: #3997F5';
      }
      case Rarity.COMMON: {
        return 'background-color: #43506A';
      }
      default: {
        return 'background-color: #43506A';
      }
      }
    }
  },

  async mounted() {
    if (this.quest.requirementExternalAddress) {
      this.isCurrency = await this.isExternalCurrency({currencyAddress: this.quest.requirementExternalAddress});
    }
  }


});
