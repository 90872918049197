

















import Vue from 'vue';
import Updates from '@/views/Updates.vue';
import { apiUrl } from '@/utils/common';
import { INotification } from '@/interfaces';

interface Data {
  unreadNotifications: boolean,
  readAll: boolean,
  apiNotifications: INotification[],
  updateNotifications: INotification[]
}

export default Vue.extend({
  data() {
    return {
      unreadNotifications: false,
      readAll: false,
      apiNotifications: [],
      updateNotifications: []
    } as Data;
  },
  methods: {
    refreshUpdatePopup() {
      if (this.unreadNotifications) {
        this.unreadNotifications = false;
      }
    },

    getUpdateNotificationsFromStorage() {
      const storageNotifications = localStorage.getItem('updateNotifications') ?? '';
      if (storageNotifications) {
        const {updateNotifications, readAll} = JSON.parse(storageNotifications);
        this.updateNotifications = updateNotifications;
        this.readAll = readAll;
      }
    },

    async checkForNewUpdateNotifications() {
      this.getUpdateNotificationsFromStorage();
      if (this.updateNotifications.length === 0 || this.updateNotifications.find((x) => x.isRead !== true)) {
        this.unreadNotifications =  true;
        return;
      }
      if (this.apiNotifications[0].hash !== this.updateNotifications[0].hash) {
        this.unreadNotifications =  true;
        return;
      }
      this.unreadNotifications =  false;
    },

    async getUpdateNotificationsFromAPI() {
      const response = await fetch(apiUrl('static/notifications'));
      const notifications = await response.json() as INotification[];
      const updatesOrderedByTimestamp = notifications.sort((a, b) => {
        return b.timestamp - a.timestamp;
      });
      const topNotifications = updatesOrderedByTimestamp.slice(0, 10);
      return topNotifications;
    },

  },
  async created() {
    this.apiNotifications = await this.getUpdateNotificationsFromAPI();
    await this.checkForNewUpdateNotifications();
  },
  components: {
    Updates,
  },
});
