












import Vue from 'vue';
import {PropType} from 'vue/types/options';
import { QuestItemsInfo } from '@/interfaces';
import {
  DustRarity,
  QuestItemType,
  Rarity } from '@/enums/Quest';
import lesserDust from '@/assets/dusts/lesserDust.png';
import greaterDust from '@/assets/dusts/greaterDust.png';
import powerfulDust from '@/assets/dusts/powerfulDust.png';
import soul from '@/assets/dusts/soulDust.png';
import sword from '@/assets/placeholder/sword-placeholder-1.png';
import junk from '@/assets/junk/junk3.png';
import reputation from '@/assets/reputation.png';
import stamina from '@/assets/stamina.png';
import raid from '@/assets/raid.png';
import experience from '@/assets/experience.png';
import shield from '@/assets/shield2.png';
import character from '@/assets/characters/Archer.png';
import trinket from '@/assets/trinkets/trinket1.png';
import questItemsInfo from '@/data/questItems.json';
import {questItemTypeSupportsStars} from '@/utils/common';
import i18n from '@/i18n';

interface Data {
  images: any;
  stars: string;
  icon?: string;
  tooltip: string;
}

export default Vue.extend({
  props: {
    questItemType: {
      type: Number as PropType<QuestItemType>,
      required: true,
    },
    amount: {
      type: Number as PropType<number>,
      required: true,
    },
    rarity: {
      type: Number as PropType<Rarity>,
    },
    externalAddress: {
      type: String as PropType<string>,
    },
  },

  data() {
    return {
      images: require.context('../../assets/partners/', false, /\.png$/),
      stars: '',
      icon: undefined,
      tooltip: '',
      QuestItemType,
    } as Data;
  },

  methods: {
    imgPath(img: string): string {
      return this.images('./' + img);
    },
    getTooltip() {
      if (this.questItemType === QuestItemType.DUST) {
        this.tooltip = i18n.t(`quests.dustRarityType.${DustRarity[this.rarity]}`)
          + ' ' + i18n.t(`quests.questItemType.${QuestItemType[this.questItemType]}`);
      } else if (this.questItemType === QuestItemType.EXTERNAL || this.questItemType === QuestItemType.EXTERNAL_HOLD) {
        this.tooltip = `
        - ${(questItemsInfo as QuestItemsInfo).questItems[this.externalAddress]?.name} -
        ${(questItemsInfo as QuestItemsInfo).questItems[this.externalAddress]?.website}
        ${(questItemsInfo as QuestItemsInfo).questItems[this.externalAddress]?.description}
        `;
      } else {
        this.tooltip = i18n.t(`quests.questItemType.${QuestItemType[this.questItemType]}`).toString();
      }
    }
  },

  mounted() {
    if (this.questItemType === QuestItemType.REPUTATION) {
      this.icon = reputation;
    } else if (this.questItemType === QuestItemType.WEAPON) {
      this.icon = sword;
    } else if (this.questItemType === QuestItemType.JUNK) {
      this.icon = junk;
    } else if (this.questItemType === QuestItemType.DUST) {
      if (this.rarity === Rarity.COMMON) {
        this.icon = lesserDust;
      } else if (this.rarity === Rarity.UNCOMMON) {
        this.icon = greaterDust;
      } else if (this.rarity === Rarity.RARE) {
        this.icon = powerfulDust;
      }
    } else if (this.questItemType === QuestItemType.TRINKET) {
      this.icon = trinket;
    } else if (this.questItemType === QuestItemType.SHIELD) {
      this.icon = shield;
    } else if (this.questItemType === QuestItemType.EXPERIENCE) {
      this.icon = experience;
    } else if (this.questItemType === QuestItemType.STAMINA) {
      this.icon = stamina;
    } else if (this.questItemType === QuestItemType.SOUL) {
      this.icon = soul;
    } else if (this.questItemType === QuestItemType.RAID) {
      this.icon = raid;
    } else if (this.questItemType === QuestItemType.CHARACTER) {
      this.icon = character;
    } else if (this.questItemType === QuestItemType.EXTERNAL || this.questItemType === QuestItemType.EXTERNAL_HOLD) {
      const fileName = (questItemsInfo as QuestItemsInfo).questItems[this.externalAddress]?.image;
      if (fileName) {
        this.icon = this.imgPath(fileName);
      }
    }
    if (questItemTypeSupportsStars(this.questItemType)) {
      this.stars = Array(this.rarity + 1).fill('★').join('');
    }
    this.getTooltip();
  },

});
