


























































































































































































import Vue from 'vue';
import Events from '../../events';
import gasp from 'gsap';
import { Accessors, PropType } from 'vue/types/options';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { IState, IWeapon } from '../../interfaces';
import WeaponIcon from '../WeaponIcon.vue';
import { NftOption } from '../NftOptionsDropdown.vue';
import { BModal } from 'bootstrap-vue';
import { getCleanName, isProfaneIsh } from '../../rename-censor';
import NftOptionsDropdown from '../NftOptionsDropdown.vue';
import i18n from '@/i18n';
import { copyNftUrl } from '@/utils/common';
import {WeaponCosmetic} from '@/enums/WeaponCosmetic';
import { ICombatState } from '@/store/combat';
type StoreMappedState = Pick<IState, 'ownedWeaponIds'>;
interface Skin {
  id: number;
  name?: string;
  amount: number;
}
interface StoreMappedGetters {
  weaponsWithIds(weaponIds: (string | number)[]): IWeapon[];
  getWeaponDurability(state: ICombatState): number;
}
interface StoreMappedActions {
  fetchWeapons(weaponIds: string[]): Promise<void>;
  renameWeapon(arg: {id: number, name: string}): Promise<void>;
  fetchTotalWeaponRenameTags(): Promise<number>;
  fetchOwnedWeaponCosmetics(arg: { cosmetic: number }): Promise<number>;
  changeWeaponCosmetic(arg: { id: number, cosmetic: number }): Promise<void>;
  removeWeaponCosmetic(arg: { id: number }): Promise<void>;
}
interface Data {
  starFilter: string | number;
  elementFilter: string;
  minPriceFilter: string;
  maxPriceFilter: string;
  favorites: Record<number, boolean>;
  priceSort: string;
  showReforgedWeapons: boolean;
  showFavoriteWeapons: boolean;
  options: NftOption[];
  haveRename: number;
  weaponRename: string;
  haveWeaponCosmetics: number[];
  targetSkin: string;
  currentWeaponId: number | string | null;
  activePage: number;
  pageSet: number[];
  noOfPages: number;
  noOfItemsPerRow: number;
  ItemPerPage: number;
  selectWeaponsBtnLabel: string;
  mobileSelectAllBool: boolean;
}

type Selectable = 'none' | 'single' | 'multiple';

const sorts = [
  { name: i18n.t('weaponGrid.sorts.any'), dir: '' },
  { name: i18n.t('weaponGrid.sorts.lowToHigh'), dir: 1 },
  { name: i18n.t('weaponGrid.sorts.highToLow'), dir: -1 },
];

export default Vue.extend({
  model: {
    prop: 'highlight',
    event: 'choose-weapon',
  },
  props: {
    highlight: {
      // this forces Typescript to consider a prop a certain type
      // without us specifying a "type" property;
      // Vue's "type" property is not as flexible as we need it here
      validator(x: string | number | null) {
        void x;
        return true;
      },
      default: null,
    },
    titleType:{
      type: String,
      default: ''
    },
    ignore: {
      type: Array as PropType<string[]>,
      default() {
        return [];
      },
    },
    noPagination: {
      type: Boolean,
      default: false,
    },
    showGivenWeaponIds: {
      type: Boolean,
      default: false,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    weaponIds: {
      type: Array as PropType<string[]>,
      default() {
        return [];
      },
    },
    showLimit: {
      type: Number,
      default: 0,
    },
    showReforgedToggle: {
      type: Boolean,
      default: true,
    },
    showReforgedWeaponsDefVal: {
      type: Boolean,
      default: true,
    },
    showFavoriteToggle: {
      type: Boolean,
      default: true,
    },
    showFavoriteWeaponsDefVal: {
      type: Boolean,
      default: true,
    },
    canFavorite: {
      type: Boolean,
      default: true,
    },
    isMarket: {
      type: Boolean,
      default: false
    },
    checkForDurability: {
      type: Boolean,
      default: false,
    },
    newWeapon: {
      type: Boolean,
      default: false,
    },
    showNftOptions: {
      type: Boolean,
      default: false
    },
    ownWeapons:{
      type: Number,
      default: 0
    },
    starsOptions: {
      type: Array as PropType<(string | number)[]>,
      default() {
        return ['', 1, 2, 3, 4, 5];
      },
    },
    chosenStarsOption: {
      type: [String, Number],
    },
    selectable: {
      type: String as PropType<Selectable>,
      default: 'none',
    },
  },
  data() {
    return {
      starFilter: this.starsOptions?.length === 1 ? this.starsOptions[0] : '',
      elementFilter: '',
      minPriceFilter:'',
      maxPriceFilter:'',
      favorites: {},
      priceSort: '',
      sorts,
      showReforgedWeapons: this.showReforgedWeaponsDefVal,
      showFavoriteWeapons: this.showFavoriteWeaponsDefVal,
      options: [],
      haveRename: 0,
      weaponRename: '',
      haveWeaponCosmetics: [],
      targetSkin: '',
      currentWeaponId: null,
      noOfItemsPerRow: 0,
      // data for paginations
      activePage: 1,
      pageSet: [],
      noOfPages: 0,
      ItemPerPage: 20,
      selectWeaponsBtnLabel: (this as any).$t('weaponGrid.selectAll'),
      mobileSelectAllBool: false,
      WeaponCosmetic
    } as Data;
  },
  components: {
    WeaponIcon,
    NftOptionsDropdown,
  },
  computed: {
    ...(mapState(['ownedWeaponIds']) as Accessors<StoreMappedState>),
    ...(mapGetters(['weaponsWithIds', 'getWeaponDurability']) as Accessors<StoreMappedGetters>),
    weaponIdsToDisplay(): string[] {
      if (this.showGivenWeaponIds) {
        return this.weaponIds;
      }
      return this.ownedWeaponIds?.map((id) => id.toString());
    },
    displayWeapons(): IWeapon[] {
      return this.weaponsWithIds(this.weaponIdsToDisplay).filter(Boolean);
    },
    nonIgnoredWeapons(): IWeapon[] {
      if (this.newWeapon) {
        return this.displayWeapons;
      }
      let items: IWeapon[] = [];
      this.displayWeapons.forEach((x) => items.push(x));
      const allIgnore: string[] = [];
      if (!this.showFavoriteWeapons) {
        for (const key in this.favorites) {
          allIgnore.push(key);
        }
      }
      items = items.filter((x) => allIgnore.findIndex((y) => y === x.id.toString()) < 0);
      if (this.starFilter) {
        items = items.filter((x) => x.stars === +this.starFilter - 1);
      }
      if (this.elementFilter) {
        items = items.filter((x) => x.element.includes(this.elementFilter));
      }
      if (!this.showReforgedWeapons) {
        items = items.filter((x) => x.bonusPower === 0);
      }
      if (this.showLimit > 0 && items.length > this.showLimit) {
        items = items.slice(0, this.showLimit);
      }
      const favoriteWeapons: IWeapon[] = [];
      for (const key in this.favorites) {
        const i = items.findIndex((y) => y.id === +key);
        if (i !== -1) {
          favoriteWeapons.push(items[i]);
          items.splice(i, 1);
        }
      }
      return favoriteWeapons.concat(items);
    },
    isRenameProfanish(): boolean {
      return isProfaneIsh(this.weaponRename);
    },
    cleanRename(): string {
      return getCleanName(this.weaponRename);
    },
    availableSkins(): Skin[] {
      const availableSkins = [];

      availableSkins.push({
        id: 0,
        name: 'No skin',
        amount: 1
      });

      for(let i = 0; i < 19; i++) {
        if(+this.haveWeaponCosmetics[i] > 0) {
          availableSkins.push({
            id: i + 1,
            amount: +this.haveWeaponCosmetics[i]
          });
        }
      }
      return availableSkins;
    },
    totalCosmeticChanges(): number {
      let count = 0;
      this.haveWeaponCosmetics.forEach(x => count += +x);
      return count;
    },
  },
  watch: {
    nonIgnoredWeapons(data){
      this.$emit('currentFilteredWeapons', data);
    },
    async weaponIdsToDisplay(newWeaponIds: string[]) {
      await this.fetchWeapons(newWeaponIds);
      await this.createPagination(this.activePage);
    },
    starFilter(){
      this.createPagination(1);
    },
    ItemPerPage(){
      this.createPagination(1);
    }
  },
  methods: {
    ...(mapActions(['fetchWeapons','renameWeapon','fetchTotalWeaponRenameTags',
      'fetchOwnedWeaponCosmetics','changeWeaponCosmetic','removeWeaponCosmetic']) as StoreMappedActions),
    ...(mapMutations(['setCurrentWeapon'])),
    saveFilters() {
      if(this.isMarket) {
        sessionStorage.setItem('market-weapon-starfilter', this.starFilter.toString());
        sessionStorage.setItem('market-weapon-elementfilter', this.elementFilter);
        sessionStorage.setItem('market-weapon-price-order', this.priceSort);
        sessionStorage.setItem('market-weapon-price-minfilter', this.minPriceFilter?''+this.minPriceFilter:'');
        sessionStorage.setItem('market-weapon-price-maxfilter', this.maxPriceFilter?''+this.maxPriceFilter:'');
      } else {
        sessionStorage.setItem('weapon-starfilter', this.starFilter.toString());
        sessionStorage.setItem('weapon-elementfilter', this.elementFilter);
      }
      this.$emit('weapon-filters-changed');
    },
    toggleFavorite(e: Event, weaponId: number) {
      e.preventDefault();
      if (this.favorites[weaponId]) {
        this.$delete(this.favorites, weaponId);
      } else {
        this.$set(this.favorites, weaponId, true);
      }
      localStorage.setItem('favorites', this.getFavoritesString(this.favorites));
      Events.$emit('weapon:newFavorite', { value: weaponId });
    },
    getFavoritesString(favorites: Record<number, boolean>): string {
      return JSON.stringify(favorites);
    },
    getFavoritesMap(favorites: string): Record<number, boolean> {
      if (!favorites) {
        return {};
      }
      const favoritesMap: Record<number, boolean> = {};
      favorites.split(',').forEach((x) => (favoritesMap[+x] = true));
      return favoritesMap;
    },
    isFavorite(weaponId: number): boolean {
      return this.favorites[weaponId];
    },
    setBorderSelected(weaponId: number): boolean{
      let bols = false;
      if(this.ignore){
        this.ignore.forEach((x: any) => {
          if(Number(x) === weaponId){
            bols = true;
          }
        });
      }
      return bols;
    },
    clearFilters() {
      if(this.isMarket) {
        sessionStorage.removeItem('market-weapon-starfilter');
        sessionStorage.removeItem('market-weapon-elementfilter');
        sessionStorage.removeItem('market-weapon-price-order');
        sessionStorage.removeItem('market-weapon-price-minfilter');
        sessionStorage.removeItem('market-weapon-price-maxfilter');
      } else {
        sessionStorage.removeItem('weapon-starfilter');
        sessionStorage.removeItem('weapon-elementfilter');
      }
      this.elementFilter = '';
      this.starFilter = this.starsOptions?.length === 1 ? this.starsOptions[0] : '';
      this.priceSort = '';
      this.minPriceFilter= '';
      this.maxPriceFilter= '';
      this.$emit('weapon-filters-changed');
    },
    onWeaponClick(id: number) {
      this.$emit('hasSelected');
      this.setCurrentWeapon(id);
      this.$emit('chooseweapon', id);
      this.$emit('choose-weapon', id);
    },
    checkStorageFavorite() {
      const favoritesFromStorage = localStorage.getItem('favorites');
      if (favoritesFromStorage) {
        this.favorites = JSON.parse(favoritesFromStorage);
      }
    },
    openRenameWeapon(id: number | string) {
      this.currentWeaponId = id;
      (this.$refs['weapon-rename-modal'] as BModal).show();
    },
    showFilter(){
      (this.$refs['open-filter'] as BModal).show();
    },
    async renameWeaponCall() {
      if(!this.currentWeaponId) return;
      await this.renameWeapon({id: +this.currentWeaponId, name: this.weaponRename});
      this.haveRename = await this.fetchTotalWeaponRenameTags();
      this.updateOptions();
      this.weaponRename = '';
    },
    async loadCosmeticsCount() {
      this.haveWeaponCosmetics = [];
      for(let i = 1; i < 22; i++) {
        this.haveWeaponCosmetics.push(await this.fetchOwnedWeaponCosmetics({cosmetic: i}));
      }
      this.updateOptions();
    },
    openChangeSkin(id: number | string) {
      this.currentWeaponId = id;
      (this.$refs['weapon-change-skin-modal'] as BModal).show();
    },
    async changeWeaponSkinCall() {
      if(!this.currentWeaponId) return;
      if(+this.targetSkin === 0) {
        await this.removeWeaponCosmetic({ id: +this.currentWeaponId });
        await this.loadCosmeticsCount();
      } else {
        await this.changeWeaponCosmetic({ id: +this.currentWeaponId, cosmetic: +this.targetSkin });
        this.haveWeaponCosmetics[+this.targetSkin] = await this.fetchOwnedWeaponCosmetics({cosmetic: +this.targetSkin});
        await this.loadCosmeticsCount();
      }
      this.updateOptions();
    },
    updateOptions() {
      if(!this.isMarket) {
        this.options = [
          {
            name: i18n.t('characterList.rename').toString(),
            amount: this.haveRename,
            handler: this.openRenameWeapon
          },
          {
            name: i18n.t('characterList.changeSkin').toString(),
            amount: this.totalCosmeticChanges,
            handler: this.openChangeSkin,
            hasDefaultOption: true
          },
        ];
      } else {
        this.options = [
          {
            name: i18n.t('copyLink').toString(),
            amount: 0,
            handler: copyNftUrl,
            hasDefaultOption: true,
            noAmount: true
          },
        ];
      }
    },
    createPagination(activePage: number){
      const noOfItems = this.nonIgnoredWeapons.length;
      this.activePage = activePage;
      this.noOfPages = Math.ceil(noOfItems/this.ItemPerPage);
      this.pageSet = [];
      if(this.noOfPages > 5){
        if(activePage > 3){
          if(this.noOfPages - this.activePage < 3){
            for (let a = 3; a >= 0; a--) {
              this.pageSet.push(this.noOfPages - a);
            }
          }else{
            this.pageSet.push(activePage - 1);
            this.pageSet.push(activePage);
            this.pageSet.push(activePage + 1);
          }
        }else{
          for (let a = 1; a < 5; a++) {
            this.pageSet.push(a);
          }
        }
      }else{
        for (let x = 0; x < this.noOfPages; x++) {
          this.pageSet.push(x+1);
        }
      }
    },
    startList(){
      return ((this.noOfPages > 6 && this.activePage > 3 ) || ((this.noOfPages > 5) && (this.noOfPages - this.activePage < 3)));
    },
    endList(){
      return ((this.noOfPages > 6 && (this.noOfPages - this.activePage > 2)) || (this.noOfPages > 5) && (this.activePage < 4));
    },

    enter(el: any, done: any) {
      gasp.to(el, {
        opacity: 1,
        y: 0,
        duration: 0.5,
        onComplete: done,
        delay: el.dataset.index * 0.1
      });
    },
    beforeEnter(el: any){
      el.style.opacity = 0;
      el.style.transform = 'translateY(100px)';
    },
    onSelect(id: number): boolean {
      switch (this.selectable) {
      default: {
        return false;
      }
      case 'single': {
        return (this.highlight === id);
      }
      case 'multiple': {
        return this.setBorderSelected(id);
      }
      }
    }
  },
  async mounted() {
    this.$root.$on('select-all-button-labeler', (bool: boolean) => {
      this.mobileSelectAllBool = bool;
      if(bool){
        this.selectWeaponsBtnLabel = (this as any).$t('weaponGrid.deSelectAll');
      }else{
        this.selectWeaponsBtnLabel = (this as any).$t('weaponGrid.selectAll');
      }
    });
    this.checkStorageFavorite();
    Events.$on('weapon:newFavorite', () => this.checkStorageFavorite());
    if(this.isMarket) {
      this.starFilter = sessionStorage.getItem('market-weapon-starfilter') || '';
      this.elementFilter = sessionStorage.getItem('market-weapon-elementfilter') || '';
      this.priceSort = sessionStorage.getItem('market-weapon-price-order') || '';
      this.minPriceFilter = sessionStorage.getItem('market-weapon-price-minfilter') || '';
      this.maxPriceFilter = sessionStorage.getItem('market-weapon-price-maxfilter') || '';
    } else if (this.chosenStarsOption !== undefined) {
      this.starFilter = this.chosenStarsOption;
    } else {
      this.starFilter = sessionStorage.getItem('weapon-starfilter') || '';
      this.elementFilter = sessionStorage.getItem('weapon-elementfilter') || '';
    }
    this.haveRename = await this.fetchTotalWeaponRenameTags();
    this.createPagination(this.activePage);
    await this.loadCosmeticsCount();
  },
});
