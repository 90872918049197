



























































import Vue from 'vue';
import {mapActions} from 'vuex';
import {isValidWeb3Address} from '@/utils/common';
import {Element} from '@/enums/Element';

interface StoreMappedActions {
  incrementDustSupplies(payload: { playerAddress: string, amountLB: number, amount4B: number, amount5B: number }): Promise<void>;

  decrementDustSupplies(payload: { playerAddress: string, amountLB: number, amount4B: number, amount5B: number }): Promise<void>;

  mintGiveawayWeapon(payload: { to: string, stars: number, chosenElement: number }): Promise<void>;

  getBurnPointMultiplier(): Promise<number>;

  setBurnPointMultiplier(payload: { multiplier: number }): Promise<void>;
}

enum Rarity {
  COMMON, UNCOMMON, RARE, EPIC, LEGENDARY
}

interface GiveawayWeaponMint {
  to: string;
  stars?: Rarity;
  chosenElement?: Element;
}

interface Data {
  playerAddress: string;
  amountLB?: number;
  amount4B?: number;
  amount5B?: number;
  incrementDust: boolean;
  giveawayWeaponMint: GiveawayWeaponMint;
  weaponRarities: Rarity[];
  weaponElements: Element[];
  currentBurnPointMultiplier?: number;
  newBurnPointMultiplier?: number;
  isLoading: boolean;
}

export default Vue.extend({
  data() {
    return {
      playerAddress: '',
      amountLB: undefined,
      amount4B: undefined,
      amount5B: undefined,
      incrementDust: true,
      giveawayWeaponMint: {
        to: '',
        stars: undefined,
        chosenElement: undefined,
      },
      weaponRarities: [Rarity.COMMON, Rarity.UNCOMMON, Rarity.RARE, Rarity.EPIC, Rarity.LEGENDARY],
      weaponElements: [Element.FIRE, Element.WATER, Element.EARTH, Element.LIGHTNING, Element.RANDOM],
      currentBurnPointMultiplier: undefined,
      newBurnPointMultiplier: undefined,
      isLoading: false,
      Rarity,
      Element,
    } as Data;
  },

  computed: {
    suppliesButtonDisabled(): boolean {
      return !isValidWeb3Address(this.playerAddress)
        || this.amountLB === undefined
        || this.amount4B === undefined
        || this.amount5B === undefined
        || this.isLoading;
    },

    mintWeaponButtonDisabled(): boolean {
      return !isValidWeb3Address(this.giveawayWeaponMint.to)
        || this.giveawayWeaponMint.stars === undefined
        || this.giveawayWeaponMint.chosenElement === undefined
        || this.isLoading;
    },

    setNewBurnPointMultiplierButtonDisabled(): boolean {
      return this.newBurnPointMultiplier === undefined
        || this.isLoading;
    },
  },

  methods: {
    ...mapActions([
      'incrementDustSupplies',
      'decrementDustSupplies',
      'mintGiveawayWeapon',
      'getBurnPointMultiplier',
      'setBurnPointMultiplier',
    ]) as StoreMappedActions,

    async incrementSupplies() {
      if (!isValidWeb3Address(this.playerAddress)
        || this.amountLB === undefined
        || this.amount4B === undefined
        || this.amount5B === undefined) {
        return;
      }
      try {
        this.isLoading = true;
        await this.incrementDustSupplies({
          playerAddress: this.playerAddress,
          amountLB: this.amountLB,
          amount4B: this.amount4B,
          amount5B: this.amount5B,
        });
        this.clearInputs();
      } finally {
        this.isLoading = false;
      }
    },

    async decrementSupplies() {
      if (!isValidWeb3Address(this.playerAddress)
        || this.amountLB === undefined
        || this.amount4B === undefined
        || this.amount5B === undefined) {
        return;
      }
      try {
        this.isLoading = true;
        await this.decrementDustSupplies({
          playerAddress: this.playerAddress,
          amountLB: this.amountLB,
          amount4B: this.amount4B,
          amount5B: this.amount5B,
        });
        this.clearInputs();
      } finally {
        this.isLoading = false;
      }
    },

    async mintWeapon() {
      if (!isValidWeb3Address(this.giveawayWeaponMint.to)
        || this.giveawayWeaponMint.chosenElement === undefined
        || this.giveawayWeaponMint.stars === undefined) {
        return;
      }
      try {
        this.isLoading = true;
        await this.mintGiveawayWeapon({
          to: this.giveawayWeaponMint.to,
          stars: this.giveawayWeaponMint.stars,
          chosenElement: this.giveawayWeaponMint.chosenElement,
        });
        this.clearInputs();
      } finally {
        this.isLoading = false;
      }
    },

    async setNewBurnPointMultiplier() {
      if (this.newBurnPointMultiplier === undefined) return;
      try {
        this.isLoading = true;
        await this.setBurnPointMultiplier({
          multiplier: this.newBurnPointMultiplier,
        });
        await this.fetchCurrentBurnPointMultiplier();
        this.newBurnPointMultiplier = undefined;
      } finally {
        this.isLoading = false;
      }
    },

    async fetchCurrentBurnPointMultiplier() {
      try {
        this.isLoading = true;
        this.currentBurnPointMultiplier = await this.getBurnPointMultiplier();
      } finally {
        this.isLoading = false;
      }
    },

    clearInputs() {
      this.playerAddress = '';
      this.amountLB = undefined;
      this.amount4B = undefined;
      this.amount5B = undefined;
      this.giveawayWeaponMint = {
        to: '',
        stars: undefined,
        chosenElement: undefined,
      };
    }
  },

  async mounted() {
    await this.fetchCurrentBurnPointMultiplier();
  },
});
